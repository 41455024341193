import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  Button,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  OrderStateModel,
  OrderStatuses,
  ItemStatuses,
  paymentsSystems,
  paymentsStatus,
  ItemResponseModel
} from '../../../../interfaces/OrderResponseModel';

import {
  OrderAddress,
  OrderItem,
  OrderSummary,
  OrderPrice,
  OrderPriceChange
} from '../';
import CourierCardBody from '../../../../components/CourierCardBody';
import DialogConfirm from '../../../../components/Dialogs';

import {
  changePaymentStatus,
  changeOrderStatus,
  changeItemStatus,
  addPayment
} from '../../../../store/order';

import withAlert from '../../../../hoc/withAlert';

import config from '../../../../config/config.json';

import { roleSelector } from '../../../../store/login';

import formatOrderText from '../../../../utils/formatOrderText';
import { copyTextToClipboard } from '../../../../libs/copyHelper';
import { IconButton } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';

import { cookSetRule } from '../../../../utils/cookSetRules';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    // @ts-ignore
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  },
  marginTop: {
    // @ts-ignore
    marginTop: theme.spacing(2)
  }
  // media: {
  //   height: 140
  // }
}));

export interface OrderFullCardProps {
  order: OrderStateModel;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

const OrderFullCard: React.SFC<OrderFullCardProps> = ({
  order,
  setSnack,
  dispatchHandler
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // State
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [blockUi, setBlockUi] = useState(false);

  const [items, setItems] = useState<{
    [key: number]: {
      item: ItemResponseModel | null;
      dishItems: ItemResponseModel[] | null;
    };
  }>();

  const isTextForCourier = true;

  const userRole = useSelector(roleSelector);

  // Может ли пользователь редакировать заказ - назначать курьера, менять статус, указывать оплату
  const [canEdit, setCanEdit] = React.useState<boolean>(false);

  // Может ли пользователь назначать поваров на блюда
  const [canSetCook, setCanSetCook] = React.useState<boolean>(false);

  const [status, setStatus] = React.useState<OrderStatuses | string>('');

  // Handlers
  const handleCopy = (source: string = 'order') => {
    let orderText = '';

    switch (source) {
      case 'order':
        orderText = formatOrderText(isTextForCourier, order);
        break;
      case 'address':
        orderText = order.deliveryAddress as string;
        break;
      case 'name':
        orderText = order.delivery?.meta.name as string;
        break;
      case 'phone':
        orderText = order.delivery?.meta.phone as string;
        break;
      case 'comment':
        orderText = order.comment as string;
        break;
    }

    copyTextToClipboard(orderText);
  };
  // Очтавим только те диши которые есть в айтемах чтобы потом передавать в айтемы не все диши а только те которые нужны

  const handleOrderStatusChange = async () => {
    setBlockUi(true);

    if (!checkAllItemsHasCook()) return;
    // Добавим проверка ну курьера если нужно

    // console.log('order place', order.place.meta.process.needCourier);
    const needCourier = order.place.meta.process.needCourier;
    if (needCourier) {
      if (!order.delivery?.courier) {
        setSnack({
          open: true,
          type: 'error',
          message: 'Ошибка: не указан курьер'
        });
        setBlockUi(false);
        return;
      }
    }

    try {
      // Поставим все айтемам статус выполнен
      await setAllItemsDoneStatus();
      // Поставим самому заказу статус в доставке
      await dispatchHandler(
        changeOrderStatus,
        order.id as number,
        OrderStatuses.delivering
      );

      //  Сохраним статус локально чтобы больше не показывать кнопку заказ готов
      setStatus(OrderStatuses.delivering);
    } catch {}
    setBlockUi(false);
  };

  const handleOrderStatusDeliveried = async () => {
    // Поставим самому заказу статус выполнен
    await dispatchHandler(
      changeOrderStatus,
      order.id as number,
      OrderStatuses.done
    );
    //  Сохраним статус локально чтобы больше не показывать кнопку заказ готов
    setStatus(OrderStatuses.done);
  };

  const handleSemisStatusDone = async () => {
    if (!checkAllItemsHasCook()) return;

    try {
      // Поставим самому заказу статус выполнен
      await dispatchHandler(
        changeOrderStatus,
        order.id as number,
        OrderStatuses.done
      );
      await setAllItemsDoneStatus();

      //  Сохраним статус локально чтобы больше не показывать кнопку заказ готов
      setStatus(OrderStatuses.done);
    } catch {}
  };

  const handleCourierParamsSet = (value: boolean) => {};

  const handleDoneClick = async () => {
    // setBlockUi(true);

    const paymentStatus =
      order.payments !== null &&
      order.payments.length > 0 &&
      (order.payments[0].status as paymentsStatus);

    const paymentSystem =
      order.payments !== null &&
      order.payments.length > 0 &&
      (order.payments[0].system as paymentsSystems);

    if (
      paymentStatus === paymentsStatus.paid ||
      paymentSystem === paymentsSystems.cp
    )
      await handleOrderStatusDeliveried();
    else setPaymentDialogOpen(true);
    // setBlockUi(false);
  };

  const handlePaymentConfirmed = () => {
    setBlockUi(true);
    setPaymentDialogOpen(false);
    const paymentStatus: paymentsStatus = paymentsStatus.paid;
    handlePaymentStatusChange(paymentStatus);
    setBlockUi(false);
  };

  const handlePaymentNotConfirmed = () => {
    setPaymentDialogOpen(false);
    const paymentStatus: paymentsStatus = paymentsStatus.not_paid;
    handlePaymentStatusChange(paymentStatus);
  };

  const handlePaymentStatusChange = async (status: paymentsStatus) => {
    try {
      if (order.payments === null || order.payments.length === 0) {
        const paymentSystem = paymentsSystems.nal;
        await dispatchHandler(
          addPayment,
          order.id as number,
          status,
          paymentSystem
        );
      } else {
        await dispatchHandler(
          changePaymentStatus,
          order.id as number,
          order.payments[0].id,
          status
        );
      }
      /* Change status to Done by sending API request */
      handleOrderStatusDeliveried();
    } catch {}
  };

  // Helpers
  const setAllItemsDoneStatus = async () => {
    await Promise.all(
      order.items.map(async item => {
        await dispatchHandler(
          changeItemStatus,
          order.id as number,
          item.id as number,
          ItemStatuses.done
        );
      })
    );
  };

  const checkAllItemsHasCook = () => {
    const itemsCooksSet = order.items
      .map(item => item.cook?.id)
      .every(cookId => cookId);
    if (!itemsCooksSet) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: не у всех позиций указаны повара'
      });
      setBlockUi(false);
      return false;
    }
    return true;
  };

  const groupItemsByDish = (items: ItemResponseModel[]) => {
    return items.reduce(
      (
        res: {
          [key: number]: {
            item: ItemResponseModel | null;
            dishItems: ItemResponseModel[] | null;
          };
        },
        item: ItemResponseModel
      ) => {
        const dishId = item.dish.id as number;
        if (Object.keys(res).indexOf(dishId.toString()) > -1) {
          res[dishId].item = {
            ...(res[dishId].item as ItemResponseModel),
            amount: (res[dishId].item?.amount as number) + 1
          };
          res[dishId].dishItems?.push({ ...item });
        } else {
          res[dishId] = { item: null, dishItems: null };
          res[dishId].item = { ...item };
          res[dishId].dishItems = [{ ...item }];
        }
        return res;
      },
      {}
      // )
    );
  };

  const getSummaryLabel = (status: OrderStatuses) => {
    if (order.type === 'semis') {
      return 'Приготовлен';
    } else {
      if (status === OrderStatuses.done) return 'Заказ доставлен';
      if (status === OrderStatuses.delivering) return 'Заказ в доставке';
      else return status;
    }
  };

  const getActionLabel = (status: OrderStatuses) => {
    if (order.type === 'semis') {
      return 'Приготовлен';
    } else {
      if (status === OrderStatuses.delivering) return 'Доставлен';
      else if (status !== OrderStatuses.done) return 'Передать в доставку';
      else return status;
    }
  };

  const getActionHandler = (status: OrderStatuses) => {
    if (order.type === 'semis') {
      return handleSemisStatusDone;
    } else {
    }
    if (status === OrderStatuses.delivering) return handleDoneClick;
    else if (status !== OrderStatuses.done) return handleOrderStatusChange;
    // else return status;
  };

  // Начальная закгрузка и при выборе другого заказа
  // @ts-ignore
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setStatus(order.status);
      setItems(groupItemsByDish(order.items));
    }
    return () => (mounted = false);
  }, [order]);

  // Могут редактировать и менять статус заказа только шефы и менджеры, повара не могут
  useEffect(() => {
    if (userRole) {
      setCanSetCook(cookSetRule(userRole));
      setCanEdit(userRole !== 'cook' && userRole !== 'trainee');
    }
  }, [userRole]);

  return (
    <Card style={{ marginRight: 8 }}>
      <CardContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}>
          <OrderSummary order={order} />
          {/* Список позаций */}
          <Grid item>
            <Typography variant={'h5'}>
              {order.type === 'semis' ? 'Заготовки' : 'Позиции'}
            </Typography>
          </Grid>
          {items &&
            Object.keys(items).length > 0 &&
            Object.keys(items)
              .sort()
              .map(dishId => (
                <Grid item key={dishId}>
                  <OrderItem
                    item={items[parseInt(dishId)].item}
                    dishItems={items[parseInt(dishId)].dishItems}
                    orderId={order.id}
                    canEdit={canSetCook}
                    placeId={order.place.id}
                  />
                </Grid>
              ))}

          {/* Список катлери */}
          {order.cutleries &&
            order.type !== 'semis' &&
            order.cutleries?.length > 0 && (
              <Grid item>
                <Typography variant={'h5'}>{`Приборы`}</Typography>
              </Grid>
            )}
          {order.cutleries &&
            order.type !== 'semis' &&
            order.cutleries?.length > 0 &&
            order.cutleries?.map(item => (
              <Grid item key={item.id}>
                <Typography
                  variant={
                    'body1'
                  }>{`${item.dish.title} - ${item.amount}`}</Typography>
              </Grid>
            ))}

          {/* Список катлери */}

          {order.type !== 'semis' && (
            <>
              <Grid item>
                <Typography variant={'h5'}>{`Клиент`}</Typography>
              </Grid>
              {/* Данные по клиенту */}
              <Grid item>
                <Typography variant={'body1'}>
                  {order.delivery?.meta.name}{' '}
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={e => {
                      handleCopy('name');
                    }}>
                    <CopyIcon fontSize="small" />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  <a
                    href={`tel:${order.delivery?.meta.phone}`}
                    className={classes.tel}>
                    {order.delivery?.meta.phone}
                  </a>{' '}
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={e => {
                      handleCopy('phone');
                    }}>
                    <CopyIcon fontSize="small" />
                  </IconButton>
                </Typography>{' '}
              </Grid>
            </>
          )}
          {/* Данные по клиенту */}
          {/* Комментарий */}
          {order.comment && order.type !== 'semis' && (
            <Grid item>
              <Typography variant={'body1'}>
                {order.comment}{' '}
                <IconButton
                  style={{ padding: 0 }}
                  onClick={e => {
                    handleCopy('comment');
                  }}>
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Typography>
            </Grid>
          )}
          {/* Комментарий */}
          {order.type !== 'semis' && (
            <>
              <Grid item></Grid>
              {/* Адрес доставки */}
              <Grid item>
                <Typography variant={'h5'}>{`Адрес`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {order.deliveryAddress}{' '}
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={e => {
                      handleCopy('address');
                    }}>
                    <CopyIcon fontSize="small" />
                  </IconButton>
                </Typography>
              </Grid>
              {/* Адрес доставки */}
            </>
          )}
          {/* Стоимость */}
          {config.process.cookCanGetPrice && order.type !== 'semis' && (
            <Fragment>
              <Grid item>
                <Typography variant={'h5'}>
                  {!order.isPickup ? 'Цена с доставкой' : 'Итоговая цена'}
                </Typography>
              </Grid>
              <Grid item>
                <OrderPrice order={order} />
              </Grid>
            </Fragment>
          )}
          {/* Стоимость */}

          {/* Изменение оплаты */}
          {config.process.cookCanChangePrice && order.type !== 'semis' && (
            <Fragment>
              <Grid item>
                <Typography variant={'h5'}>{`Статус оплаты`}</Typography>
              </Grid>
              <Grid item>
                <OrderPriceChange order={order} canEdit={canEdit} />
              </Grid>
            </Fragment>
          )}

          {/* Изменение оплаты */}

          {/* Выбор курьера */}
          {order.type !== 'semis' && (
            <Fragment>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Typography variant={'h5'}>{`Курьер`}</Typography>
                <IconButton
                  onClick={e => {
                    handleCopy();
                  }}>
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <CourierCardBody
                  courierParamsSet={handleCourierParamsSet}
                  order={order}
                  canEdit={canEdit}
                />
              </Grid>
            </Fragment>
          )}
          {/* Выбор курьера */}
          {/* Статус заказа */}
          <Grid item>
            {canEdit && status !== OrderStatuses.done && (
              <Button
                color="primary"
                fullWidth
                size="large"
                disabled={blockUi}
                variant="contained"
                className={classes.marginTop}
                onClick={getActionHandler(status as OrderStatuses)}>
                {getActionLabel(status as OrderStatuses)}
              </Button>
            )}

            {(status === OrderStatuses.delivering ||
              status === OrderStatuses.done) && (
              <Typography
                variant={'h5'}
                style={{ color: colors.teal[500], marginTop: '16px' }}>
                {getSummaryLabel(status as OrderStatuses)}
              </Typography>
            )}
            <DialogConfirm
              open={paymentDialogOpen}
              onClose={setPaymentDialogOpen}
              title={'Заказ оплачен?'}
              buttonYes={'Оплачен'}
              buttonNo={'Не оплачен'}
              onYes={handlePaymentConfirmed}
              onNo={handlePaymentNotConfirmed}
            />
          </Grid>
          {!order.delivery?.courier &&
            order.type !== 'semis' &&
            order.place.meta.process.needCourier && (
              <Grid item>
                <Typography
                  variant={
                    'caption'
                  }>{`Необходимо указать курьера`}</Typography>
              </Grid>
            )}
          {/* Статус заказа */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withAlert(OrderFullCard);
