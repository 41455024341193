import {
  paymentsSystems,
  OrderStatuses,
  paymentsStatus
} from './OrderResponseModel';

import { SelectItemModel } from './ItemsSelect';

enum DeliveryType {
  pickup = 'pickup',
  pickpoint = 'pickpoint',
  delivery = 'delivery'
}

export enum itemTypes {
  items = 'items',
  presents = 'presents',
  cutlery = 'cutlery',
  semis = 'semis'
}

export interface ItemRequestModel {
  dishId: number;
  amount: number;
  price: number;
  categoryId: number;
}

export interface DeliveryRequestModel {
  option: { id: number | null };
  start_time: string | null;
  address: string | null;
  name: string | null;
  phone: string | null;
  // user_id: number | null;
  price: number | null;
}

export interface NewOrderRequestModel {
  client: { id: number | null } | null;
  cook?: { id: number | null };
  // cutlery: { [key: number]: number };
  cutlery: SelectItemModel[];
  items: SelectItemModel[];
  presents: SelectItemModel[];
  semis: SelectItemModel[];
  delivery: DeliveryRequestModel | null;
  comment: string | null;
  price: number;
  status: OrderStatuses | null;
  paymentStatus: paymentsStatus | null;
  paymentSystem: paymentsSystems | null;
  type: string | null;
}

export interface OrderRequestModel {
  client: { id: number | null } | null;
  cook?: { id: number | null };
  // cutlery: { [key: number]: number };
  cutlery: ItemRequestModel[];
  items: ItemRequestModel[];
  presents: ItemRequestModel[];
  semis: ItemRequestModel[];
  delivery: DeliveryRequestModel | null;
  comment: string | null;
  price: number;
  status: OrderStatuses | null;
  paymentStatus: paymentsStatus;
  paymentSystem: paymentsSystems | null;
  type: string | null;
}

export interface ItemRequestBodyModel {
  dish: { id: number };
  amount: number;
  price: number;
}

export interface DeliveryRequestBodyModel {
  option: { id: number | null };
  start_time: string | null;
  address: string | null;
  name: string | null;
  phone: string | null;
  // user_id: number | null;
  price: number | null;
}

export interface PaymentModel {
  status: paymentsStatus;
  system: paymentsSystems | null;
}

export interface OrderRequestBodyModel {
  place: { id: number };
  cook: { id: number | null } | null;
  client: { id: number | null };
  items: ItemRequestBodyModel[];
  delivery: DeliveryRequestBodyModel;
  comment: string | null;
  price: number;
  status: OrderStatuses | null;
  payments: PaymentModel[];
  type: string | null;
}
