export enum ConnectionState {
  connected = 'connected',
  wait = 'wait',
  disconnected = 'disconnected'
}

export enum WsRoles {
  cook = 'cook',
  manager = 'manager'
}

export interface WsNotificationMessageAddOrderModel {
  delivery_date: string | null;
  order_id: number | null;
}

export interface WsNotificationModel {
  time: string | null;
  message?: string | null;
  message_data: WsNotificationMessageAddOrderModel | null;
  user_id: number | null;
  user_name: string | null;
  wasSeen?: boolean | null;
}

export interface WsNotificationRequestModel {
  data: WsNotificationModel;
  type: string;
  user_type: WsRoles;
}

export interface WsModel {
  connectionState: ConnectionState;
  notifications: WsNotificationModel[];
  refresh: WsNotificationModel | null;
}
