import React, { useState, useEffect } from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const handleStartDateChange = () => {};

export interface OrderCardProps {
  onDateChange: (date: MaterialUiPickersDate) => void;
  selectedDate: MaterialUiPickersDate;
}

const OrderDateFilter: React.SFC<OrderCardProps> = ({
  onDateChange,
  selectedDate
}) => {
  const handleDateChange = async (
    date: MaterialUiPickersDate,
    value?: string | null | undefined
  ) => {
    await onDateChange(date);
  };

  return (
    <KeyboardDatePicker
      // className={classes.datePiker}
      margin="normal"
      id="order-date"
      label="Заказы за"
      format="dd.MM.yyyy"
      value={selectedDate}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date'
      }}
    />
  );
};

export default OrderDateFilter;
