import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress, Switch } from '@material-ui/core';

import { DishCard, DishesTopBar } from './components';
import { PlaceSelect } from '../../components';

import { getCategorySelector } from '../../store/categories';
import {
  getDishesByCategorySelector,
  getDishesSelector
} from '../../store/dishes';
import { initPage, getPageReadyByPageSelector } from '../../store/api';

import useIgnoreRefresh from '../../hooks/useIgnoreRefresh';

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    paddingTop: theme.spacing(2),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const Category = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categoryId = props.match.params.id;
  const { type } = queryString.parse(props.location.search);
  // cutlery
  const allDishes = useSelector(getDishesSelector);

  const dishesState = React.useMemo(() => {
    return Object.keys(allDishes)
      .filter(
        key =>
          allDishes[parseInt(key)].categories.indexOf(parseInt(categoryId)) > -1
      )
      .map(key => allDishes[parseInt(key)]);
  }, [categoryId, allDishes]);

  const category = useSelector(getCategorySelector(parseInt(categoryId)));

  const pageReady = useSelector(getPageReadyByPageSelector('/categories'));

  // игнорируем события рефреш
  useIgnoreRefresh();

  useEffect(() => {
    const getData = async () => {
      initPage('/categories', dispatch);
    };
    getData();
  }, [dispatch]);

  // useEffect(() => {
  //   if (dishesState) {
  //     setDishes(dishesState);
  //   }
  // }, [pageReady]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} block={true} />

      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}
        wrap="nowrap">
        {!pageReady && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress disableShrink size={56} />
          </div>
        )}
        <Grid item>
          <DishesTopBar category={category} />
        </Grid>
        {Object.keys(dishesState).map(key => (
          <Grid item key={key}>
            <DishCard dish={dishesState[parseInt(key)]} type={type} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Category;
