import * as React from 'react';
// import { useState, useEffect } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    // flexGrow: 1,
    color: 'white',
    justifyContent: 'start'
  }
}));

export interface CategoriesTopBarProps {
  userRole: string;
}

const CategoriesTopBar: React.SFC<CategoriesTopBarProps> = ({ userRole }) => {
  const classes = useStyles();
  let history = useHistory();

  const handleAdd = () => {
    if (userRole === 'manager') {
      history.push(`/category/new/categories/`);
    }
    if (userRole === 'chef') {
      history.push(`/category/new/semis/`);
    }
    // console.log('handleAdd');
  };
  return (
    <div>
      <IconButton
        className={classes.flexGrow}
        // style={{ }}
        onClick={e => {
          handleAdd();
        }}>
        <AddIcon fontSize="small" />
        <Typography
          variant="h6"
          style={{
            marginLeft: 5,
            color: 'white'
          }}>
          Категорию
        </Typography>
      </IconButton>
    </div>
  );
};

export default CategoriesTopBar;
