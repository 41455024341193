import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WsNotificationModel } from '../interfaces/WsModel';

import { refreshDone, getRefreshSelector } from '../store/ws';

const useIgnoreRefresh = async () => {
  const dispatch = useDispatch();
  const refresh = useSelector(getRefreshSelector) as WsNotificationModel;

  useEffect(() => {
    const refreshClear = async () => {
      await dispatch({ type: refreshDone.type });
    };
    if (refresh) {
      refreshClear();
    }
  }, [refresh]);
};

export default useIgnoreRefresh;
