import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { CategoryCard } from './components';
import { PlaceSelect } from '../../components';

import {
  getCategoriesSelector,
  getSemisCategoriesSelector,
  getCutleryCategoriesSelector
} from '../../store/categories';
import { initPage, getPageReadyByPageSelector } from '../../store/api';
import { CategoryModel } from '../../interfaces/CategoriesModel';

import useIgnoreRefresh from '../../hooks/useIgnoreRefresh';
import { roleSelector, getMenuPlacesSelector } from '../../store/login';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    paddingTop: theme.spacing(2),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const CategoriesList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = useSelector(roleSelector);

  const categoriesState = useSelector(getCategoriesSelector);
  // const cutleryCategory = useSelector(getCutleryCategoriesSelector)[0];
  const menuPlace = useSelector(getMenuPlacesSelector);

  const pageReady = useSelector(
    getPageReadyByPageSelector(props.location.pathname)
  );
  const [categories, setCategories] = useState<{
    [key: number]: CategoryModel;
  }>({});

  // игнорируем события рефреш
  useIgnoreRefresh();

  // Helpers
  const getGroupCategories = (groupName: string) => {
    // @ts-ignore
    return Object.keys(categoriesState).reduce(
      // @ts-ignore
      (res: { [key: number]: CategoryModel }, key: number) => {
        if (categoriesState[key].group === groupName) {
          res[key] = categoriesState[key];
        }
        return res;
      },
      {}
    );
  };

  // useEffects
  useEffect(() => {
    const getData = async () => {
      initPage(props.location.pathname, dispatch);
    };
    getData();
  }, [dispatch, menuPlace]);

  useEffect(() => {
    if (categoriesState) {
      if (userRole === 'chef') {
        // @ts-ignore
        setCategories(getGroupCategories('semis'));
      } else {
        // @ts-ignore
        setCategories(categoriesState);
      }
    }
  }, [categoriesState]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} />

      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}>
        {!pageReady && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress disableShrink size={56} />
          </div>
        )}

        {Object.keys(categories).map(key => (
          <Grid item key={key}>
            <CategoryCard category={categories[parseInt(key)]} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoriesList;
