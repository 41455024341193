import axios from 'axios';

export const photoUpload = async (
  file: File,
  id: number,
  type: PhotoUploadType = PhotoUploadType.dish
) => {
  const formData = new FormData();
  formData.append('file', file);
  let url;
  if (type == PhotoUploadType.dish) {
    url = `/api/cafe/dishes/${id}/image/`;
  } else if (type == PhotoUploadType.seoCategory) {
    url = `/api/cafe/categories/${id}/seo_image/`;
  } else if (type == PhotoUploadType.seoDish) {
    url = `/api/cafe/dishes/${id}/image/?type=seo`;
  } else if (type == PhotoUploadType.siteDish) {
    url = `/api/cafe/dishes/${id}/image/?type=site`;
  }

  let requestParams = {
    url,
    method: 'post',
    data: formData,
    withCredentials: true,
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  //@ts-ignore
  const result = await axios.request(requestParams);
  return result;
};

export enum PhotoUploadType {
  dish = 'dish',
  siteDish = 'siteDish',
  category = 'category',
  seoDish = 'seoDish',
  seoCategory = 'seoCategory'
}
