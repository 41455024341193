export interface UserModel {
  id: string | null;
  name: string | null;
  surname: string | null;
  initials?: string | null;
  roles?: string[];
}

export interface PlaceModel {
  id: number;
  title: string;
}

export interface LoginModel {
  validationError?: string | null;
  user?: UserModel;
  places?: PlaceModel[];
  orderPlaces?: number[];
  menuPlace?: number;
  email?: string | null;
  emailSent?: boolean;
  hasError?: boolean;
  onError?: string | null;
  xcode?: string | null;
  error: string | null;
}

export enum UserRoles {
  notLogin = 'notLogin',
  manager = 'manager',
  chef = 'chef',
  cook = 'cook',
  trainee = 'trainee',
  guest = 'guest'
}
