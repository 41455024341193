import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { UserTopBar, UserCard } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const UserList = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}>
        <Grid item>
          <UserTopBar />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
        <Grid item>
          <UserCard />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserList;
