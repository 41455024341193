import config from '../config/config.json';

export const cookSetRule = (role: string): boolean => {
  if (role === 'cook') {
    return config.process.cookCanSetCook;
  }
  if (role === 'trainee') {
    return config.process.traineeCanSetCook;
  }
  return true;
};

export default {
  cookSetRule
};
