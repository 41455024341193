import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';
import rubFormatter from '../../../../libs/rubFormatter';
export interface OrdersSummaryProps {
  orders: OrderStateModel[];
}

const OrdersSummary: React.SFC<OrdersSummaryProps> = ({ orders }) => {
  return (
    <Fragment>
      <Typography variant={'body1'}>{`заказов: ${
        orders.length
      }, на сумму: ${rubFormatter(
        orders.reduce((res, order) => {
          res +=
            (order.itemsPrice as number) + (order.delivery?.price as number);
          return res;
        }, 0)
      )}`}</Typography>
    </Fragment>
  );
};

export default OrdersSummary;
