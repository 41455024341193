import * as React from 'react';
import { useState, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography
} from '@material-ui/core';

interface DialogConfirmProps {
  open: boolean;
  onClose?: (value: boolean) => void;
  title?: string;
  buttonYes?: string;
  buttonNo?: string;
  onYes?: () => void;
  onNo?: () => void;
}

const DialogConfirm = (props: DialogConfirmProps) => {
  const { open, onClose, title, buttonYes, buttonNo, onYes, onNo } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogTitle = (title) ? title : 'Уверены?';
  const labelYes = (buttonYes) ? buttonYes : 'Да'; 
  const labelNo = (buttonNo) ? buttonNo : 'Нет'; 

  const closeDialog = () => {
    if (onClose) onClose(false)
    setDialogOpen(false)
  } 

  const buttonCallbacks: { [key: string]: Function; } = {
    'yes': (onYes) ? onYes: closeDialog,
    'no': (onNo) ? onNo: closeDialog,
  }

  useEffect(() => {
    if (open)
      setDialogOpen(true)
    else
      setDialogOpen(false)
  }, [open])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonValue = event.currentTarget.value; 
    buttonCallbacks[buttonValue]();
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={closeDialog}
      aria-labelledby="confirm-dialog-title">
      <DialogTitle disableTypography id="confirm-dialog-title">
        <Typography variant="h4" align="center">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogActions>
        <Button
          variant="outlined"
          value={Object.keys(buttonCallbacks)[0]}
          onClick={(e) => handleClick(e)}
          color="primary"
          autoFocus>
          {labelYes} 
        </Button>
        <Button
          variant="outlined"
          value={Object.keys(buttonCallbacks)[1]}
          onClick={(e) => handleClick(e)}
          color="default">
          {labelNo} 
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
