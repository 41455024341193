import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';

// import withAlert from '../../hoc/withAlert';

import {
  OrderStateModel,
  CourierSystemEnum
} from '../../../../interfaces/OrderResponseModel';

import { getCouriersSelector } from '../../../../store/roles';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const CourierCard = props => {
  const classes = useStyles();
  const { order }: { order: OrderStateModel } = props;
  const { courierParamsSet: handleCourierParamsSet } = props;
  const { dispatchHandler, setSnack } = props;

  const dispatch = useDispatch();

  const couriers = useSelector(getCouriersSelector);

  const [courierSystem, setCourierSystem] = React.useState(
    CourierSystemEnum.own
  );

  const [courierId, setCourierId] = React.useState('');

  const [courierName, setCourierName] = React.useState('');
  const [courierPhone, setCourierPhone] = React.useState('');
  const [courierParamsSet, setCourierParamsSet] = React.useState(false);

  const handleCourierSystemChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const system = event.target.value;

    try {
      // await dispatchHandler(
      //   changePaymentSystem,
      //   order.id as number,
      //   payment_id,
      //   system
      // );
      // Если ок то поменяем локальный статус
      setCourierSystem(system as CourierSystemEnum);

      // При переключении типа курьерской системы сбрасывам признак того что курьер выбран
      setCourierParamsSet(false);
      handleCourierParamsSet(false);
    } catch {}
  };

  const handleCourierIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const id = event.target.value;

    try {
      // await dispatchHandler(
      //   changePaymentSystem,
      //   order.id as number,
      //   payment_id,
      //   system
      // );
      // Если ок то поменяем локальный статус
      setCourierId(id);
      // Устанавливаем статус что курьер не выбран
      setCourierParamsSet(true);
      handleCourierParamsSet(true);
    } catch {}
  };

  // @ts-ignore
  const handleNameChange = event => {
    setCourierName(event.target.value);
  };

  // @ts-ignore
  const handlePhoneChange = event => {
    setCourierPhone(event.target.value);
  };

  // @ts-ignore
  const handleSaveCourier = event => {
    try {
      // await dispatchHandler(
      //   changePaymentSystem,
      //   order.id as number,
      //   payment_id,
      //   system
      // );

      if (courierName === '' || courierPhone === '') {
        setSnack({
          open: true,
          type: 'error',
          message: 'Ошибка: укажите имя и телефон'
        });
        throw 'Ошибка: укажите имя и телефон';
      }
      if (courierParamsSet === false) {
        console.log('CREATE');
      } else {
        console.log('EDIT');
      }
      // Установим статус что данные о курьере сохранены - это нужно чтобы менять стейт кнопки сохранить или редактировать
      setCourierParamsSet(true);
      handleCourierParamsSet(true);
    } catch {}
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={0}>
      <Grid item>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="courierSystem">Курьерская служба</InputLabel>
          <Select
            fullWidth
            labelId="courierSystem"
            value={courierSystem}
            // @ts-ignore
            onChange={handleCourierSystemChange}>
            <MenuItem value={CourierSystemEnum.own}>Свои курьеры</MenuItem>
            <MenuItem value={CourierSystemEnum.dostavista}>Достависта</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {courierSystem === CourierSystemEnum.own && (
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="courier">Выберите курьера</InputLabel>
            <Select
              fullWidth
              labelId="courier"
              value={courierId}
              // @ts-ignore
              onChange={handleCourierIdChange}>
              {couriers.length > 0 &&
                couriers.map(courier => (
                  <MenuItem value={courier.id}>{courier.person.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {courierSystem === CourierSystemEnum.dostavista && (
        <Fragment>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Фамилия Имя"
              name="name"
              type="text"
              // @ts-ignore
              onChange={handleNameChange}
              value={courierName}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Телефон"
              name="phone"
              type="text"
              // @ts-ignore
              onChange={handlePhoneChange}
              value={courierPhone}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveCourier}>
              {courierParamsSet === false ? 'Сохранить' : 'Редактировать'}
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default CourierCard;
