import moment from 'moment';
import { useSelector } from 'react-redux';

import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Grid, Typography } from '@material-ui/core';

import { isExtremelyDeliveryTime } from 'utils/delivery';

import { OrderStatus } from '../';
import {
  OrderStateModel,
  OrderStatuses,
  paymentsStatus
} from '../../interfaces/OrderResponseModel';

import { formatDate } from '../../libs/dateHelper';
import AppChip from 'components/AppChip/AppChip';
import { getDeliveryOptionsSelector } from 'store/deliveryOptions';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    // @ts-ignore
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  }
}));
// @ts-ignore
const OrderSummary = props => {
  const classes = useStyles();
  const { order }: { order: OrderStateModel } = props;

  const deliveryOptions = useSelector(getDeliveryOptionsSelector)

  const priceColor = React.useMemo(() => {
    if (order.payments?.length && order.payments[0].status === paymentsStatus.paid) {
      return 'primary'
    }

    return order.status === OrderStatuses.done ? 'secondary' : 'default'
  }, [order, paymentsStatus])

  const dateColor = React.useMemo(() => {
    return isExtremelyDeliveryTime(order, deliveryOptions) ? 'secondary' : 'primary'
  }, [order])

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={3}>
      {order.type === 'semis' && (
        <Grid item>
          <Typography variant={'h5'}>
            {`#${order.id}`} заготовки {`(${order.items.length})`}
          </Typography>
        </Grid>
      )}
      {order.type !== 'semis' && (
        <>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <Typography variant={'h5'}>{`#${order.id}`}</Typography>
              </Grid>

              <Grid item>
                <OrderStatus status={order.status} />
              </Grid>
              <Grid item>
                <AppChip
                  size="small"
                  label={formatDate(moment(order.delivery?.start_time))}
                  color={dateColor}
                />
              </Grid>
              <Grid item>
                <AppChip
                  size="small"
                  label={`${(order.itemsPrice as number) +
                    (order.delivery?.price as number)} ₽`}
                  color={priceColor}
                />
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: 12 }}>
              <Typography variant={'h6'}>
                {order.delivery?.meta.name}{' '}
                <a
                  href={`tel:${order.delivery?.meta.phone}`}
                  className={classes.tel}>
                  {order.delivery?.meta.phone}
                </a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant={'h6'}
                style={{ color: 'gray', fontSize: 12 }}>
                <b style={{ color: '#009688' }}>{order.place.title}:</b>{' '}
                {order.deliveryAddress}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OrderSummary;
