import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import { DishesUnitsModel } from '../interfaces/DishesUnitModel';

const initialState: DishesUnitsModel = {
  units: []
};

const slice = createSlice({
  name: 'dishesUnits',
  initialState: initialState,
  reducers: {
    dishesUnitsLoaded: (dishesUnits, action) => {
      dishesUnits.units = action.payload.data.units;
    }
  }
});

export const { dishesUnitsLoaded } = slice.actions;

export default slice.reducer;

// Action Creators
export const loadDishesUnits = () => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/dishes_units/`,
      onSuccess: dishesUnitsLoaded.type,
      actionName: 'loadDishesUnits'
    })
  );
};

// Selectors;
export const getDishesUnitsSelector = createSelector(
  (state: RootState) => state.entities.dishesUnits,
  dishesUnits => dishesUnits.units
);

// Helpers
