import { UserModel, UserRoles } from '../interfaces/LoginModel';

export const getUserRole = (user: UserModel) => {
  if (user?.roles) {
    if (user?.roles.indexOf('manager') > -1) {
      return UserRoles.manager;
    }

    if (user?.roles.indexOf('chef') > -1) {
      return UserRoles.chef;
    }

    if (user?.roles.indexOf('cook') > -1) {
      return UserRoles.cook;
    }

    if (user?.roles.indexOf('trainee') > -1) {
      return UserRoles.trainee;
    }
    return UserRoles.guest;
  }
};
