import React, { Fragment, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { IconButton } from '@material-ui/core';
import ChefIcon from '@material-ui/icons/AssignmentInd';
import CookIcon from '@material-ui/icons/EmojiPeople';

import withAlert from '../../../../hoc/withAlert';
import { setChefRole, deleteChefRole } from '../../../../store/login';

import DialogConfirm from '../../../../components/Dialogs';

export interface ChefProps {
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  userRole: 'notLogin' | 'manager' | 'chef' | 'cook' | 'guest';
}

const Chef: React.SFC<ChefProps> = ({ dispatchHandler, userRole }) => {
  const handelSetChef = async () => {
    try {
      await dispatchHandler(setChefRole);
    } catch {}
    handleClose();
  };

  const handelDeleteCook = async () => {
    try {
      await dispatchHandler(deleteChefRole);
    } catch {}
    handleClose();
  };

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [yesLabel, setYesLabel] = useState('');
  const [noLabel, setNoLabel] = useState('');
  const [onYes, setOnYes] = useState<() => Promise<void> | null>();

  const handleIconClick = () => {
    if (userRole === 'cook') {
      setTitle('Назначить роль старшего повара?');
      setYesLabel('Да назначить');
      setNoLabel('Нет не нужно');
      setOnYes(() => handelSetChef);
      setConfirmOpen(true);
    }
    if (userRole === 'chef') {
      setTitle('Отменить роль старшего повара?');
      setYesLabel('Да отментить');
      setNoLabel('Нет не нужно');
      setOnYes(() => handelDeleteCook);
      setConfirmOpen(true);
    }
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  return (
    <Fragment>
      <IconButton color="inherit" size="small" onClick={handleIconClick}>
        {userRole === 'cook' && <CookIcon />}
        {userRole === 'chef' && <ChefIcon />}
      </IconButton>

      <DialogConfirm
        open={confirmOpen}
        title={title}
        buttonYes={yesLabel}
        buttonNo={noLabel}
        onClose={handleClose}
        onNo={handleClose}
        onYes={onYes}
      />
    </Fragment>
  );
};

export default withAlert(Chef);
