import React, { useState, Fragment, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Switch,
  IconButton,
  TextField,
  Collapse,
  Select,
  MenuItem,
  InputLabel,
  Button,
  CircularProgress
} from '@material-ui/core';
import { colors } from '@material-ui/core';

import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  changeDishStatus,
  changeDishPrice,
  changeDishDifficulty,
  changeDishMinAmount,
  changeDishMeasure,
  changeDishTitle,
  changeDishDescription,
  changeDishBgu,
  addChild,
  changeChildAmount,
  changeDishCategory,
  changeDishMeta
} from '../../../../store/dishes';
import { getMenuPlacesSelector } from '../../../../store/login';
import { getDishesUnitsSelector } from '../../../../store/dishesUnits';
import { getCategoriesSelector } from '../../../../store/categories';

import {
  DishResponseModel,
  ChildModel
} from '../../../../interfaces/DishesResponseModel';
import { itemTypes } from '../../../../interfaces/OrderRequestModel';
import { SelectItemModel } from '../../../../interfaces/ItemsSelect';

import withAlert from '../../../../hoc/withAlert';

import useDebounce, {
  useDebounceStringField
} from '../../../../hooks/useDebounce';

import { useItemsState } from '../../../../hooks/ItemsSelect';
import {
  ItemsSelect,
  ItemsContainer,
  ItemsIncDecList
} from '../../../../components/ItemsSelect';

import { photoUpload, PhotoUploadType } from '../../../../utils/photoUpload';
import { Seo, Site } from './components';
import { CollapseCard } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    // @ts-ignore
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  },
  bottom: {
    paddingBottom: 32
  }
}));

const MAX_PHOTO_SIZE_KB = 50;
const ACCEPTED_PHOTO_FORMATS = 'image/x-png,image/png';
// @ts-ignore
const DishCard = props => {
  const classes = useStyles();

  const { dispatchHandler, setSnack, handleInputChange } = props;
  const { dish, type }: { dish: DishResponseModel; type: string } = props;

  const dishesUnits = useSelector(getDishesUnitsSelector);
  const menuPlaces = useSelector(getMenuPlacesSelector);
  const categoriesState = useSelector(getCategoriesSelector);

  const addHandler = async (child: SelectItemModel) => {
    await dispatchHandler(addChild, dish.id, child.id, child.amount);
  };

  const changeAmountHandler = async (child: SelectItemModel) => {
    await dispatchHandler(changeChildAmount, dish.id, child.id, child.amount);
  };
  // Link to photo for refresh image after upload
  const photoRef = useRef(null);
  // To show preloader while uploading
  const [showPhotoLoading, setShowPhotoLoading] = useState(false);

  const [title, setTitle] = useState({
    value: '',
    state: 'init'
  });
  const debouncedTitle = useDebounce(title.value, 1000);

  const changeDescription = async (value: string) => {
    await dispatchHandler(changeDishDescription, dish.id as number, bgu, value);
  };
  const {
    value: description,
    setValue: setDescription
  } = useDebounceStringField(
    dish.meta.description as string,
    changeDescription
  );

  const changeBgu = async (value: string) => {
    await dispatchHandler(changeDishBgu, dish.id as number, description, value);
  };
  const { value: bgu, setValue: setBgu } = useDebounceStringField(
    dish.meta.bgu as string,
    changeBgu
  );

  const [price, setPrice] = useState({
    value: 0,
    state: 'init'
  });
  const debouncedPrice = useDebounce(price.value, 500);

  const [difficulty, setDifficulty] = useState({
    value: 0.0,
    state: 'init'
  });
  const debouncedDifficulty = useDebounce(difficulty.value, 500);

  const [minAmount, setMinAmount] = useState({
    value: 0.0,
    state: 'init'
  });
  const debouncedMinAmount = useDebounce(minAmount.value, 500);

  const [measure, setMeasure] = useState({
    value: 0,
    state: 'init'
  });
  const debouncedMeasure = useDebounce(measure.value, 500);

  const [active, setActive] = useState(false);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setActive(dish.active);

    setTitle({
      value: dish.title,
      state: 'init'
    });

    setPrice({
      value: dish.price as number,
      state: 'init'
    });

    setDifficulty({
      value: dish.difficulty as number,
      state: 'init'
    });

    setMinAmount({
      value: dish.minAmount as number,
      state: 'init'
    });

    setMeasure({
      value: dish.dishesUnit?.id || 0,
      state: 'init'
    });
  }, [dish.id]);

  // handlers
  const handleCollapse = async () => {
    setCollapse(!collapse);
  };

  // @ts-ignore
  const handleChange = async () => {
    try {
      await dispatchHandler(changeDishStatus, dish.id, !active);
      // Если ок то поменяем локальный статус
      setActive(!active);
    } catch {}
  };

  // Price
  const handleDeliveryPriceChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPrice = event.target.value;
    setPrice({
      value: newPrice !== '' ? parseInt(newPrice) : 0,
      state: 'changed'
    });
  };

  const handleChangeDifficulty = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newDifficulty = ev.target.value ? parseFloat(ev.target.value) : 0;
    setDifficulty({
      value: newDifficulty,
      state: 'changed'
    });
  };

  const handleChangeMinAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newMinAmount = ev.target.value ? parseFloat(ev.target.value) : 0;
    setMinAmount({
      value: newMinAmount,
      state: 'changed'
    });
  };

  const handleChangeMeasure = (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const newMeasure = parseInt(ev.target.value as string) || 0;
    setMeasure({
      value: newMeasure,
      state: 'changed'
    });
  };

  const handleChangeCategory = async (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const newCategory = parseInt(ev.target.value as string) || 0;
    setCollapse(false);
    await dispatchHandler(changeDishCategory, dish.id, newCategory);
  };

  // Use effects
  useEffect(() => {
    if (difficulty.state != 'init') {
      const sendDifficulty = async (debouncedDifficulty: number) => {
        try {
          await dispatchHandler(
            changeDishDifficulty,
            dish.id as number,
            debouncedDifficulty
          );
        } catch {}
      };
      sendDifficulty(debouncedDifficulty as number);
    }
  }, [debouncedDifficulty]);

  useEffect(() => {
    if (minAmount.state != 'init') {
      const sendMinAmount = async (debouncedMinAmount: number) => {
        try {
          await dispatchHandler(
            changeDishMinAmount,
            dish.id as number,
            debouncedMinAmount
          );
        } catch {}
      };
      sendMinAmount(debouncedMinAmount as number);
    }
  }, [debouncedMinAmount]);

  useEffect(() => {
    if (measure.state != 'init') {
      const sendMeasure = async (debouncedMeasure: number) => {
        try {
          await dispatchHandler(
            changeDishMeasure,
            dish.id as number,
            debouncedMeasure
          );
        } catch {}
      };
      sendMeasure(debouncedMeasure as number);
    }
  }, [debouncedMeasure]);

  useEffect(() => {
    if (price.state != 'init') {
      const sendPrice = async (debouncedPrice: number) => {
        try {
          await dispatchHandler(
            changeDishPrice,
            dish.id as number,
            debouncedPrice
          );
        } catch {}
      };
      sendPrice(debouncedPrice as number);
    }
  }, [debouncedPrice]);

  useEffect(() => {
    if (debouncedTitle && title.state !== 'init') {
      const sendTitle = async (debouncedTitle: string) => {
        try {
          await dispatchHandler(
            changeDishTitle,
            dish.id as number,
            debouncedTitle
          );
        } catch {}
      };
      sendTitle(debouncedTitle as string);
    }
  }, [debouncedTitle]);

  // Получим список выранных блюд и проперти для селекта и списка
  const { getItemsSelectProps, getItemsListProps } = useItemsState({
    places: menuPlaces as number,
    // @ts-ignore
    currentItems: dish.children as ChildModel,
    // @ts-ignore
    prepareItems: useItemsState.prepareFromChildModel,
    itemType: itemTypes.items,
    onAdd: addHandler,
    onAmountChange: changeAmountHandler
  });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file = e.target.files[0];

    if (file.size > 1024 * MAX_PHOTO_SIZE_KB) {
      setSnack({
        open: true,
        type: 'error',
        message: `Ошибка: размер картинки должен быть меньше ${MAX_PHOTO_SIZE_KB} килобайт`
      });
      return;
    }

    if (
      ACCEPTED_PHOTO_FORMATS.toLowerCase().indexOf(file.type.toLowerCase()) ===
      -1
    ) {
      setSnack({
        open: true,
        type: 'error',
        message: `Ошибка: картинка должна быть в формате ${ACCEPTED_PHOTO_FORMATS}`
      });
      return;
    }

    setShowPhotoLoading(true);
    await photoUpload(file, dish.id);
    setShowPhotoLoading(false);

    if (photoRef !== null) {
      //@ts-ignore
      photoRef.current.src = `${
        process.env.REACT_APP_FRONT_URL
      }/images/dishes/${dish.id}.png?t=${new Date().getTime()}`;
    }
  };
  // If now image for dish
  const handleNoImage = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // @ts-ignore
    ev.target.src = '/images/no_image.png';
  };

  // seo

  return (
    <Card
      style={{
        backgroundColor: `${active ? 'white' : colors.grey[200]}`,
        overflow: 'visible',
        zIndex: 0
      }}>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch">
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={0}
              wrap="nowrap">
              <Grid item>
                <Typography variant={'h5'}>
                  {title.value.replace(/<[^>]*>?/gm, '')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center">
                  <Grid item>
                    <IconButton aria-label="minus" onClick={handleCollapse}>
                      <DownIcon />
                    </IconButton>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Collabsabel pat */}
          <Collapse in={collapse}>
            {collapse && (
              <Grid item>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={2}>
                  {/* Row with title and active */}
                  {type !== 'categories' && (
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        spacing={0}>
                        <Grid item>
                          {' '}
                          <TextField
                            style={{ width: '100%' }}
                            label="Название"
                            name="label"
                            type="text"
                            value={title.value || ''}
                            // @ts-ignore
                            onChange={handleInputChange(setTitle)}
                            autoComplete="off"
                          />
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={active}
                            // @ts-ignore
                            onChange={handleChange}
                            name="active"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Тип категории для доставки */}
                  {type === 'categories' && (
                    <Fragment>
                      {/* Row with name */}
                      <Grid item>
                        <TextField
                          style={{ width: '100%' }}
                          label="Название"
                          name="label"
                          type="text"
                          value={title.value || ''}
                          // @ts-ignore
                          onChange={handleInputChange(setTitle)}
                          autoComplete="off"
                        />
                      </Grid>
                      {/* Row with price and active */}
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          spacing={0}>
                          <Grid item>
                            {' '}
                            <TextField
                              style={{ width: '100%' }}
                              label="Цена"
                              name="label"
                              type="text"
                              value={price.value || ''}
                              // @ts-ignore
                              onChange={handleDeliveryPriceChange}
                              autoComplete="off"
                            />
                          </Grid>
                          <Grid item>
                            <Switch
                              checked={active}
                              // @ts-ignore
                              onChange={handleChange}
                              name="active"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                  {/* Raw category */}
                  <Grid item>
                    <InputLabel
                      id="measure-select-label"
                      style={{ fontSize: 12, fontWeight: 400 }}>
                      Категория меню
                    </InputLabel>
                    <Select
                      displayEmpty
                      style={{ width: '100%', maxWidth: 200 }}
                      labelId="measure-select-label"
                      value={dish.categories[0]}
                      onChange={handleChangeCategory}>
                      <MenuItem value={0} disabled>
                        Выбрать
                      </MenuItem>
                      {categoriesState &&
                        Object.keys(categoriesState).length > 0 &&
                        Object.keys(categoriesState).map(key => (
                          <MenuItem key={key} value={key}>
                            {`${categoriesState[parseInt(key)].title}`}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>

                  {/* Row with difficulty */}
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={1}>
                      <Grid item>
                        {' '}
                        <InputLabel
                          id="measure-select-label"
                          style={{ fontSize: 12, fontWeight: 400 }}>
                          Измерение
                        </InputLabel>
                        <Select
                          displayEmpty
                          // style={{ minWidth: 160 }}
                          labelId="measure-select-label"
                          value={measure.value || 0}
                          onChange={handleChangeMeasure}>
                          <MenuItem value={0} disabled>
                            Выбрать
                          </MenuItem>
                          {dishesUnits &&
                            dishesUnits.length > 0 &&
                            dishesUnits.map(o => (
                              <MenuItem key={o.id} value={o.id}>
                                {o.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>

                      <Grid item>
                        {' '}
                        <InputLabel
                          id="measure-min-input"
                          style={{ fontSize: 12, fontWeight: 400 }}>
                          Mин колво
                        </InputLabel>
                        <TextField
                          name="min"
                          type="number"
                          style={{ minWidth: 40, width: 40 }}
                          value={minAmount.value || ''}
                          onChange={handleChangeMinAmount}
                          autoComplete="off"
                        />
                      </Grid>

                      <Grid item>
                        {' '}
                        <InputLabel
                          id="measure-difficulty-input"
                          style={{ fontSize: 12, fontWeight: 400 }}>
                          Сложность
                        </InputLabel>
                        <TextField
                          name="difficulty"
                          type="number"
                          style={{ minWidth: 40, width: 40 }}
                          value={difficulty.value || ''}
                          // @ts-ignore
                          onChange={handleChangeDifficulty}
                          autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Row with difficulty */}
                  {/* Row with description */}

                  {type === 'categories' && (
                    <Fragment>
                      <Grid item>
                        <TextField
                          style={{ width: '100%' }}
                          label="Состав"
                          name="description"
                          type="text"
                          value={description || ''}
                          onChange={setDescription}
                          autoComplete="off"
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          style={{ width: '100%' }}
                          label="БЖУ"
                          name="bgu"
                          type="text"
                          value={bgu || ''}
                          onChange={setBgu}
                          autoComplete="off"
                          multiline
                          rows={4}
                        />
                      </Grid>

                      {/* Bot */}
                      <CollapseCard title={'Бот'}>
                        <>
                          {/* Row with image preview */}
                          <Grid item>
                            <div>
                              <img
                                ref={photoRef}
                                onError={handleNoImage}
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                  maxWidth: '100%',
                                  maxHeight: '100%'
                                }}
                                src={`${
                                  process.env.REACT_APP_FRONT_URL
                                }/images/dishes/${
                                  dish.id
                                }.png?t=${new Date().getTime()}`}
                              />
                            </div>
                          </Grid>
                          {/* Row with image preview */}
                          {/* Row with image upload */}
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              alignItems="center"
                              spacing={2}>
                              <Grid item>
                                <Button
                                  variant="contained"
                                  component="label"
                                  color="primary"
                                  style={{ paddingRight: 10 }}>
                                  Выберите файл
                                  <input
                                    type="file"
                                    accept={ACCEPTED_PHOTO_FORMATS}
                                    id="inputFileId"
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                  />
                                </Button>
                              </Grid>
                              <Grid item>
                                {showPhotoLoading && (
                                  <CircularProgress disableShrink size={30} />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Row with image upload */}
                        </>
                      </CollapseCard>
                      {/* Bot */}
                      {/* Site */}
                      <CollapseCard title={'Сайт'}>
                        <>
                          <Site setSnack={setSnack} dishId={dish.id} />
                        </>
                      </CollapseCard>
                      {/* Site */}

                      {/* SEO */}
                      <Seo
                        setSnack={setSnack}
                        dishId={dish.id}
                      />
                      {/* SEO */}

                      {/* Children */}
                      <CollapseCard title={'Копмлекты'}>
                        <ItemsContainer>
                          <ItemsSelect {...getItemsSelectProps} />
                          <ItemsIncDecList {...getItemsListProps} />
                        </ItemsContainer>
                      </CollapseCard>
                      {/* Children */}

                      {/* Список потомков для блюда*/}
                    </Fragment>
                  )}
                  <Grid item style={{ paddingBottom: 32 }}></Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </Grid>
      </CardContent>
    </Card>
  );
};

// @ts-ignore
function renderRool(prevProps, nextProps) {
  if (nextProps.dish === prevProps.dish) return true;
}

// @ts-ignore
export default React.memo(withAlert(DishCard), renderRool);
