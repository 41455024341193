import clsx from 'clsx';

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from '@material-ui/core/styles';

import { FormControl, Select, Chip, MenuItem, Input } from '@material-ui/core';

import {
  getPlacesSelector,
  getMenuPlacesSelector,
  getOrderPlacesSelector,
  orderPlacesSet,
  menuPlacesSet,
  roleSelector
} from '../../store/login';
import { closeWs, wsConnect } from '../../store/ws';

export interface PlaceSelectProps {
  // order - for order list it can be multi select
  // menu - for menu and order create no multi select only one place is posible
  placeType: string;
  block?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    disabled: {
      backgroundColor: theme.palette.background.paper
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(
  placeId: number,
  itemPlaces: number[] | number,
  theme: Theme
) {
  // console.log('itemPlaces', itemPlaces, typeof itemPlaces);
  return Array.isArray(itemPlaces)
    ? {
        fontWeight:
          itemPlaces.indexOf(placeId) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium
      }
    : {
        fontWeight:
          itemPlaces === placeId
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium
      };
}

// Component to show place select filter
const PlaceSelect: React.SFC<PlaceSelectProps> = ({
  placeType,
  block = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [selectedPlaces, setSelectedPlaces] = useState<number[]>([]);

  const places = useSelector(getPlacesSelector);
  const currentPlaces = useSelector(getOrderPlacesSelector);
  const menuPlaces = useSelector(getMenuPlacesSelector);
  const userRole = useSelector(roleSelector);

  useEffect(() => {
    if (places) {
      if (placeType === 'order' && currentPlaces) {
        setSelectedPlaces(currentPlaces);
      } else if (placeType === 'menu' && menuPlaces) {
        setSelectedPlaces([menuPlaces as number]);
      }
    }
  }, [places]);

  const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    // If type order we can use multi select but only for managers

    if (placeType === 'order') {
      let selected = event.target.value as number[] | number;
      // If its manager selected value is number change it to array
      if (!Array.isArray(selected)) {
        selected = [selected];
      }
      setSelectedPlaces(selected as number[]);
      await dispatch({ type: orderPlacesSet.type, payload: selected });
    }
    if (placeType === 'menu') {
      const selected = event.target.value as number;
      setSelectedPlaces([selected] as number[]);
      await dispatch({ type: menuPlacesSet.type, payload: selected });
    }

    // Close socket
    await dispatch(closeWs());
    // Open with new place
    await dispatch(wsConnect());
  };

  return (
    <div style={{ width: '100%' }}>
      <FormControl
        className={clsx(classes.formControl, block && classes.disabled)}>
        <Select
          disabled={block}
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          // If type order we can use multi select but only for managers
          multiple={
            (placeType === 'menu' ? false : true) && userRole === 'manager'
          }
          value={selectedPlaces}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as string[]).map(value => (
                <Chip
                  key={value}
                  label={
                    places?.find(place => place.id === parseInt(value))?.title
                  }
                  color="primary"
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}>
          {places &&
            places.map(place => (
              <MenuItem
                key={place.id}
                value={place.id}
                style={getStyles(place.id, selectedPlaces, theme)}>
                {place.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PlaceSelect;
