import { PlaceModel } from './LoginModel';

export interface ChildRequestModel {
  parent_id: number;
  child_id: number;
  amount: number;
}

export interface ChildrenRequestModel {
  children: ChildRequestModel[];
}

export interface DishRequestModel {
  active: boolean;
  id: number;
  price: number;
  title: string;
  type: string;
  description: string;
  place: number;
  categories: number[];
  children?: ChildrenRequestModel;
}

export interface DishesRequestModel {
  [key: number]: DishRequestModel;
}

export interface ChildModel {
  [key: number]: number;
}

export interface DishResponseModel {
  active: boolean;
  id: number;
  price: number;
  title: string;
  type: string;
  difficulty: number;
  minAmount: number;
  dishesUnit: { id: number };
  meta: { description?: string; bgu?: string };
  place: PlaceModel;
  categories: number[];
  children: ChildModel;
}

export interface DishesStateModel {
  dict: { [key: number]: DishResponseModel };
}

export interface DishesModel {
  [key: number]: DishResponseModel;
}

export interface SeoMetaModel {
  slug: string;
  title: string;
  description: string;
  keywords: string;
}

export interface SiteMetaModel {
  description: string;
  photos: string[];
}

export enum DishMetaType {
  seo = 'seo',
  site = 'site'
}
