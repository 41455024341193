import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField, IconButton, Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircle';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const DishesTopBar = props => {
  const classes = useStyles();

  return (
    // <Card>
    //   <CardContent>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={2}>
      <Grid item xs={8}>
        <TextField
          id="search"
          style={{ width: '100%' }}
          label="Поиск по названию"
          name="search"
          type="text"
          // value={'Иванов Иван'}
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={4}>
        {/* <Button variant="contained" color="primary">
          Добавить
        </Button> */}
        {/* <IconButton
          aria-label="add"
          // onClick={onMakeReport} c
          olor="primary"
          style={{ marginTop: 12 }}>
          <AddIcon fontSize="large" />
        </IconButton> */}
      </Grid>
    </Grid>
    //   </CardContent>
    // </Card>
  );
};

export default DishesTopBar;
