import moment from 'moment';

import * as React from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  colors,
  Collapse
} from '@material-ui/core';
import EcoIcon from '@material-ui/icons/Eco';
import DownIcon from '@material-ui/icons/ExpandMore';
import UpIcon from '@material-ui/icons/ExpandLess';

import { ItemResponseModel } from 'interfaces/OrderResponseModel';
import {
  OrderStatuses,
  ItemStatuses
} from 'interfaces/OrderResponseModel';

import { getDishesSelector } from 'store/dishes';
import { getCategoriesSelector } from 'store/categories';
import { changeItemStatus } from 'store/order';
import { getUserSelector } from 'store/login';
import { getCooksSelector } from 'store/roles';

import { isExtremelyDeliveryTime } from 'utils/delivery';
import { getDishLabel } from 'utils/dishHelper';
import { formatDate } from 'libs/dateHelper';
import withAlert from 'hoc/withAlert';

import { SemisChangeCard } from '../';
import { getDeliveryOptionsSelector } from 'store/deliveryOptions';

enum OrderOwnerStatuses {
  me = 'me', //Pseudostatus
  all = 'all' // Pseudostatus
}

enum ordersStatuses {
  notDone = 'notDone', //Pseudostatus
  all = 'all' // Pseudostatus
}

export interface CookDishCardProps {
  // ordersStatus: ordersStatuses;
  // ordersOwnerStatus: OrderOwnerStatuses;
  item: ItemResponseModel;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

const CookDishCard: React.SFC<CookDishCardProps> = ({
  item,
  setSnack
  // ordersStatus,
  // ordersOwnerStatus
}) => {
  const dispatch = useDispatch();
  // Ве блюда
  const dishes = useSelector(getDishesSelector);
  // Все категории
  const categories = useSelector(getCategoriesSelector);
  // Все повара
  const cooks = useSelector(getCooksSelector);

  const deliveryOptions = useSelector(getDeliveryOptionsSelector)

  // Данные пользователя
  const user = useSelector(getUserSelector);

  // state
  // Закрыт открыт
  const [collapseState, setCollapseState] = useState(false);

  // helpers
  const getButtonLabel = () => {
    if (item.status === ItemStatuses.in_progress) {
      return 'Готово';
    } else if (item.status === ItemStatuses.done) {
      return 'Отмена';
    }
  };

  const getButtonHandler = () => {
    if (item.status === ItemStatuses.in_progress) {
      return handleChangeStatus(ItemStatuses.done);
    } else if (item.status === ItemStatuses.done) {
      return handleChangeStatus(ItemStatuses.in_progress);
    }
  };

  const getButtonColor = () => {
    if (item.status === ItemStatuses.in_progress) {
      return 'primary';
    } else if (item.status === ItemStatuses.done) {
      return 'secondary';
    }
  };

  const getChipColor = () => {
    if (
      item.orderStatus === OrderStatuses.delivering ||
      item.orderStatus === OrderStatuses.done
    ) {
      return 'primary';
    } else {
      return 'secondary';
    }
  };

  const getCookName = (cookId: number) => {
    const cook = cooks.filter(c => c.id === cookId)[0];
    if (cook) {
      return `${cook.person.surname ? cook.person.surname : ''} ${
        cook.person.name
      }`;
    }
    return '';
  };
  // helpers

  // handlers
  const handleChangeStatus = async (newStats: ItemStatuses) => {
    try {
      // Каждому айтему поставим статус дан
      if (item.dishItems && item.dishItems?.length > 0) {
        await Promise.all(
          item.dishItems.map(async it => {
            await dispatch(
              changeItemStatus(item.orderId as number, it as number, newStats)
            );
          })
        );
        setSnack({
          open: true,
          type: 'success',
          message: 'Изменения сохранены'
        });
      }
    } catch {
      setSnack({
        open: true,
        type: 'error',
        message: 'Изменения не сохранены'
      });
    }
  };

  const handleCollapse = () => {
    if (item.orderType !== 'semis') return;
    if (item.status === ItemStatuses.done) return;
    if (parseInt(user?.id as string) !== item.cook?.id) return;
    // console.log('handleCollapse');
    setCollapseState(!collapseState);
  };
  // handlers

  // effects

  return (
    <>
      {item.orderType !== 'semis' && (
        <Typography
          variant={'subtitle2'}
          color={item.order && isExtremelyDeliveryTime(item.order, deliveryOptions) ? 'error' : 'inherit'}
        >
          {formatDate(moment(item.deliveryTime))}
        </Typography>
      )}
      <Card
        style={{
          backgroundColor: `${
            item.status !== ItemStatuses.done ? 'white' : colors.grey[200]
          }`
        }}>
        <CardContent>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={1}
                wrap="nowrap">
                <Grid item xs={2}>
                  <Chip
                    size="small"
                    label={item.amount}
                    color={getChipColor()}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant={'body1'} onClick={e => handleCollapse()}>
                    {item.orderType !== 'semis' ? (
                      ` (#${item.orderId} ${item.orderPlace?.title}) `
                    ) : (
                      <EcoIcon />
                    )}
                    {getDishLabel(item.dish, false)}
                    {item.orderType === 'semis' &&
                      parseInt(user?.id as string) === item.cook?.id &&
                      item.status !== ItemStatuses.done &&
                      (collapseState ? <UpIcon /> : <DownIcon />)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {parseInt(user?.id as string) === item.cook?.id && (
                    <Button
                      style={{ fontSize: 10 }}
                      color={getButtonColor()}
                      fullWidth
                      size="large"
                      variant="contained"
                      disabled={
                        item.orderStatus === OrderStatuses.done ||
                        item.orderStatus === OrderStatuses.delivering
                      }
                      onClick={e => getButtonHandler()}>
                      {getButtonLabel()}
                    </Button>
                  )}
                  {parseInt(user?.id as string) !== item.cook?.id && (
                    <Typography variant={'body2'}>
                      {getCookName(item.cook?.id as number)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={collapseState}>
              <Grid item>
                <SemisChangeCard
                  item={item}
                  label={getDishLabel(item.dish, false)}
                />
              </Grid>
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default withAlert(CookDishCard);
