import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import _ from 'underscore';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import {
  OrderStateModel,
  ItemResponseModel,
  ItemStatuses,
  OrderStatuses
} from '../../interfaces/OrderResponseModel';

import { DateStr, toDateStr } from '../../interfaces/DateStr';

import { initPage, getPageReadyByPageSelector } from '../../store/api';
import { loadOrders, ordersReset, getOrderSelector } from '../../store/order';
import {
  getUserSelector,
  roleSelector,
  getMenuPlacesSelector
} from '../../store/login';
import { getRefreshSelector, refreshDone } from '../../store/ws';

import withAlert from '../../hoc/withAlert';
import useBecomeVisible, {
  saveRefreshTime
} from '../../hooks/useBecomeVisible';

import { CookDishCard } from './components';
import { PlaceSelect, DatesFilterTopBar } from '../../components';

enum orderOwnerStatuses {
  me = 'me', //Pseudostatus
  all = 'all' // Pseudostatus
}

enum orderTypeFilter {
  all = 'all',
  semis = 'semis',
  delivery = 'delivery'
}

enum ordersStatuses {
  notDone = 'notDone', //Pseudostatus
  all = 'all' // Pseudostatus
}

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    paddingTop: theme.spacing(1),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

type TParams = {};
type CookDishesPropsRoute = RouteComponentProps<TParams>;

export interface CookDishesProps extends CookDishesPropsRoute {
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  handleInputChange: (
    setMethod: Function
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

const CookDishes: React.SFC<CookDishesProps> = ({ match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // Заказы из стора
  const ordersState = useSelector(getOrderSelector) as OrderStateModel[];
  // Данные пользователя
  const user = useSelector(getUserSelector);
  //Данные загружены
  const pageReady = useSelector(getPageReadyByPageSelector(match.path));
  // Событие из ws
  const refresh = useSelector(getRefreshSelector);

  const menuPlace = useSelector(getMenuPlacesSelector);

  const userRole = useSelector(roleSelector);

  // States
  // Список позиций в заказах
  const [items, setItems] = useState<ItemResponseModel[]>([]);
  // Статус фильтра готовые или все
  const [ordersStatus, setOrdersStatus] = useState<ordersStatuses>(
    userRole === 'manager' ? ordersStatuses.all : ordersStatuses.notDone
  );
  // Статус на кого назначен заказ
  const [ordersOwnerStatus, setOrdersOwnerStatuses] = useState<
    orderOwnerStatuses
  >(userRole === 'manager' ? orderOwnerStatuses.all : orderOwnerStatuses.me);

  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date() as MaterialUiPickersDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date() as MaterialUiPickersDate
  );
  const [orderType, setOrderType] = useState<orderTypeFilter>(
    orderTypeFilter.all
  );

  // States

  // Helpers
  const getOrdersItems = (newOrders: OrderStateModel[]) => {
    return newOrders.reduce(
      (res: ItemResponseModel[], order: OrderStateModel) => {
        // Сделаем список айтемов для каждой позиции в заказе
        res = [
          ...res,
          ...order.items.reduce(
            (resItems: ItemResponseModel[], item: ItemResponseModel) => {
              // Для каждой позиции добавим несоклько полей с информацией о самом заказе
              resItems.push({
                ...item,
                orderStatus: order.status,
                orderType: order.type,
                orderPlace: order.place,
                orderId: order.id,
                deliveryTime: order.delivery?.start_time,
                order,
              });
              return resItems;
            },
            []
          )
        ];
        return res;
      },
      []
    );
  };

  // Группируем по блюду, заказу и повару - чтобы показывать в списке
  const groupItems = (newItems: ItemResponseModel[]) => {
    return newItems.reduce(
      (res: ItemResponseModel[], item: ItemResponseModel) => {
        const dishId = item.dish.id as number;
        const orderId = item.orderId as number;
        const cookId = item.cook?.id as number;
        const index = res.findIndex(
          it =>
            it.dish.id === dishId &&
            it.orderId === orderId &&
            it.cook?.id === cookId
        );
        if (index > -1) {
          res[index] = {
            ...res[index],
            amount: res[index].amount + item.amount,
            dishItems: (res[index].dishItems as number[]).concat([item.id])
          };
        } else {
          res.push({ ...item, dishItems: [item.id] });
        }
        return res;
      },
      []
    );
  };

  const applyFilters = (newItems: ItemResponseModel[]) => {
    return _.compose(
      applyOrdersOwnerStatusFilter,
      applyOrdersStatusFilter,
      applyNotCancelledFilter,
      applyOrdersTypeFilter
    )(newItems);
  };

  const applyOrdersStatusFilter = (newItems: ItemResponseModel[]) => {
    if (ordersStatus === ordersStatuses.notDone) {
      return newItems.filter(item => item.status !== ItemStatuses.done);
    }
    return newItems;
  };

  const applyOrdersOwnerStatusFilter = (newItems: ItemResponseModel[]) => {
    if (ordersOwnerStatus === orderOwnerStatuses.me) {
      return newItems.filter(
        item => (item.cook?.id as number) === parseInt(user?.id as string)
      );
    }
    return newItems;
  };

  const applyNotCancelledFilter = (newItems: ItemResponseModel[]) => {
    return newItems.filter(i => i.orderStatus !== OrderStatuses.cancelled);
  };

  const applyOrdersTypeFilter = (newItems: ItemResponseModel[]) => {
    return newItems.filter(item => {
      return orderType === orderTypeFilter.all || orderType === item.orderType;
    });
  };

  // Helpers

  // Handlers
  // Обработчик изменения дат
  const handleDatesChanged = async (startDate: DateStr, endDate: DateStr) => {
    // setOrders([]);
    await dispatch(
      loadOrders({
        startDate: startDate,
        endDate: endDate,
        orderBy: 'delivery',
        placesType: 'menu'
      })
    );
  };
  const handleOrdersStatus = () => {
    if (ordersStatus === ordersStatuses.all) {
      setOrdersStatus(ordersStatuses.notDone);
    } else if (ordersStatus === ordersStatuses.notDone) {
      setOrdersStatus(ordersStatuses.all);
    }
  };

  const handleOrdersOwnerStatus = () => {
    if (ordersOwnerStatus === orderOwnerStatuses.all) {
      setOrdersOwnerStatuses(orderOwnerStatuses.me);
    } else if (ordersOwnerStatus === orderOwnerStatuses.me) {
      setOrdersOwnerStatuses(orderOwnerStatuses.all);
    }
  };

  const handleStartDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedStartDate(date);
    },
    [selectedStartDate]
  );

  const handleEndDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedEndDate(date);
    },
    [selectedEndDate]
  );

  const handleOrderTypeChange = useCallback((_, type: orderTypeFilter) => {
    setOrderType(type);
  }, []);

  // Handlers

  // При переключении между вкладками или выходе из бекграунда
  const reloadOrders = async () => {
    saveRefreshTime();
    // Устаовим дефлотную дату
    setSelectedStartDate(selectedStartDate);
    setSelectedEndDate(selectedEndDate);
    // Обновим данные
    await dispatch(
      loadOrders({
        startDate: toDateStr(selectedStartDate as Date),
        endDate: toDateStr(selectedEndDate as Date),
        orderBy: 'delivery'
      })
    );
  };
  useBecomeVisible(reloadOrders);

  // Useeffects
  useEffect(() => {
    const getData = async () => {
      saveRefreshTime();

      await dispatch({ type: ordersReset.type });
      setItems([]);

      await initPage(match.path, dispatch);
      await dispatch(
        loadOrders({
          startDate: toDateStr(selectedStartDate as Date),
          endDate: toDateStr(selectedEndDate as Date),
          orderBy: 'delivery',
          placesType: 'menu'
        })
      );
    };
    getData();
  }, [dispatch, selectedStartDate, selectedEndDate, menuPlace]);

  useEffect(() => {
    if (ordersState) {
      setItems([]);
      const newItems = applyFilters(getOrdersItems(ordersState));
      setItems(groupItems(newItems));
    }
  }, [ordersState, orderType]);

  useEffect(() => {
    const newItems = applyFilters(getOrdersItems(ordersState));
    setItems(groupItems(newItems));
  }, [ordersStatus, ordersOwnerStatus]);

  // При событии рефреш провеярем нужно ли делать обновление и если нужно обновляем данные
  useEffect(() => {
    const refreshData = async () => {
      await dispatch({ type: ordersReset.type });
      setItems([]);

      await dispatch(
        loadOrders({
          startDate: toDateStr(selectedStartDate as Date),
          endDate: toDateStr(selectedEndDate as Date),
          orderBy: 'delivery'
        })
      );

      await dispatch({ type: refreshDone.type });
    };

    // Если пришел пустой рефреш ничего не делаем
    if (refresh) {
      // Нужно определить нужен рефреш или нет
      refreshData();
    }
  }, [refresh]);

  // Useeffects
  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          <Grid item>
            <DatesFilterTopBar
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              onDatesChanged={handleDatesChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={ordersStatus}
                  onChange={handleOrdersStatus}>
                  <ToggleButton
                    key={1}
                    value={ordersStatuses.notDone}
                    style={{ fontSize: 10 }}>
                    В работе
                  </ToggleButton>
                  <ToggleButton
                    key={2}
                    value={ordersStatuses.all}
                    style={{ fontSize: 10 }}>
                    Все
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {userRole !== 'trainee' && (
                <Grid item>
                  <ToggleButtonGroup
                    size="small"
                    exclusive
                    value={ordersOwnerStatus}
                    onChange={handleOrdersOwnerStatus}>
                    <ToggleButton
                      key={1}
                      value={orderOwnerStatuses.me}
                      style={{ fontSize: 10 }}>
                      Мои
                    </ToggleButton>
                    <ToggleButton
                      key={2}
                      value={orderOwnerStatuses.all}
                      style={{ fontSize: 10 }}>
                      Все
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              )}
              <Grid item>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={orderType}
                  onChange={handleOrderTypeChange}>
                  <ToggleButton
                    key={1}
                    value={orderTypeFilter.all}
                    style={{ fontSize: 10 }}>
                    Все
                  </ToggleButton>
                  <ToggleButton
                    key={2}
                    value={orderTypeFilter.semis}
                    style={{ fontSize: 10 }}>
                    З
                  </ToggleButton>
                  <ToggleButton
                    key={3}
                    value={orderTypeFilter.delivery}
                    style={{ fontSize: 10 }}>
                    Б
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>

          {!pageReady && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress disableShrink size={56} />
            </div>
          )}

          {pageReady &&
            items.length > 0 &&
            items.map(item => (
              <Grid item key={item.id}>
                <CookDishCard item={item} />
              </Grid>
            ))}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withRouter(withAlert(CookDishes));
