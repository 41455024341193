import { createSlice } from '@reduxjs/toolkit';
// @ts-ignore
import Joi from 'joi-browser';
// import queryString from 'query-string';

import http from '../libs/httpService';
import auth from '../libs/authService';
import { getUserRole } from '../utils/userHelper';
import { apiCallBegan, callSuccess, callFailed } from './api';
// Typescript for Redux support
import { LoginModel, UserModel, PlaceModel } from '../interfaces/LoginModel';
import { AppDispatch, AppThunk } from '../App';
import { RootState } from '../store/reducer';

import { createSelector } from 'reselect';

import { wsConnect } from './ws';

const queryString = require('query-string');

const apiEndpoint = '/auth/';

const getPlacesFromLocalStorage = (user: UserModel) => {
  const places = JSON.parse(localStorage.getItem('places') as string);
  const menuPlace = parseInt(localStorage.getItem('menuPlace') as string);
  let orderPlaces = JSON.parse(localStorage.getItem('orderPlaces') as string);
  if (orderPlaces) {
    orderPlaces = orderPlaces.map((x: string) => parseInt(x));
  }
  return { menuPlace, orderPlaces, places };
};

// Method to get places from local storage if they were set
const getPlaces = (user: UserModel, places: PlaceModel[]) => {
  const lsMenuPlace = parseInt(localStorage.getItem('menuPlace') as string);
  const isManager = !!user.roles?.find(r => r === 'manger');

  let menuPlace = places[0].id;
  if (lsMenuPlace) {
    if (!places.find(place => place.id === lsMenuPlace)) {
      localStorage.setItem('menuPlace', JSON.stringify(menuPlace));
    } else {
      menuPlace = lsMenuPlace;
    }
  } else {
    localStorage.setItem('menuPlace', JSON.stringify(menuPlace));
  }

  // Prepare place filter for list of orders
  let lsOrderPlaces = JSON.parse(localStorage.getItem('orderPlaces') as string);

  let orderPlaces = places.reduce((res: number[], place: PlaceModel) => {
    res.push(place.id);
    return res;
  }, []);

  // If not manager get only first place for order
  orderPlaces = isManager ? orderPlaces : [orderPlaces[0]];

  let orderPlacesCorrect = true;
  if (lsOrderPlaces) {
    lsOrderPlaces = lsOrderPlaces.map((x: string) => parseInt(x));
    lsOrderPlaces.forEach((placeId: number) => {
      if (!places.find(place => place.id === placeId)) {
        orderPlacesCorrect = false;
      }
    });
    if (!orderPlacesCorrect) {
      localStorage.setItem('orderPlaces', JSON.stringify(orderPlaces));
    } else {
      orderPlaces = isManager ? lsOrderPlaces : [lsOrderPlaces[0]];
    }
  } else {
    localStorage.setItem('orderPlaces', JSON.stringify(orderPlaces));
  }

  return { menuPlace, orderPlaces };
};

const initialState: LoginModel = {
  validationError: null,
  user: {
    id: null,
    name: null,
    surname: null,
    roles: []
  },
  places: [],
  orderPlaces: [],
  menuPlace: undefined,
  email: '',
  emailSent: false,
  hasError: false,
  error: null,
  xcode: null
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.user = prepareUSer(action.payload);
      const { orderPlaces, menuPlace, places } = getPlacesFromLocalStorage(
        action.payload
      );
      state.places = places;
      state.orderPlaces = orderPlaces;
      state.menuPlace = menuPlace;
      state.hasError = false;
      state.error = null;
    },
    userTokenValidatedError: (state, action) => {
      state.hasError = true;
      state.error = 'Не корректный емейл';
    },

    emailValidated: (state, action) => {
      state.email = action.payload;
      state.validationError = null;
    },
    emailValidatedWithError: (state, action) => {
      state.email = action.payload;
      state.validationError = 'Не корректный емейл';
    },
    emailSent: (state, action) => {
      state.emailSent = true;
      state.hasError = false;
      state.error = null;
      // state.xcode = action.payload.headers['x-code'];
      state.xcode = action.payload.data['CODE'];
    },
    codeSent: (state, action) => {
      const { user, places } = action.payload;
      state.user = prepareUSer(user);
      state.places = places;
      localStorage.setItem('places', JSON.stringify(places));

      const { orderPlaces, menuPlace } = getPlaces(user, places);
      state.orderPlaces = orderPlaces;
      state.menuPlace = menuPlace;
      state.hasError = false;
      state.error = null;
    },
    orderPlacesSet: (state, action) => {
      const orderPlaces = action.payload;
      state.orderPlaces = orderPlaces;
      localStorage.setItem('orderPlaces', JSON.stringify(orderPlaces));
    },
    menuPlacesSet: (state, action) => {
      const menuPlace = action.payload;
      if (Array.isArray(menuPlace)) {
        throw 'menuPlace is array, expected number';
      }
      state.menuPlace = menuPlace;
      localStorage.setItem('menuPlace', JSON.stringify(menuPlace));
    },
    codeSentValidatedWithError: (state, action) => {
      state.hasError = true;
      state.error = 'Не корректный код';
    },
    nameSent: (state, action) => {
      const { name, surname, id } = action.payload;
      state.user = state.user
        ? state.user
        : {
            id: null,
            name: null,
            surname: null
          };
      state.user.id = id;
      state.user.name = name;
      state.user.surname = surname;
      state.user.initials = prepareInitials(name, surname);
      state.hasError = false;
      state.error = null;
    },
    chefRoleSet: (state, action) => {
      // state.user?.roles?.push('chef');
      if (state.user) {
        state.user.roles = state.user.roles
          ? state.user.roles.concat(['chef'])
          : ['chef'];
      }
    },
    rolesUpdated: (state, action) => {
      // console.log('action.payload.data', action.payload.data);
      let { roles } = action.payload.data.user;
      if (!roles) roles = [];
      // @ts-ignore
      state.user = { ...state.user, roles };
    },
    chefRoleDeleted: (state, action) => {
      if (state.user && state.user.roles) {
        let roles = state.user?.roles?.filter(role => role !== 'chef');
        if (!roles) roles = [];
        state.user = { ...state.user, roles };
      }
    }
  }
});

export const {
  userLoggedIn,
  userTokenValidatedError,
  emailValidated,
  emailValidatedWithError,
  emailSent,
  codeSent,
  codeSentValidatedWithError,
  nameSent,
  chefRoleSet,
  chefRoleDeleted,
  rolesUpdated,
  orderPlacesSet,
  menuPlacesSet
} = slice.actions;
export default slice.reducer;

// Action Creators

export const init = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const response = await dispatch(
    apiCallBegan({
      url: '/auth/',
      actionName: 'init'
    })
  );
  // Установим в апи признак того что запрос завершился
  dispatch({ type: callSuccess.type, payload: { actionName: 'init' } });
  // @ts-ignore
  return http.setCSRF(response.csrf_token);
};

// @ts-ignore
export const autoLogin = location => {
  return async (dispatch: AppDispatch) => {
    const search = location.state
      ? location.state.from.search
      : location.search;

    const { token } = queryString.parse(search);

    if (token) {
      const data = await dispatch(
        apiCallBegan({
          url: `${apiEndpoint}?token=${token}`,
          method: 'post',
          actionName: 'sendToken'
        })
      );
      // @ts-ignore
      if (data.type === 'api/callFailed') return data;

      // Установим в апи признак того что запрос завершился
      dispatch({
        type: callSuccess.type,
        payload: { actionName: 'sendToken' }
      });
      // @ts-ignore
      const { access_token, refresh_token } = data;
      if (access_token) {
        auth.setAccessToken(access_token);
        auth.setRefreshToken(refresh_token);

        // localStorage.setItem('access_token', access_token);
        // localStorage.setItem('refresh_token', refresh_token);
        http.setJwt(access_token);
      }
      const user = auth.getCurrentUser();
      if (user) {
        dispatch({ type: userLoggedIn.type, payload: user });
      } else {
        dispatch({
          type: userTokenValidatedError.type,
          error: 'Не валидный токен'
        });
      }
    } else {
      // Если нет токена то пробуем взять пользовтеля из локалстораджа
      const user = auth.getCurrentUser();
      // console.log('autoLogin');
      if (user) {
        auth.setJwt();
        dispatch({ type: userLoggedIn.type, payload: user });
      }
    }
  };
};

export const validateEmail = (email: string) => {
  return async (dispatch: AppDispatch) => {
    const schema = {
      email: Joi.string()
        .email({ minDomainAtoms: 2 })
        .required()
    };
    const { error } = Joi.validate({ email }, schema, {});

    if (error) {
      dispatch({ type: emailValidatedWithError.type, payload: email });
    } else {
      dispatch({ type: emailValidated.type, payload: email });
    }
  };
};

export const sendEmail = (email: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  return await dispatch(
    apiCallBegan({
      url: `${apiEndpoint}email/?alias=${email}`,
      onSuccess: emailSent.type,
      method: 'post',
      headers: {
        'X-Auth-Mode': 'login'
      },
      actionName: 'sendEmail'
    })
  );
};

export const sendCode = (code: string) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const xcode = getState().login.xcode;
  // console.log('sendCode', getState().login);
  // console.log('sendCode', xcode);

  const data = await dispatch(
    apiCallBegan({
      url: `${apiEndpoint}email/code/?code=${code}`,
      method: 'post',
      actionName: 'sendCode',
      headers: {
        'X-code': xcode
      }
    })
  );
  // @ts-ignore
  if (data.type === 'api/callFailed') return data;

  // Установим в апи признак того что запрос завершился
  dispatch({
    type: callSuccess.type,
    payload: { actionName: 'sendCode' }
  });

  if (data) {
    // @ts-ignore
    const { access_token, refresh_token, places } = data;
    if (places.length === 0)
      return dispatch({ type: codeSentValidatedWithError.type });

    if (access_token) {
      auth.setAccessToken(access_token);
      auth.setRefreshToken(refresh_token);
      http.setJwt(access_token);
      const user = auth.getCurrentUser();

      if (user !== false) {
        // Init WS connection after success login
        // console.log('try connect at login');
        await dispatch({ type: codeSent.type, payload: { user, places } });
        // @ts-ignore
        return await dispatch(wsConnect());
      }
    }
  }
  return dispatch({ type: codeSentValidatedWithError.type });
};
// @ts-ignore
export const sendName = ({ name, surname }) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const response = await dispatch(
    apiCallBegan({
      url: '/users/',
      method: 'put',
      data: {
        name,
        surname
      },
      actionName: 'sendName'
    })
  );
  // @ts-ignore
  if (response.type === 'api/callFailed') return response;

  // Установим в апи признак того что запрос завершился
  dispatch({
    type: callSuccess.type,
    payload: { actionName: 'sendName' }
  });

  // Установим новые токены в которых есть имя юзера
  if (response) {
    // @ts-ignore
    const { access_token, refresh_token } = response;
    if (access_token) {
      auth.setAccessToken(access_token);
      auth.setRefreshToken(refresh_token);
      http.setJwt(access_token);
      const user = auth.getCurrentUser();
      if (user !== false) {
        // return dispatch({ type: codeSent.type, payload: user });
        return dispatch({
          type: nameSent.type,
          payload: { name: user.name, surname: user.surname, id: user.id }
        });
      }
    }
  }
  dispatch({
    type: callFailed.type,
    payload: { data: { actionName: 'sendName' }, error: 'Не корректный токен' }
  });
};

export const logout = () => {
  auth.logout();
  return { type: 'RESET_APP' };
};

// Helpers
const prepareUSer = (user: {
  id: string;
  person: { name: string; surname: string };
  roles?: string[];
}) => {
  return {
    id: user.id ? user.id : '',
    name: user.person.name,
    surname: user.person.surname,
    roles: user.roles,
    initials: prepareInitials(
      user.person.name as string,
      user.person.surname as string
    )
  };
};

const prepareInitials = (name: string, surname: string) => {
  return name && surname
    ? `${name.charAt(0).toUpperCase()}${surname.charAt(0).toUpperCase()}`
    : null;
};

export const setChefRole = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const userId = getState().login.user?.id;
  let orderPlaces = getState().login.orderPlaces;

  return dispatch(
    apiCallBegan({
      url: `/custom/user/${userId}/role/chef/`,
      data: { place: { id: orderPlaces && orderPlaces[0] } },
      method: 'patch',
      onSuccess: chefRoleSet.type,
      actionName: 'setChefRole'
    })
  );
};

export const deleteChefRole = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const userId = getState().login.user?.id;
  const orderPlaces = getState().login.orderPlaces;
  return dispatch(
    apiCallBegan({
      url: `/custom/user/${userId}/role/chef/?place_id=${
        (orderPlaces as number[])[0]
      }`,
      method: 'delete',
      onSuccess: chefRoleDeleted.type,
      actionName: 'deleteChefRole'
    })
  );
};

export const getRoles = () => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/user/role/`,
      onSuccess: rolesUpdated.type,
      actionName: 'getRoles'
    })
  );
};

// Selectors

export const getUserSelector = createSelector(
  (state: RootState) => state.login.user,
  user => user
);

export const getPlacesSelector = createSelector(
  (state: RootState) => state.login.places,
  places => places
);

export const getMenuPlacesSelector = createSelector(
  (state: RootState) => state.login.menuPlace,
  places => places
);

export const getOrderPlacesSelector = createSelector(
  (state: RootState) => state.login.orderPlaces,
  places => places
);

export const roleSelector = createSelector(
  (state: RootState) => state.login.user,
  user => {
    if (!user?.id) return 'notLogin';
    if (user?.roles) {
      return getUserRole(user);
    } else {
      auth.logout();
      return 'guest';
    }
  }
);

export const isMangerSelector = createSelector(
  (state: RootState) => state.login.user?.roles,
  roles => (roles ? roles.indexOf('manager') > -1 : false)
);

export const isCookSelector = createSelector(
  (state: RootState) => state.login.user?.roles,
  roles => (roles ? roles.indexOf('cook') > -1 : false)
);
