import React, { Fragment, useCallback } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';

// @ts-ignore
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
// @ts-ignore
const withAlert = Component => {
  // @ts-ignore
  const WithAlertComponent = props => {
    const [state, setState] = React.useState({
      open: false,
      type: 'success',
      message: ''
    });

    const dispatch = useDispatch();

    const dispatchHandler = useCallback(
      async (action: Function, ...params: any) => {
        try {
          const result = await dispatch(action(...params));
          // console.log('result', result);
          // console.log('result.payload.error', result.payload.error);
          if (result.payload.error) throw result.payload.data.error;
          setState({
            open: true,
            type: 'success',
            message: 'Изменения сохранены'
          });
        } catch (e) {
          // console.log('result.payload.error', e);

          setState({
            open: true,
            type: 'error',
            message: 'Ошибка: изменения не сохранены'
          });
          throw e;
        }
      },
      []
    );

    const handleInputChange = (setMethod: Function) => async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const newValue = event.target.value;
      setMethod({
        value: newValue ? newValue : '',
        state: 'changed'
      });
    };

    // @ts-ignore
    const handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setState({ ...state, open: false });
    };

    return (
      <Fragment>
        <Snackbar
          open={state.open}
          autoHideDuration={3000}
          onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity={state.type}>
            {state.message}
          </Alert>
        </Snackbar>

        <Component
          {...props}
          setSnack={setState}
          dispatchHandler={dispatchHandler}
          handleInputChange={handleInputChange}
        />
      </Fragment>
    );
  };
  return WithAlertComponent;
};

export default withAlert;
