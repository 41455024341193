import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';

import Select from 'react-select';

import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Divider
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';


import { useSelector, useDispatch } from 'react-redux';
import { getGuestsSelector } from '../../../../store/roles';
import { loadUserDeliveries } from '../../../../store/newOrder';

import useDebounce from '../../../../hooks/useDebounce';
import { string } from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  divider: {
    // @ts-ignore
    borderTop: `1px solid ${theme.palette.primary.main}`,
    margin: '10px 0'
  }
}));

const customSelectStyle = {
  // @ts-ignore
  control: styles => ({
    ...styles,
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial'
  }),
  // @ts-ignore
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial'
  })
};

export interface ClientCardProps {
  orderClient: {
    name: string | null;
    phone: string | null;
    userId: number | null;
  };
  onClientChange: ({
    name,
    phone,
    userId
  }: {
    name: string | null;
    phone: string | null;
    userId: number | null;
  }) => void;
}

const ClientCard: React.SFC<ClientCardProps> = ({
  onClientChange,
  orderClient
}) => {
  // const ClientCard = props => {
  const classes = useStyles();
  const guests = useSelector(getGuestsSelector);
  const dispatch = useDispatch();

  // Для списка гестов
  type selectedValueType = {
    value: number;
    label: string;
  } | null;

  const [client, setClient] = React.useState<{
    name: string | null;
    phone: string | null;
    userId: number | null;
  }>({
    name: '',
    phone: '',
    userId: null
  });

  // Текущее выбранное в селекте блюдо
  const [selectedValue, setSelectedValue] = useState<selectedValueType>();

  // Список всех возможных блюд в селекте
  const [options, setOptions] = useState<selectedValueType[]>([]);

  // Изменение выбранного блюда в селекте
  const handleSelectChange = async (selectedOption: any) => {
    setSelectedValue(selectedOption);
    if (selectedOption) {
      const newName = guests[parseInt(selectedOption.value)].name;
      const newPhone = guests[parseInt(selectedOption.value)].phone as string;
      const newUserId = parseInt(selectedOption.value);
      await dispatch(loadUserDeliveries(parseInt(selectedOption.value)));

      const newClient = {
        name: newName,
        phone: newPhone,
        userId: newUserId
      };
      setClient(newClient);
      onClientChange({
        name: null,
        phone: null,
        userId: newUserId
      });
    } else {
      await dispatch(loadUserDeliveries(null));

      setClient({
        name: '',
        phone: '',
        userId: null
      });
      onClientChange({
        name: null,
        phone: null,
        userId: null
      });
    }
  };

  const setSelectOptions = () => {
    // подготовим список пользователей
    const currentOptions = Object.keys(guests).map((key: string) => ({
      value: guests[parseInt(key)].id,
      label: `${guests[parseInt(key)].name} ${guests[parseInt(key)].phone}`
    }));

    setOptions(currentOptions as selectedValueType[]);
  };

  // const [userId, setUserId] = React.useState<number | null>(null);
  // const [name, setName] = React.useState('');
  // const [phone, setPhone] = React.useState('');

  const handleNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setClient({ ...client, name: event.target.value });
    onClientChange({ ...client, name: event.target.value });
  };
  const handlePhoneChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setClient({ ...client, phone: event.target.value });
    onClientChange({ ...client, phone: event.target.value });
  };

  useEffect(() => {
    setSelectOptions();
  }, [guests]);

  useEffect(() => {
    // reset only
    if (
      orderClient.userId === null &&
      orderClient.name === null &&
      orderClient.phone === null
    ) {
      setClient(orderClient);
      setSelectedValue(null);
    }
  }, [orderClient]);

  return (
    <Fragment>
      <Grid item>
        <div style={{ width: '100%' }}>
          <Select
            value={selectedValue}
            isClearable={true}
            // @ts-ignore
            onChange={handleSelectChange}
            // @ts-ignore
            options={options}
            styles={customSelectStyle}
          />
        </div>
      </Grid>

      <Grid item>
        <TextField
          style={{ width: '100%' }}
          label="Фамилия Имя"
          name="surname"
          type="text"
          value={client.name ? client.name : ''}
          disabled={client.userId !== null}
          onChange={handleNameChange}
          autoComplete="off"
        />
      </Grid>
      <Grid item>
        <TextField
          style={{ width: '100%' }}
          label="Телефон"
          name="phone"
          type="text"
          disabled={client.userId !== null}
          value={client.phone ? client.phone : ''}
          onChange={handlePhoneChange}
          autoComplete="off"
        />
      </Grid>
    </Fragment>
  );
};
export default ClientCard;
