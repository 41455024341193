import { LoginModel, UserRoles } from '../interfaces/LoginModel';
import { getUserRole } from './userHelper';

export const getPlaceQueryParam = (
  loginState: LoginModel,
  userRole: UserRoles
) => {
  // if (userRole === 'manager') {
  return 'place_id=' + loginState.orderPlaces?.map(p => `${p}`)?.join(',');
  // }
  // return `place_id=${loginState.menuPlace}`;
};
