import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Chip,
  colors
} from '@material-ui/core';
import EcoIcon from '@material-ui/icons/Eco';
import moment from 'moment';

import { isExtremelyDeliveryTime } from 'utils/delivery';
import { correctNounForm } from 'libs/textHelper';
import config from 'config/config.json';
import { OrderStateModel, ItemStatuses, OrderStatuses } from 'interfaces/OrderResponseModel';
import { paymentsStatus } from 'interfaces/OrderResponseModel';

import AppChip from 'components/AppChip';
import { getDeliveryOptionsSelector } from 'store/deliveryOptions';

export interface OrderCardProps {
  order: OrderStateModel;
  selected: boolean;
  onCardClick: (orderId: number) => void;
}

enum OrderStatusesInternal {
  new = 'new', //Pseudostatus
  notAll = 'notAll',
  cooking = 'cooking',
  ready = 'ready',
  delivering = 'delivering'
}

const OrderCard: React.SFC<OrderCardProps> = ({
  order,
  selected,
  onCardClick
}) => {
  const handleCardClick = () => {
    onCardClick(order.id);
  };
  const [status, setStatus] = React.useState<OrderStatusesInternal | string>('');

  const deliveryOptions = useSelector(getDeliveryOptionsSelector)

  // console.log('order.type',order.type);
  useEffect(() => {
    const itemsWithoutCutlery = order.items;

    const newStatuses = itemsWithoutCutlery.reduce((res: boolean[], item) => {
      res.push(item.status === ItemStatuses.new);
      return res;
    }, []);

    if (newStatuses.every(isNew => isNew === true)) {
      setStatus(OrderStatusesInternal.new);
      return;
    }
    if (newStatuses.some(isNew => isNew === true)) {
      setStatus(OrderStatusesInternal.notAll);
      return;
    }

    const doneStatuses = itemsWithoutCutlery.reduce((res: boolean[], item) => {
      res.push(item.status === ItemStatuses.done);
      return res;
    }, []);

    if (doneStatuses.every(isDone => isDone === true)) {
      setStatus(OrderStatusesInternal.ready);
      return;
    }

    setStatus(OrderStatusesInternal.cooking);
  }, [order]);

  const getChipColor = (status: OrderStatusesInternal | null | undefined) => {
    const chipColor = {
      new: colors.grey[600],
      notAll: colors.orange[600],
      cooking: colors.blue[600],
      ready: colors.teal[500]
    };

    // @ts-ignore
    return chipColor[status];
  };

  const getStatusLabel = (
    status: OrderStatusesInternal | null | undefined,
    orderStatus: string
  ) => {
    const chipColor = {
      new: 'Ждет',
      notAll: 'Не все в работе',
      cooking: 'В работе',
      ready: 'Выполнен',
      delivering: 'Доставка'
    };
    let colorKey = status;
    if (colorKey === OrderStatusesInternal.ready && orderStatus === 'delivering')
      colorKey = OrderStatusesInternal.delivering;
    // @ts-ignore
    return chipColor[colorKey];
  };

  const priceColor = React.useMemo(() => {
    if (order.payments?.length && order.payments[0].status === paymentsStatus.paid) {
      return 'primary'
    }

    return order.status === OrderStatuses.done ? 'secondary' : 'default'
  }, [order, paymentsStatus])

  const dateColor = React.useMemo(() => {
    return isExtremelyDeliveryTime(order, deliveryOptions) ? 'secondary' : 'primary'
  }, [order])

  return (
    <Card
      style={{
        backgroundColor: selected
          ? colors.teal[50]
          : status === 'new'
          ? '#ea80fc'
          : 'white'
      }}
      onClick={handleCardClick}>
      <CardContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}>
          {/* Строка с номером заказа и временем */}
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <Typography variant={'h5'}>{`#${order.id}`}</Typography>{' '}
                <Typography variant={'h6'}>{`${order.place.title}`}</Typography>
              </Grid>
              {order.type === 'semis' && (
                <Grid item>
                  <EcoIcon />
                </Grid>
              )}
              <Grid item>
                <AppChip
                  size="small"
                  label={moment(order.delivery?.start_time).format('DD.MM — HH:mm')}
                  color={dateColor}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Строка со статусом заказа */}
          <Grid item>
            <Chip
              size="small"
              label={getStatusLabel(
                status as OrderStatusesInternal,
                order.status as string
              )}
              // color={getChipColor(status as OrderStatusesInternal)}
              style={{
                backgroundColor: `${getChipColor(status as OrderStatusesInternal)}`,
                color: 'white'
              }}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <Typography variant={'h6'}>
                  {`Блюд: ${order.items.length} `}
                </Typography>
              </Grid>

              {order.type !== 'semis' && (
                <Grid item>
                  {config.process.cookCanGetPrice && (
                    <AppChip
                      size="small"
                      label={`${(order.itemsPrice as number) +
                        (order.delivery?.price as number)} ₽`}
                      color={priceColor}
                    />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* Строка с фамилией */}
          {order.type !== 'semis' && (
            <Grid item>
              <Typography variant={'body1'}>
                {order.delivery?.meta.name}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Chip
              size="small"
              label={
                order.sales
                  ? `${order.sales} ${correctNounForm(order.sales, [
                      'заказ',
                      'заказа',
                      'заказов'
                    ])}`
                  : 'Первый заказ'
              }
              color={order.sales ? 'default' : 'primary'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderCard;
