import { createAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import config from '../config/config.json';
import { RootState } from '../store/reducer';
import { ApiCallModel } from '../interfaces/ApiCallModel';
import { AppDispatch } from '../App';
import { strict } from 'assert';
import { string } from 'prop-types';
// Загрузим все редьюсеры чтобы можно было делать инит пейдж
import { loadOrders } from './order';
import { loadDishes, loadMenuDishes, loadOrderDishes } from './dishes';
import { loadDishesUnits } from './dishesUnits';
import {
  loadCategories,
  loadMenuCategories,
  loadOrderCategories
} from './categories';
import {
  loadMenuDeliveryOptions,
  loadOrderDeliveryOptions
} from './deliveryOptions';

import { loadCooks, loadCouriers, loadGuests, loadStaff } from './roles';

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}
export const apiCallBegan = createAction(
  'api/callBegan',
  withPayloadType<ApiCallModel>()
);

// export const apiCallSuccess = createAction('api/callSuccess');
// export const apiCallFailed = createAction('api/callFailed');

// loading: false,
// lastFetch: null,
// hasError: false,
// error: null,
interface ApiModel {
  loading: boolean;
  error: boolean | null;
  hasError: boolean;
  ready: boolean;
  lastFetch?: number;
  url: string;
}
const initialState: { requests: { [key: string]: ApiModel } } = {
  requests: {}
};

const slice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    callRequested: (api, action) => {
      const { actionName, url } = action.payload;
      api.requests[actionName] = {
        loading: true,
        error: null,
        hasError: false,
        ready: false,
        url
      };
    },
    callSuccess: (api, action) => {
      // console.log('callSuccess action.payload', action.payload);
      const { actionName, url } = action.payload;
      api.requests[actionName] = {
        loading: false,
        error: null,
        hasError: false,
        ready: true,
        lastFetch: Date.now(),
        url
      };
    },
    callFailed: (api, action) => {
      const { actionName, url } = action.payload.data;
      api.requests[actionName] = {
        loading: false,
        error: action.payload.error,
        ready: false,
        hasError: true,
        url
      };
    }
  }
});

export const { callRequested, callSuccess, callFailed } = slice.actions;
export default slice.reducer;

// Helper methods
const getName = (pageName: string): string => {
  return pageName.startsWith('/') ? pageName.slice(1) : pageName;
};

type pageRequestModel = {
  [key: string]: string[];
};
export const initPage = async (pageName: string, dispatch: AppDispatch) => {
  const fns: { [key: string]: Function } = {};
  fns['loadOrders'] = loadOrders;
  fns['loadDishes'] = loadDishes;
  fns['loadMenuDishes'] = loadMenuDishes;
  fns['loadOrderDishes'] = loadOrderDishes;
  fns['loadDishesUnits'] = loadDishesUnits;
  fns['loadCategories'] = loadCategories;
  fns['loadMenuCategories'] = loadMenuCategories;
  fns['loadOrderCategories'] = loadOrderCategories;
  fns['loadCouriers'] = loadCouriers;
  fns['loadCooks'] = loadCooks;
  fns['loadGuests'] = loadGuests;
  fns['loadStaff'] = loadStaff;
  fns['loadOrderDeliveryOptions'] = loadOrderDeliveryOptions;
  fns['loadMenuDeliveryOptions'] = loadMenuDeliveryOptions;

  const pageRequestsConfig: pageRequestModel = config.pageRequests;
  if (Object.keys(pageRequestsConfig).length === 0) return;
  const name = getName(pageName);
  let loaders: string[] = [];
  if (name in pageRequestsConfig) {
    loaders = pageRequestsConfig[name];
  } else {
    return;
  }

  await Promise.all(
    loaders.map(async loader => {
      await dispatch(fns[loader]());
    })
  );
};

// Selectors
const getActionName = (pageName: string): string[] => {
  const name = getName(pageName);
  const pageRequestsConfig: pageRequestModel = config.pageRequests;
  return pageRequestsConfig[name];
};

export const getPageReadyByPageSelector = (pageName: string) =>
  createSelector(
    (state: RootState) => state.api.requests,
    requests => {
      const actionNames = getActionName(pageName);

      const pageRequests = Object.keys(requests).reduce(
        (res: [], key: string) => {
          if (actionNames.indexOf(key) > -1) {
            // @ts-ignore
            res.push(requests[key].ready);
          }
          return res;
        },
        []
      );
      if (pageRequests.length < actionNames.length) return false;
      return pageRequests.every(x => x);
    }
  );

export const getPageReadySelector = (requestName: string) =>
  createSelector(
    (state: RootState) => state.api.requests,
    requests => {
      return requests[requestName] && requests[requestName]['ready']
        ? requests[requestName]['ready']
        : false;
    }
  );

// export const getLoadStateByPageSelector = (pageName: string) =>
//   createSelector(
//     (state: RootState) => state.api.requests,
//     requests => {
//       const actionNames = getActionName(pageName);
//       // config.pageRequests[
//       //   pageName.startsWith('/') ? pageName.slice(1) : pageName
//       // ];

//       // console.log('actionNames', pageName, actionNames);
//       const pageRequests = Object.keys(requests)
//         .map(function(r, key) {
//           if (key in actionNames) {
//             return requests[r].loading;
//           }
//         })
//         .filter(item => typeof item !== 'undefined');

//       if (pageRequests.length < actionNames.length) return true;
//       return pageRequests.some(x => x);
//     }
//   );

type ErrorModel = {
  hasError: boolean;
  error: boolean;
};
export const getRequestErrorsByPageSelector = (pageName: string) =>
  createSelector(
    (state: RootState) => state.api.requests,
    requests => {
      const actionNames = getActionName(pageName);
      // console.log('actionNames', pageName, actionNames);

      let requestsError: ErrorModel[] = [];

      if (typeof requests !== 'undefined') {
        requestsError = Object.keys(requests).map(function(r, key) {
          if (key in actionNames) {
            return { hasError: requests[r].hasError, error: requests[r].error };
          }
        }) as ErrorModel[];
      }
      return requestsError.filter(item => item).some(x => x.hasError)
        ? {
            hasError: true,
            error: requestsError.filter(e => e.hasError === true)[0].error
          }
        : { hasError: false, error: null };
    }
  );
