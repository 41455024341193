import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  TextField,
  IconButton,
  Collapse,
  Button
} from '@material-ui/core';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import { getDishMeta, changeDishMeta } from '../../../../../../store/dishes';
import { PhotoUpload } from '../../../../../../components';

import {
  photoUpload,
  PhotoUploadType
} from '../../../../../../utils/photoUpload';

import {
  DishMetaType,
  SeoMetaModel
} from '../../../../../../interfaces/DishesResponseModel';

const useStyles = makeStyles(theme => ({
  photo: {
    // @ts-ignore
    paddingTop: theme.spacing(2)
  }
}));

export interface SeoProps {
  dishId: number;
  // imagePath: string;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  // onSeoPhotoUpload: (file: File) => Promise<void>;
}

const Seo: React.SFC<SeoProps> = ({
  // onChangeField,
  dishId,
  // imagePath,
  setSnack
  // onSeoPhotoUpload,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const imagePath = `/images/site/dishes/s_${dishId}.png`;

  const [collapse, setCollapse] = useState(false);
  const [state, setState] = useState({
    slug: '',
    title: '',
    description: '',
    keywords: ''
  });

  const handleChangeField = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, type, name } = event.target;
    // @ts-ignore
    const newValue = type === 'checkbox' ? !state[name] : value;
    setState(state => {
      return { ...state, [name]: newValue };
    });
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const handelSeoPhotoUpload = async (file: File) => {
    await photoUpload(file, dishId, PhotoUploadType.seoDish);
  };

  const handelSave = async (state: SeoMetaModel) => {
    try {
      const result = await dispatch(
        changeDishMeta(dishId as number, state, DishMetaType.seo)
      );
      // @ts-ignore
      if (result.payload.error) throw result.payload.data.error;
      setSnack({
        open: true,
        type: 'success',
        message: 'Изменения сохранены'
      });
    } catch (e) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: изменения не сохранены'
      });
      throw e;
    }
  };

  const initSeo = async (dishId: number) => {
    const seoData = await dispatch(getDishMeta(dishId));
    // @ts-ignore
    setState(state => ({ ...state, ...seoData.meta.seo }));
    // console.log('seoData', seoData, seoData['seo']);
  };

  useEffect(() => {
    initSeo(dishId);
  }, []);

  return (
    <Card
      style={{
        overflow: 'visible',
        zIndex: 0,
        marginTop: 10
      }}>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          wrap="nowrap">
          <Grid item>
            <Typography variant={'h5'}>SEO</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center">
              <Grid item>
                <IconButton aria-label="minus" onClick={handleCollapse}>
                  <DownIcon />
                </IconButton>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={collapse}>
          {collapse && (
            <Grid item>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  required
                  label="ЧПУ"
                  name="slug"
                  type="text"
                  value={state.slug}
                  // @ts-ignore
                  onChange={handleChangeField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Title"
                  name="title"
                  type="text"
                  value={state.title}
                  // @ts-ignore
                  onChange={handleChangeField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Description"
                  name="description"
                  type="text"
                  value={state.description}
                  // @ts-ignore
                  onChange={handleChangeField}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Keywords"
                  name="keywords"
                  type="text"
                  value={state.keywords}
                  // @ts-ignore
                  onChange={handleChangeField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item style={{ paddingTop: 32 }}>
                <Button
                  color="primary"
                  // fullWidth
                  // size="large"
                  // disabled={true}
                  variant="contained"
                  onClick={() => {
                    handelSave(state);
                  }}>
                  Сохранить
                </Button>
              </Grid>
              <PhotoUpload
                imagePath={imagePath}
                onUpload={handelSeoPhotoUpload}
                classes={classes.photo}
                setSnack={setSnack}
              />
            </Grid>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default Seo;
