import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Switch,
  TextField,
  colors,
  Button,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import { itemTypes } from '../../interfaces/OrderRequestModel';

import { initPage } from '../../store/api';
import { addDish } from '../../store/dishes';
import {
  getCategorySelector,
  getCategoriesSelector
} from '../../store/categories';
import { getMenuPlacesSelector } from '../../store/login';

import { useItemsState } from '../../hooks/ItemsSelect';
import {
  ItemsSelect,
  ItemsContainer,
  ItemsIncDecList,
  ItemsChangeList
} from '../../components/ItemsSelect';
import { PlaceSelect } from '../../components';

import { DishesTopBar } from '../Category/components/';

import withAlert from '../../hoc/withAlert';
import useIgnoreRefresh from '../../hooks/useIgnoreRefresh';
import { getDishesUnitsSelector } from '../../store/dishesUnits';

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    paddingTop: theme.spacing(2),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

type TParams = { categoryId: string };
type DishPropsRoute = RouteComponentProps<TParams>;

export interface DishProps extends DishPropsRoute {
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

const Dish: React.SFC<DishProps> = ({
  match,
  location,
  setSnack,
  dispatchHandler
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const categoryId = match.params.categoryId;
  const category = useSelector(getCategorySelector(parseInt(categoryId)));
  const categories = useSelector(getCategoriesSelector);
  const menuPlace = useSelector(getMenuPlacesSelector);
  const dishesUnits = useSelector(getDishesUnitsSelector);

  const { type } = queryString.parse(location.search);

  const [categoryName, setCategoryName] = useState('');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState<number | string>(0);
  const [difficulty, setDifficulty] = useState<number | string>('');
  const [minAmount, setMinAmount] = useState<number | string>('');
  const [measure, setMeasure] = useState<number | string>('0');
  const [description, setDescription] = useState('');
  const [bgu, setBgu] = useState('');
  const [active, setActive] = useState(true);

  // игнорируем события рефреш
  useIgnoreRefresh();

  const { items, getItemsSelectProps, getItemsListProps } = useItemsState({
    places: menuPlace as number,
    itemType: itemTypes.items
  });

  const handleChangeActive = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setActive(event.target.value === 'true');
  };

  const handleChangePrice = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPrice = parseInt(event.target.value);
    setPrice(newPrice ? newPrice : 0);
  };

  const handleChangeDifficulty = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newDifficulty = parseFloat(ev.target.value);
    setDifficulty(newDifficulty ? newDifficulty : '');
  };

  const handleChangeMinAmount = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newMinAmount = parseFloat(ev.target.value);
    setMinAmount(newMinAmount ? newMinAmount : '');
  };

  const handleChangeMeasure = (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const newMeasure = parseInt(ev.target.value as string) || 0;
    setMeasure(newMeasure);
  };

  const handleChangeTitle = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTitle(event.target.value);
  };

  const handleChangeDescription = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleChangeBgu = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBgu(event.target.value);
  };

  const handleAddDish = async () => {
    if (title === '') {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: укажите название'
      });
      return;
    }
    // Для cutlery проверка не нужна, так как прайс 0
    if (price === '' && type === 'categories') {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: укажите цену'
      });
      return;
    }
    try {
      await dispatchHandler(
        addDish,
        title,
        categoryId,
        active,
        price,
        difficulty,
        minAmount,
        parseInt(measure as string),
        description,
        bgu,
        items.length > 0
          ? items.map(item => {
              return { dish_id: item.id, amount: item.amount };
            })
          : [],
        menuPlace
      );
      setTimeout(() => {
        history.push(`/category/${categoryId}?type=${type}`);
      }, 1000);
    } catch {}
  };

  // useEffects
  useEffect(() => {
    const getData = async () => {
      initPage('dish', dispatch);
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (categories && Object.keys(categories).length > 0) {
      setCategoryName(categories[parseInt(categoryId)].title);
    }
  }, [categories]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} block={true} />
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}
        wrap="nowrap">
        <Grid item>
          <DishesTopBar category={category} />
        </Grid>
        <Grid item>
          <Card
            style={{
              backgroundColor: `${active ? 'white' : colors.grey[200]}`,
              overflow: 'visible',
              zIndex: 0
            }}>
            <CardContent>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="stretch"
                spacing={2}>
                <Grid item>
                  <Typography variant={'h5'}>
                    {`Добавление блюда в ${categoryName}`}
                  </Typography>{' '}
                </Grid>

                {/* Row with name */}
                <Grid item>
                  <TextField
                    style={{ width: '100%' }}
                    label="Название"
                    name="label"
                    type="text"
                    value={title}
                    // @ts-ignore
                    onChange={handleChangeTitle}
                    autoComplete="off"
                  />
                </Grid>

                {/* Row with price and active */}
                {type === 'categories' && (
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={0}>
                      <Grid item>
                        {' '}
                        <TextField
                          style={{ width: '100%' }}
                          label="Цена"
                          name="label"
                          type="text"
                          value={price}
                          // @ts-ignore
                          onChange={handleChangePrice}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={active}
                          // @ts-ignore
                          onChange={handleChangeActive}
                          name="active"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* Row with price and active */}
                {/* Row with dish unit */}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}>
                    <Grid item>
                      {' '}
                      <InputLabel
                        id="measure-select-label"
                        style={{ fontSize: 12, fontWeight: 400 }}>
                        Измерение
                      </InputLabel>
                      <Select
                        displayEmpty
                        style={{ minWidth: 160 }}
                        labelId="measure-select-label"
                        value={measure}
                        onChange={handleChangeMeasure}>
                        <MenuItem value={0} disabled>
                          Выбрать
                        </MenuItem>
                        {dishesUnits &&
                          dishesUnits.length > 0 &&
                          dishesUnits.map(o => (
                            <MenuItem key={o.id} value={o.id}>
                              {o.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>

                    <Grid item>
                      {' '}
                      <TextField
                        // style={{ minWidth: 120 }}
                        label="Mин колво"
                        name="min_amount"
                        type="number"
                        value={minAmount}
                        onChange={handleChangeMinAmount}
                        autoComplete="off"
                      />
                    </Grid>

                    <Grid item>
                      {' '}
                      <TextField
                        // style={{ minWidth: 90 }}
                        label="Сложность"
                        name="difficulty"
                        type="number"
                        // inputProps={{ min: 1, max: 5, step: 1 }}
                        value={difficulty}
                        // @ts-ignore
                        onChange={handleChangeDifficulty}
                        autoComplete="off"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Row with dish unit */}

                {/* Row with description */}
                {type === 'categories' && (
                  <React.Fragment>
                    <Grid item>
                      <TextField
                        style={{ width: '100%' }}
                        label="Состав"
                        name="description"
                        type="text"
                        value={description}
                        // @ts-ignore
                        onChange={handleChangeDescription}
                        autoComplete="off"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{ width: '100%' }}
                        label="БЖУ"
                        name="description"
                        type="text"
                        value={bgu}
                        // @ts-ignore
                        onChange={handleChangeBgu}
                        autoComplete="off"
                        multiline
                        rows={4}
                      />
                    </Grid>
                    {/* Row with description */}

                    <Grid item>
                      <Typography variant={'h5'}>
                        Для сета добавьте список блюд
                      </Typography>{' '}
                    </Grid>

                    {/* Список выбранных блюд */}
                    <ItemsContainer>
                      <ItemsSelect {...getItemsSelectProps} />
                      <ItemsIncDecList {...getItemsListProps} />
                    </ItemsContainer>
                    {/* Список выбранных блюд */}
                  </React.Fragment>
                )}
                <Grid item style={{ paddingTop: 32 }}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    // disabled={true}
                    variant="contained"
                    onClick={handleAddDish}>
                    Добавить блюдо
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(withAlert(Dish));
