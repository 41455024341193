import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Chip,
  CardContent,
  Card,
  CardMedia,
  CardActions,
  IconButton
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  }
  // media: {
  //   height: 140
  // }
}));

const DishCard = props => {
  const classes = useStyles();
  const { title } = props;
  return (
    <Card>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          style={{ padding: 0 }}
          wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography variant={'h5'} noWrap>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center">
              <Grid item>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton>
                  <ListIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DishCard;
