import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Hidden
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import AddIcon from '@material-ui/icons/AddCircleOutline';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import RestaurantIcon from '@material-ui/icons/Restaurant';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
    // width: 500,
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0
  }
}));

const Footer = props => {
  const { className, userRole, ...rest } = props;
  const pathname = props.location.pathname;
  const defaultValue = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  const classes = useStyles();

  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Hidden lgUp>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        className={classes.stickToBottom}>
        {userRole === 'manager' && (
          <BottomNavigationAction
            label="Новый"
            value="new"
            icon={<AddIcon />}
            component={Link}
            to={'/new'}
          />
        )}

        {userRole === 'manager' && (
          <BottomNavigationAction
            label="Заказы"
            value="orders"
            icon={<ListIcon />}
            component={Link}
            to={'/orders'}
          />
        )}
        {userRole !== 'manager' && (
          <BottomNavigationAction
            label="Мои заказы"
            value="my_orders"
            icon={<ListIcon />}
            component={Link}
            to={'/my_orders'}
          />
        )}

        {/* my_orders */}
        {(userRole  === 'manager' || userRole === 'chef') && (
          <BottomNavigationAction
            label="Блюда"
            value="categories"
            icon={<MenuBookIcon />}
            component={Link}
            to={'/categories'}
          />
        )}
        <BottomNavigationAction
          label="Магия"
          value="cook"
          icon={<RestaurantIcon />}
          component={Link}
          to={'/cook'}
        />
      </BottomNavigation>
    </Hidden>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default withRouter(Footer);
