import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const UserTopBar = props => {
  const classes = useStyles();

  return (
    // <Card>
    //   <CardContent>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={2}>
      <Grid item xs={7}>
        <TextField
          id="search"
          style={{ width: '100%' }}
          label="Поиск по фамилии"
          name="search"
          type="text"
          // value={'Иванов Иван'}
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={5}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="courier125">Сортировка</InputLabel>
          <Select
            fullWidth
            labelId="courier125"
            value={1}
            // onChange={handleChange}
          >
            <MenuItem value={1}>По дате заказа</MenuItem>
            <MenuItem value={2}>По имени</MenuItem>
            <MenuItem value={3}>По номеру</MenuItem>
            <MenuItem value={4}>По колву</MenuItem>
            <MenuItem value={5}>По сумме</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    //   </CardContent>
    // </Card>
  );
};

export default UserTopBar;
