import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Chip,
  CardContent,
  Card,
  TextField,
  Collapse
} from '@material-ui/core';

import Select, { ValueType } from 'react-select';

import {
  changePersonName,
  changePersonSurname,
  changePersonPhone,
  changeTraineeMentor,
  deleteTraineeMentor,
  getCooksSelector
} from '../../../../store/roles';
import { roleSelector } from '../../../../store/login';

import { StaffResponseModel } from '../../../../interfaces/RolesResponseModel';
import { SelectOptionType } from '../../../../interfaces/ItemsSelect';

import { useDebounceStringField } from '../../../../hooks/useDebounce';
import withAlert from '../../../../hoc/withAlert';

const useStyles = makeStyles(theme => ({
  input: {
    // @ts-ignore
    width: '100%'
  }
}));

const customSelectStyle = {
  // @ts-ignore
  control: styles => ({
    ...styles,
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial'
  }),
  // @ts-ignore
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial'
  })
};

export interface PersonCardProps {
  person: StaffResponseModel;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

// @ts-ignore
const PersonCard: React.FunctionComponent<PersonCardProps> = ({
  person,
  dispatchHandler
}) => {
  const classes = useStyles();

  const [options, setOptions] = useState<SelectOptionType[]>();
  const [currentOption, setCurrentOption] = useState<SelectOptionType | null>();
  const [mentorName, setMentorName] = useState('');

  const cooks = useSelector(getCooksSelector);
  const userRole = useSelector(roleSelector);

  const rolesLabels = {
    manager: 'менеджер',
    cook: 'повар',
    chef: 'шеф',
    trainee: 'стажер',
    courier: 'курьер'
  };

  const getChipColor = (role: string) => {
    const chipColor = {
      manager: 'default',
      cook: 'primary',
      chef: 'secondary',
      trainee: 'secondary',
      courier: 'default'
    };
    // @ts-ignore
    return chipColor[role];
  };

  const [collapse, setCollapse] = useState(false);
  const handleCollapse = async () => {
    setCollapse(!collapse);
  };

  const changeName = async (value: string) => {
    await dispatchHandler(changePersonName, person.id as number, value);
  };

  const { value: name, setValue: setName } = useDebounceStringField(
    person.person.name,
    changeName
  );

  const changeSurname = async (value: string) => {
    await dispatchHandler(changePersonSurname, person.id as number, value);
  };

  const { value: surname, setValue: setSurname } = useDebounceStringField(
    person.person.surname,
    changeSurname
  );

  const changePhone = async (value: string) => {
    await dispatchHandler(changePersonPhone, person.id as number, value);
  };

  const { value: phone, setValue: setPhone } = useDebounceStringField(
    person.person.meta?.phone || '',
    changePhone
  );

  const handleAddMentor = async (option: ValueType<SelectOptionType>) => {
    try {
      if ((option as SelectOptionType).value === 0) {
        await dispatchHandler(deleteTraineeMentor, person.id as number);
        setCurrentOption(null);
      } else {
        await dispatchHandler(
          changeTraineeMentor,
          person.id as number,
          (option as SelectOptionType).value
        );
      }
    } catch {}
  };

  // For trainee prepare select
  useEffect(() => {
    if (
      cooks &&
      Object.keys(cooks).length > 0 &&
      person.roles.filter(p => p === 'trainee').length > 0
    ) {
      const newOptions = cooks.map(c => ({
        value: c.id,
        label: `${c.person.surname ? c.person.surname : ''} ${
          c.person.name ? c.person.name : ''
        }`
      }));
      newOptions.unshift({
        value: 0,
        label: 'Удалить текущего наставника'
      });
      setOptions(newOptions);

      if (person.person.meta?.mentor_id) {
        const mentor = cooks.find(c => c.id === person.person.meta?.mentor_id);
        if (mentor) {
          const mentorName = `${
            mentor.person.surname ? mentor.person.surname : ''
          } ${mentor.person.name ? mentor.person.name : ''}`;
          setMentorName(mentorName);
          setCurrentOption({
            value: mentor.id,
            label: mentorName
          });
        }
      }
    }
  }, [cooks, person]);

  // Set mentor

  return (
    <Card style={{ overflow: 'visible', zIndex: 0 }}>
      <CardContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}>
          <Grid item onClick={handleCollapse}>
            <Typography variant={'h5'}>
              {person.person.surname ? `${person.person.surname} ` : ''}
              {person.person.name}
            </Typography>
          </Grid>
          <Grid item onClick={handleCollapse}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
              wrap="nowrap">
              {person.roles.map(r => {
                if (Object.keys(rolesLabels).indexOf(r) > -1)
                  return (
                    <Grid item key={r}>
                      <Chip
                        // style={{ marginRight: 4 }}
                        size="small"
                        // @ts-ignore
                        label={rolesLabels[r]}
                        // color="primary"
                        color={getChipColor(r)}
                      />
                    </Grid>
                  );
              })}
            </Grid>
          </Grid>
          {person.roles.filter(p => p === 'trainee').length > 0 && (
            <Grid item>
              <Typography component="span" variant="body1">
                Наставник:{' '}
              </Typography>
              <Typography component="span" variant="body1">
                {person.person.meta?.mentor_id ? mentorName : 'Не задан'}
              </Typography>
            </Grid>
          )}
          <Grid item onClick={handleCollapse}>
            {person.places.map(place => {
              return (
                <Chip
                  key={place.id}
                  style={{ marginRight: 4 }}
                  size="small"
                  // @ts-ignore
                  label={place.title}
                />
              );
            })}
          </Grid>
          <Collapse in={collapse}>
            {collapse && (
              <>
                {userRole === 'manager' && (
                  <>
                    <Grid item>
                      <TextField
                        className={classes.input}
                        label="Фамилия"
                        name="surname"
                        type="text"
                        value={surname || ''}
                        onChange={setSurname}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        className={classes.input}
                        label="Имя"
                        name="name"
                        type="text"
                        value={name || ''}
                        onChange={setName}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        className={classes.input}
                        label="Телефон"
                        name="phone"
                        type="text"
                        value={phone || ''}
                        onChange={setPhone}
                        autoComplete="off"
                      />
                    </Grid>
                  </>
                )}
                {person.roles.filter(p => p === 'trainee').length > 0 && (
                  <Grid item style={{ paddingTop: 10 }}>
                    <Select
                      placeholder={'Выберите наставника'}
                      value={currentOption}
                      onChange={option => handleAddMentor(option)}
                      options={options}
                      styles={customSelectStyle}
                    />
                  </Grid>
                )}
              </>
            )}
          </Collapse>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default withAlert(PersonCard);
