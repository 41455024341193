import { DeliveryOptionModel } from 'interfaces/DeliveriesResponseModel'
import { OrderResponseModel, OrderStatuses } from 'interfaces/OrderResponseModel'

const HOUR = 1000 * 60 * 60
const MIN_DELIVERY_HOUR = 0.5

export const isExtremelyDeliveryTime = (order: OrderResponseModel, defaultDeliveryOptions: DeliveryOptionModel[]) => {
  const { delivery, status } = order

  const stillNotInDelivery = [
    OrderStatuses.new,
    OrderStatuses.approved,
    OrderStatuses.approving,
    OrderStatuses.cooking,
  ].includes(status)

  if (!stillNotInDelivery) {
    return false
  }

  if (!delivery) {
    console.warn('order.delivery равняется null')
    return false
  }

  const now = Number(new Date())
  const startTime = Number(new Date(delivery.start_time))

  // Если delivery.deliveryoption.type = "pickup",
  // нужно показывать красным, когда текущее время
  // больше или равно чем delivery.start_time
  if (delivery.deliveryoption.type === 'pickup') {
    return now >= startTime
  }

  const defaultDeliveryOption = defaultDeliveryOptions
    .find((option) => option.id === delivery.deliveryoption.id)
  
  if (!defaultDeliveryOption) {
    console.warn('delivery.deliveryoption не найден')
    return false
  }

  // defaultDeliveryHour НЕ может быть меньше чем полчаса
  const defaultDeliveryHour = Math.max(
    defaultDeliveryOption.defaultDeliveryHours - 1,
    MIN_DELIVERY_HOUR
  )

  // Если НЕ pickup, то нужно подсвечивать красным
  // если текущее время большеб или равно чем
  // delivery.start_time - (deliveryoptions.defaultDeliveryHours - 1)
  const extremelyTime = now >= (startTime - HOUR * defaultDeliveryHour)

  return extremelyTime
}
