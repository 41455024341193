import React, { useEffect, useState } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import { useSelector, useDispatch } from 'react-redux';

import { wsConnect } from './store/ws';
import { ConnectionState } from './interfaces/WsModel';
import { UserRoles } from './interfaces/LoginModel';

import {
  OrderList as OrderListView,
  CategoriesList as CategoriesListView,
  Category as CategoryView,
  NewCategory as NewCategoryView,
  // DailyMenu as DailyMenuView,
  // UserList as UserListView,
  NotFound as NotFoundView,
  Login as LoginView,
  Cook as CookView,
  Dish as DishView,
  NewOrder as NewOrderView,
  StaffList as StaffListView,
  CookDishes as CookDishesView
} from './views';

import { init, autoLogin, roleSelector } from './store/login';
import config from './config/config.json';

const Routes = () => {
  const [autoLoginFinished, setAutoLoginFinished] = useState(false);

  const userRole = useSelector(roleSelector);

  const dispatch = useDispatch();
  const location = useHistory().location;

  useEffect(() => {
    const getData = async () => {
      // Поставим SCRF токен
      await dispatch(init());
      // Попробуем авторизоваться по данным из локал стораджа или по токену в урле
      await dispatch(autoLogin(location));
      setAutoLoginFinished(true);
      // Init WS
      await dispatch(wsConnect());
    };
    getData();
  }, [dispatch]);

  if (!autoLoginFinished) return null;
  else
    return (
      <Switch>
        <Redirect
          exact
          from="/"
          to={`${config.defaultPaths[userRole as UserRoles]}`}
        />

        <RouteWithLayout
          component={OrderListView}
          // @ts-ignore
          exact
          roles={['manager']}
          layout={MainLayout}
          path="/orders"
        />
        <RouteWithLayout
          component={CookDishesView}
          // @ts-ignore
          exact
          roles={['chef', 'cook', 'manager', 'trainee']}
          layout={MainLayout}
          path="/my_orders"
        />

        <RouteWithLayout
          component={StaffListView}
          // @ts-ignore
          exact
          roles={['manager', 'chef', 'cook']}
          layout={MainLayout}
          path="/staff"
        />

        <RouteWithLayout
          component={NewOrderView}
          // @ts-ignore
          exact
          roles={['manager', 'chef', 'cook', 'trainee']}
          layout={MainLayout}
          path="/new"
        />

        <RouteWithLayout
          component={CategoriesListView}
          // @ts-ignore
          exact
          roles={['manager', 'chef']}
          layout={MainLayout}
          path="/categories"
        />

        <RouteWithLayout
          component={NewCategoryView}
          // @ts-ignore
          exact
          roles={['manager', 'chef']}
          layout={MainLayout}
          path="/category/new/:group"
        />

        <RouteWithLayout
          component={NewCategoryView}
          // @ts-ignore
          exact
          roles={['manager', 'chef']}
          layout={MainLayout}
          path="/category/edit/:categoryId"
        />

        <RouteWithLayout
          component={CategoryView}
          // @ts-ignore
          exact
          roles={['manager', 'chef']}
          layout={MainLayout}
          path="/category/:id"
        />

        <RouteWithLayout
          component={DishView}
          // @ts-ignore
          exact
          roles={['manager', 'chef']}
          layout={MainLayout}
          path="/dish/:categoryId"
        />

        {/* <RouteWithLayout
          component={DailyMenuView}
          // @ts-ignore
          exact
          roles={['manager']}
          layout={MainLayout}
          path="/daily_menu"
        /> */}

        {/* <RouteWithLayout
          component={UserListView}
          // @ts-ignore
          exact
          roles={['manager']}
          layout={MainLayout}
          path="/users"
        /> */}

        <RouteWithLayout
          component={CookView}
          // @ts-ignore
          exact
          layout={MainLayout}
          roles={['cook', 'manager', 'chef', 'trainee']}
          path="/cook"
        />

        <RouteWithLayout
          component={LoginView}
          // @ts-ignore
          exact
          layout={MinimalLayout}
          path="/login"
        />

        <RouteWithLayout
          component={NotFoundView}
          // @ts-ignore
          exact
          layout={MinimalLayout}
          path="/not-found"
        />

        <Redirect to={`${config.defaultPaths[userRole as UserRoles]}`} />
      </Switch>
    );
};

export default Routes;
