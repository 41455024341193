import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { StaffResponseModel } from '../../interfaces/RolesResponseModel';

import { initPage, getPageReadyByPageSelector } from '../../store/api';
import { getStaffSelector } from '../../store/roles';

import useIgnoreRefresh from '../../hooks/useIgnoreRefresh';
import withAlert from '../../hoc/withAlert';

import { PersonCard } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    paddingTop: theme.spacing(2),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

type TParams = {};
type StaffListPropsRoute = RouteComponentProps<TParams>;

export interface StaffListProps extends StaffListPropsRoute {
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  handleInputChange: (
    setMethod: Function
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

enum roleTypeFilter {
  all = 'all',
  manager = 'manager',
  cook = 'cook',
  chef = 'chef',
  trainee = 'trainee',
  courier = 'courier'
}

const StaffList: React.SFC<StaffListProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [roleType, setRoleType] = useState<roleTypeFilter>(roleTypeFilter.all);
  const [users, setUsers] = useState<StaffResponseModel[]>([]);

  const staff = useSelector(getStaffSelector);

  const pageReady = useSelector(getPageReadyByPageSelector(match.path));

  // игнорируем события рефреш
  useIgnoreRefresh();

  // Helpers
  const handleRoleTypeChange = useCallback((_, type: roleTypeFilter) => {
    setRoleType(type);
  }, []);

  // useEffects
  useEffect(() => {
    const getData = async () => {
      initPage(match.path, dispatch);
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (staff && staff.length > 0) {
      if (roleType === roleTypeFilter.all) {
        setUsers(staff);
      } else {
        setUsers(staff.filter(p => p.roles.indexOf(roleType) > -1));
      }
    }
  }, [roleType, staff]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}>
        <Grid item>
          <ToggleButtonGroup
            size="small"
            exclusive
            value={roleType}
            onChange={handleRoleTypeChange}>
            <ToggleButton
              key={1}
              value={roleTypeFilter.all}
              style={{ fontSize: 10 }}>
              Все
            </ToggleButton>
            <ToggleButton
              key={2}
              value={roleTypeFilter.manager}
              style={{ fontSize: 10 }}>
              Менедж
            </ToggleButton>
            <ToggleButton
              key={3}
              value={roleTypeFilter.cook}
              style={{ fontSize: 10 }}>
              Повара
            </ToggleButton>
            <ToggleButton
              key={4}
              value={roleTypeFilter.chef}
              style={{ fontSize: 10 }}>
              Шеф
            </ToggleButton>
            <ToggleButton
              key={5}
              value={roleTypeFilter.trainee}
              style={{ fontSize: 10 }}>
              Стажеры
            </ToggleButton>
            <ToggleButton
              key={6}
              value={roleTypeFilter.courier}
              style={{ fontSize: 10 }}>
              Курьеры
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {!pageReady && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CircularProgress disableShrink size={56} />
          </div>
        )}

        {users
          .filter(p => p.roles.indexOf('dev') === -1)
          .map(person => (
            <Grid item key={person.id}>
              <PersonCard person={person} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default withRouter(withAlert(StaffList));
