const fruitEmojes = [
  '🍇',
  '🍉',
  '🍋',
  '🍍',
  '🥭',
  '🍎',
  '🍏',
  '🍒',
  '🍓',
  '🥝',
  '🥥',
  '🥑',
  '🥕',
  '🥦'
];
const heartEmojes = [
  '💓',
  '💗',
  '💘',
  '💝',
  '😻',
  '😍',
  '🥰',
  '💖',
  '💕',
  '❤️'
];

export const getRandomFruit = () => {
  return fruitEmojes[Math.floor(Math.random() * fruitEmojes.length)];
};
export const getRandomHeart = () => {
  return heartEmojes[Math.floor(Math.random() * heartEmojes.length)];
};
