import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, CardContent, IconButton } from '@material-ui/core';

import AutorenewIcon from '@material-ui/icons/Autorenew';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import { loadOrders, ordersReset } from '../../../../store/order';
import { getRefreshSelector, refreshDone } from '../../../../store/ws';
import { prepareDate } from '../../../../libs/dateHelper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const OrderTopBar = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const refresh = useSelector(getRefreshSelector);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment(new Date())
      .add(-1, 'days')
      .toDate()
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

  const handleStartDateChange = date => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };

  const handleChangeDates = async () => {
    await dispatch({ type: ordersReset.type });
    props.onOrdersReset();
    await dispatch(
      loadOrders({
        startDate: prepareDate(selectedStartDate),
        endDate: prepareDate(selectedEndDate),
        type: props.orderType 
      })
    );
  };

  // При событии рефреш провеярем нужно ли делать обновление и если нужно обновляем данные
  useEffect(() => {
    const refreshData = async () => {
      await dispatch({ type: ordersReset.type });
      props.onOrdersReset();
      await dispatch(
        loadOrders({
          startDate: prepareDate(selectedStartDate),
          endDate: prepareDate(selectedEndDate),
          type: props.orderType 
        })
      );
      await dispatch({ type: refreshDone.type });
    };

    const refreshClear = async () => {
      await dispatch({ type: refreshDone.type });
    };
    // Если пришел пустой рефреш ничего не делаем
    if (refresh) {
      // Проверяем  сегодняшний день находится в текущем дипазоне или нет. Так как в этом компоненте фильтрации по дате создания, а не по дате заказа
      const isOrderBetweenSelected = moment().isBetween(
        moment(selectedStartDate),
        moment(selectedEndDate),
        'days',
        '[]'
      );

      if (isOrderBetweenSelected) {
        // console.log('refresh OrderList page');
        refreshData();
      } else {
        refreshClear();
      }
    }
  }, [refresh]);

  return (
    // <Card>
    //   <CardContent>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={1}>
      <Grid item xs={5}>
        <KeyboardDatePicker
          // className={classes.datePiker}
          margin="normal"
          id="order-start-date"
          label="Начальная дата"
          format="dd.MM.yyyy"
          value={selectedStartDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <KeyboardDatePicker
          // className={classes.datePiker}
          margin="normal"
          id="order-end-date"
          label="Конечная дата"
          format="dd.MM.yyyy"
          value={selectedEndDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          aria-label="add"
          onClick={handleChangeDates}
          color="primary"
          style={{ marginTop: 12 }}>
          <AutorenewIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
    //   </CardContent>
    // </Card>
  );
};

export default OrderTopBar;
