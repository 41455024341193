import moment from 'moment';

import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Typography, CardContent, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    // @ts-ignore
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  }
  // media: {
  //   height: 140
  // }
}));

export interface OrderSummaryProps {
  order: OrderStateModel;
}

const OrderSummary: React.SFC<OrderSummaryProps> = ({ order }) => {
  const classes = useStyles();

  return (
    <Fragment>
      {/* Номер зааказ */}
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={1}>
          <Grid item>
            <Typography variant={'h5'}>{`#${order.id}`}</Typography>
          </Grid>

          <Grid item>
            <Chip
              size="small"
              label={moment(order.delivery?.start_time).format(
                'YYYY-MM-DD HH:mm'
              )}
              color="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OrderSummary;
