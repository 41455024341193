import moment from 'moment';

import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, CardContent, Card, Button } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import useBecomeVisible, {
  saveRefreshTime
} from '../../hooks/useBecomeVisible';

import {
  OrderAddress,
  OrderCard,
  OrderDateFilter,
  OrderFullCard,
  OrdersSummary
} from './components';

import { PlaceSelect } from '../../components';

import { initPage, getPageReadyByPageSelector } from '../../store/api';
import { loadCouriers } from '../../store/roles';
import {
  loadOrders,
  ordersReset,
  getOrderStatusFilterSelector
} from '../../store/order';

import {
  OrderStateModel,
  OrderStatuses
} from '../../interfaces/OrderResponseModel';
import { WsNotificationModel } from '../../interfaces/WsModel';

import { getRoles, getOrderPlacesSelector } from '../../store/login';
import { getOrderSelector } from '../../store/order';
import { getRefreshSelector, refreshDone } from '../../store/ws';

import { prepareDate } from '../../libs/dateHelper';

import config from '../../config/config.json';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    // padding: theme.spacing(4),
    // @ts-ignore
    paddingTop: theme.spacing(2),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    // @ts-ignore
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  }
  // media: {
  //   height: 140
  // }
}));

// @ts-ignore
const Cook = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageReady = useSelector(
    getPageReadyByPageSelector(props.location.pathname)
  );
  const ordersState = useSelector(getOrderSelector) as OrderStateModel[];
  const refresh = useSelector(getRefreshSelector) as WsNotificationModel;
  const statusFilter = useSelector(getOrderStatusFilterSelector);
  const orderPlaces = useSelector(getOrderPlacesSelector);

  // Обработчики даты
  const [selectedDate, setSelectedDate] = React.useState(
    new Date() as MaterialUiPickersDate
  );
  // При загрузке данных нужно установить по дефолту первый ордер выбранным
  const [ordersLoaded, setOrdersLoaded] = React.useState(false);

  // При переключении между вкладками или выходе из бекграунда
  const reloadOrdersAndRole = async () => {
    saveRefreshTime();
    // Устаовим дефлотную дату
    setSelectedDate(selectedDate);

    // Обновим список заказов
    await Promise.all([
      dispatch(
        loadOrders({
          startDate: prepareDate(selectedDate as Date),
          endDate: prepareDate(selectedDate as Date),
          orderBy: 'delivery',
          type: 'delivery'
        })
      ),
      // Это нужно чтобы менять роль между поваром и шефом
      dispatch(getRoles())
      // Обновим список курьеров, если вдруг прилетел новый курьер для достависты
      // dispatch(loadCouriers())
    ]);
  };
  useBecomeVisible(reloadOrdersAndRole);

  const handleDateChange = async (date: MaterialUiPickersDate) => {
    // Сбрасываем признак того чт данные были загружены
    setOrdersLoaded(false);
    await dispatch(
      loadOrders({
        startDate: prepareDate(date as Date),
        endDate: prepareDate(date as Date),
        orderBy: 'delivery',
        type: 'delivery'
      })
    );
    setSelectedDate(date);
  };

  // Начальная закгрузка
  // @ts-ignore

  useEffect(() => {
    const getData = async () => {
      saveRefreshTime();
      await dispatch({ type: ordersReset.type });
      await initPage(props.location.pathname, dispatch);
      await dispatch(
        loadOrders({
          startDate: prepareDate(selectedDate as Date),
          endDate: prepareDate(selectedDate as Date),
          orderBy: 'delivery',
          type: 'delivery'
        })
      );
    };
    getData();
  }, [dispatch, orderPlaces]);

  // При рефреше провеярем нужно ли делать обновление и если нужно обновляем данные
  useEffect(() => {
    const refreshData = async () => {
      await dispatch({ type: ordersReset.type });
      await initPage(props.location.pathname, dispatch);
      await dispatch(
        loadOrders({
          startDate: prepareDate(selectedDate as Date),
          endDate: prepareDate(selectedDate as Date),
          orderBy: 'delivery',
          type: 'delivery'
        })
      );
      await dispatch({ type: refreshDone.type });
    };

    const refreshClear = async () => {
      await dispatch({ type: refreshDone.type });
    };

    if (refresh) {
      // console.log('refresh', refresh);
      if (
        moment(selectedDate as Date).format('YYYY-MM-DD') ===
        refresh.message_data?.delivery_date
      ) {
        // console.log('refresh COOK page');
        refreshData();
      } else {
        refreshClear();
      }
    }
  }, [refresh]);

  // @ts-ignore
  useEffect(() => {
    if (ordersState) {
      const newOrders = ordersState.filter(
        order =>
          [OrderStatuses.new, OrderStatuses.cancelled].indexOf(order.status) ===
          -1
      );

      // Сраюатывает только при первом обонвлении и потом при обонвлении даты, тогда выбираем первый ордер
      if (ordersLoaded === false) {
        if (newOrders.length > 0) setSelectedOrderId(newOrders[0].id);
        // Ставим признак того что данные обновились
        setOrdersLoaded(true);
      }

      setOrders(newOrders);
    }
  }, [ordersState]);

  useEffect(() => {
    if (statusFilter) {
      setOrders(
        ordersState.filter(
          order => order.status === (statusFilter as OrderStatuses)
        )
      );
    } else {
      setOrders(
        ordersState.filter(
          order =>
            [OrderStatuses.new, OrderStatuses.cancelled].indexOf(
              order.status
            ) === -1
        )
      );
    }
  }, [statusFilter]);

  // Локальный список заказов
  const [orders, setOrders] = React.useState<OrderStateModel[]>([]);

  // Выбранный заказ
  const [selectedOrderId, setSelectedOrderId] = React.useState<number | null>(
    null
  );

  const handleCardClick = (orderId: number) => {
    setSelectedOrderId(orderId);
  };

  const [sortOderType, setSortOderType] = React.useState('id');

  const handleChangeSortOrderType = (
    event: React.MouseEvent<HTMLElement>,
    newSortOderType: string
  ) => {
    if (newSortOderType !== null) {
      setSortOderType(newSortOderType);
    }
  };

  const sortOrders = (orders: OrderStateModel[]) => {
    if (sortOderType === 'id') {
      return orders.sort((a: OrderStateModel, b: OrderStateModel) =>
        a.id < b.id ? 1 : b.id < a.id ? -1 : 0
      );
    }
    if (sortOderType === 'time') {
      return orders.sort((a: OrderStateModel, b: OrderStateModel) =>
        moment(a.delivery?.start_time) < moment(b.delivery?.start_time)
          ? 1
          : moment(b.delivery?.start_time) < moment(a.delivery?.start_time)
          ? -1
          : 0
      );
    }

    return orders;
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}>
          <Grid item xs={5}>
            {/* Левая колонка с заказами */}
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={1}
              style={{ paddingLeft: 8 }}>
              <Grid item>
                <PlaceSelect placeType={'order'} />

                <OrderDateFilter
                  onDateChange={handleDateChange}
                  selectedDate={selectedDate}
                />
              </Grid>

              <Grid item>
                <ToggleButtonGroup
                  size="small"
                  exclusive
                  value={sortOderType}
                  onChange={handleChangeSortOrderType}>
                  <ToggleButton key={1} value="id" style={{ fontSize: 10 }}>
                    Номеру
                  </ToggleButton>
                  <ToggleButton key={2} value="time" style={{ fontSize: 10 }}>
                    Времени
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item>
                {config.process.cookCanGetPrice && (
                  <OrdersSummary orders={orders} />
                )}
              </Grid>

              {/* Заказы */}
              {/* Показываем только заказы не в статусе новый или отклоненный */}
              {pageReady &&
                orders.length > 0 &&
                sortOrders(orders).map(order => (
                  <Grid item key={order.id}>
                    <OrderCard
                      order={order}
                      selected={order.id === selectedOrderId}
                      onCardClick={handleCardClick}
                    />
                  </Grid>
                ))}

              {!statusFilter && pageReady && orders.length === 0 && (
                <Grid item>
                  <Card>
                    <CardContent>
                      <Typography
                        variant={
                          'h5'
                        }>{`За выбранную дату нет подтвержденных заказов`}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {/* Заказы */}
              {/* Следуюший заказ */}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            {/* Правая колонка с блюдами в заказе */}

            {selectedOrderId &&
              ordersLoaded &&
              orders.filter(order => order.id === selectedOrderId).length >
                0 && (
                <OrderFullCard
                  order={
                    orders.filter(order => order.id === selectedOrderId)[0]
                  }
                />
              )}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default Cook;
