import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import {
  getCategoriesSelector,
  getSemisCategoriesSelector,
  getCutleryCategoriesSelector
} from '../../store/categories';

import { CategoriesModel } from '../../interfaces/CategoriesModel';
import { itemTypes } from '../../interfaces/OrderRequestModel';
import { SelectItemModel } from '../../interfaces/ItemsSelect';
import {
  DishesModel,
  DishResponseModel
} from '../../interfaces/DishesResponseModel';

import { getDishesSelector } from '../../store/dishes';
import { getDishesUnitsSelector } from '../../store/dishesUnits';

const useDishes = (itemType: itemTypes, places: number) => {
  // Все блюда
  const dishes = useSelector(getDishesSelector);
  // Все категории
  const categories = useSelector(getCategoriesSelector);
  // Категория для приборов
  const cutleryCategories = useSelector(getCutleryCategoriesSelector).map(
    Number
  );
  // Категории для полуфабрикатов
  const semisCategories = useSelector(getSemisCategoriesSelector);
  // Список юнитов
  const dishesUnits = useSelector(getDishesUnitsSelector);

  const [options, setOptions] = useState<SelectItemModel[]>([]);

  // To init options only once
  const optionsInit = useRef(false);

  const hasSemiManufactures = (categoryId: number) => {
    return (
      Object.keys(semisCategories)
        .map(Number)
        .indexOf(categoryId) > -1
    );
  };

  const getDishLabel = (dishId: number, showCategory: boolean = true) => {
    try {
      if (
        Object.keys(dishes).length > 0 &&
        Object.keys(categories).length > 0
      ) {
        if (showCategory) {
          return `${dishes[dishId].id}. ${
            dishes[dishId].categories[0]
              ? categories[dishes[dishId].categories[0]].title
              : ''
          } ${
            dishes[dishId] ? dishes[dishId].title.replace(/<[^>]*>?/gm, '') : ''
          }`;
        } else {
          return `${dishes[dishId].id}. ${
            dishes[dishId] ? dishes[dishId].title.replace(/<[^>]*>?/gm, '') : ''
          }`;
        }
      }
    } catch {}
    return '';
  };

  const prepareOptions = () => {
    if (
      dishes &&
      Object.keys(dishes).length > 0 &&
      Object.keys(categories).length > 0
    ) {
      let placeDishes = {};
      let placeCategories = {};
      if (places) {
        placeDishes = Object.keys(dishes)
          .filter((key: string) => places === dishes[parseInt(key)].place.id)
          .reduce((obj: DishesModel, key: string) => {
            obj[parseInt(key)] = dishes[parseInt(key)];
            return obj;
          }, {});
        placeCategories = Object.keys(categories)
          .filter(
            (key: string) => places === categories[parseInt(key)].place.id
          )
          .reduce((obj: CategoriesModel, key: string) => {
            obj[parseInt(key)] = categories[parseInt(key)];
            return obj;
          }, {});
      }
      if (
        Object.keys(placeDishes).length > 0 &&
        Object.keys(placeCategories).length > 0
      ) {
        setOptions(
          // Object.keys(Object.keys(placeDishes).length > 0 ? placeDishes : dishes)
          Object.keys(placeDishes)
            .filter(key => {
              if (itemType === itemTypes.semis) {
                return dishes[parseInt(key)].categories.some(
                  hasSemiManufactures
                );
              } else if (itemType !== itemTypes.cutlery) {
                return (
                  !dishes[parseInt(key)].categories.some(hasSemiManufactures) &&
                  dishes[parseInt(key)].categories.filter(cat =>
                    cutleryCategories.includes(cat)
                  ).length === 0
                );
              } else {
                return (
                  dishes[parseInt(key)].categories.filter(cat =>
                    cutleryCategories.includes(cat)
                  ).length > 0
                );
              }
            })
            .map((key: string) => {
              const unit = dishes[parseInt(key)].dishesUnit
                ? dishesUnits.find(
                    u => u.id === dishes[parseInt(key)].dishesUnit.id
                  )
                : '';
              const res = {
                id: dishes[parseInt(key)].id,
                title: getDishLabel(
                  parseInt(key),
                  itemType !== itemTypes.cutlery
                ),
                price: dishes[parseInt(key)].price,
                amount: 0,
                minAmount: dishes[parseInt(key)].minAmount,
                unitLabel: unit ? (unit.title ? unit.title : '') : ''
              };
              return res;
            })
        );
      }
    }
  };

  useEffect(() => {
    prepareOptions();
  }, [itemType, places]);

  // To load only first time than dishes and categories ready
  useEffect(() => {
    if (
      dishes &&
      Object.keys(dishes).length > 0 &&
      Object.keys(categories).length > 0
      // && !optionsInit.current
    ) {
      prepareOptions();
      // optionsInit.current = true;
    }
  }, [dishes, categories]);

  return options;
};

export default useDishes;
