import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import { DeliveryOptionsStateModel } from '../interfaces/DeliveriesResponseModel';

const initialState: DeliveryOptionsStateModel = {
  list: []
};

const slice = createSlice({
  name: 'deliveryOptions',
  initialState: initialState,
  reducers: {
    deliveryOptionsLoaded: (deliveryOptions, action) => {
      deliveryOptions.list = action.payload.data.options;
    }
  }
});

export const { deliveryOptionsLoaded } = slice.actions;

export default slice.reducer;

// Action Creators

export const loadOrderDeliveryOptions = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  return await dispatch(
    // @ts-ignore
    loadDeliveryOptions(places, 'loadOrderDeliveryOptions')
  );
};

export const loadMenuDeliveryOptions = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let menuPlace = getState().login.menuPlace;
  const places = `place_id=${menuPlace}`;
  // @ts-ignore
  return await dispatch(loadDeliveryOptions(places, 'loadMenuDeliveryOptions'));
};

export const loadDeliveryOptions = (places: string, actionName: string) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  // let orderPlaces = getState().login.orderPlaces;
  // const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');

  return dispatch(
    apiCallBegan({
      url: `/api/delivery/options/?${places}`,
      onSuccess: deliveryOptionsLoaded.type,
      actionName: actionName
    })
  );
};

// Selectors;
export const getDeliveryOptionsSelector = createSelector(
  (state: RootState) => state.entities.deliveryOptions.list,
  deliveryOptions => deliveryOptions
);

// Helpers
