import moment from 'moment';

import React, { useState, useEffect, Fragment, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Button
} from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { initPage, getPageReadyByPageSelector } from '../../store/api';
import { addOrder } from '../../store/order';

import { getCutleryCategoriesSelector } from '../../store/categories';
import {
  getUserSelector,
  getMenuPlacesSelector,
  getPlacesSelector
} from '../../store/login';
import { getDeliveryOptionsSelector } from '../../store/deliveryOptions';

import {
  OrderRequestModel,
  DeliveryRequestModel,
  ItemRequestModel,
  OrderRequestBodyModel,
  NewOrderRequestModel,
  itemTypes
} from '../../interfaces/OrderRequestModel';
import {
  paymentsSystems,
  OrderStatuses
} from '../../interfaces/OrderResponseModel';
import { paymentsStatus } from '../../interfaces/OrderResponseModel';
import { SelectItemModel } from '../../interfaces/ItemsSelect';

import {
  ClientCard,
  DeliveryCard,
  SummaryCard,
  StatusCard
} from './components';

import { DishesCard, PriceChangeCard, PlaceSelect } from '../../components';

import withAlert from '../../hoc/withAlert';
import useIgnoreRefresh from '../../hooks/useIgnoreRefresh';

import config from '../../config/config.json';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    paddingTop: theme.spacing(1),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  card: {
    overflow: 'visible'
  }
}));

const prepareItems = (items: SelectItemModel[]) => {
  return items.reduce(
    (res: { dish: { id: number }; amount: number; price: number }[], item) => {
      res.push({
        dish: { id: item.id },
        amount: item.amount,
        price: item.price
      });
      return res;
    },
    []
  );
};

// @ts-ignore
const NewOrder = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const { setSnack } = props;

  const user = useSelector(getUserSelector);
  const menuPlaces = useSelector(getMenuPlacesSelector);
  const places = useSelector(getPlacesSelector);
  const deliveryOptionsPlaces = useSelector(getDeliveryOptionsSelector);

  const placeTitle =
    // @ts-ignore
    menuPlaces && places ? places?.find(p => p.id === menuPlaces)['title'] : '';

  // Заготовки создают шефы и повара, заказы менеджеры
  const newSemiManufactures = !(
    user?.roles && user?.roles.indexOf('manager') > -1
  );

  // игнорируем события рефреш
  useIgnoreRefresh();

  // Категория для приборов

  const pageReady = useSelector(
    getPageReadyByPageSelector(
      newSemiManufactures ? 'new_semis' : props.location.pathname
    )
  );

  const initOrder: NewOrderRequestModel = {
    client: { id: null },
    items: [],
    presents: [],
    cutlery: [],
    semis: [],
    delivery: {
      option: { id: null },
      start_time: null,
      address: null,
      name: null,
      phone: null,
      price: null
    },
    comment: '',
    price: 0,
    status: null,
    paymentStatus: null,
    paymentSystem: null,
    type: 'delivery'
  };
  const [order, setOrder] = React.useState<NewOrderRequestModel>(initOrder);

  const [orderSending, setOrderSending] = React.useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      if (newSemiManufactures) {
        initPage('new_semis', dispatch);
      } else {
        initPage(props.location.pathname, dispatch);
      }
    };
    getData();
  }, [dispatch, menuPlaces]);

  const handleClientChange = ({
    name,
    phone,
    userId
  }: {
    name: string | null;
    phone: string | null;
    userId: number | null;
  }) => {
    let newOrder = order as NewOrderRequestModel;
    let newDelivery = newOrder.delivery as DeliveryRequestModel;
    newOrder.delivery = {
      ...newDelivery,
      name,
      phone
      // user_id: userId
    };
    newOrder.client = { id: userId };
    setOrder(newOrder);
  };

  // Change items
  const handleAddNew = async (item: SelectItemModel, itemType: itemTypes) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    let newItems = [...(newOrder[itemType] as Array<SelectItemModel>)];
    newItems.push(item);
    // @ts-ignore
    newOrder[itemType] = newItems;
    if (itemType === itemTypes.items) {
      newOrder.price = newItems.reduce((res, item) => {
        res += item.price * item.amount;
        return res;
      }, 0);
    }
    // console.log('newOrder', newOrder);
    setOrder(newOrder);
  };

  const handleChangeAmount = async (
    item: SelectItemModel,
    itemType: itemTypes
  ) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    let newItems = [...(newOrder[itemType] as Array<SelectItemModel>)];

    const itemIndex = newItems.findIndex(i => i.id === item.id);
    newItems[itemIndex].amount = item.amount;

    // @ts-ignore
    newOrder[itemType] = newItems.filter(item => item.amount > 0);
    if (itemType === itemTypes.items) {
      newOrder.price = newItems.reduce((res, item) => {
        res += item.price * item.amount;
        return res;
      }, 0);
    }
    setOrder(newOrder);
  };

  const onAmountMinusHandler = async (
    item: SelectItemModel,
    itemType: itemTypes
  ) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    let newItems = [...(newOrder[itemType] as Array<SelectItemModel>)];

    if (item.amount > 0) {
      const itemIndex = newItems.findIndex(i => i.id === item.id);
      newItems[itemIndex].amount = item.amount;
    } else {
      newItems = [...newItems.filter(i => i.id !== item.id)];
    }

    // @ts-ignore
    newOrder[itemType] = newItems.filter(item => item.amount > 0);
    if (itemType === itemTypes.items) {
      newOrder.price = newItems.reduce((res, item) => {
        res += item.price * item.amount;
        return res;
      }, 0);
    }
    setOrder(newOrder);
  };

  // Change items

  const handlePresentsChange = (items: SelectItemModel[]) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    newOrder.presents = items;
    setOrder(newOrder);
  };

  const handlePriceChange = (price: number) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    newOrder.price = price;
    setOrder(newOrder);
  };

  const handleDeliveryChange = ({
    start_time,
    // cutleryAmount,
    deliveryOptionId,
    deliveryIsPickup,
    price,
    address,
    comment
  }: {
    start_time: string;
    // cutleryAmount: number;
    deliveryOptionId: number;
    deliveryIsPickup: boolean;
    price: number;
    address: string;
    comment: string;
  }) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    newOrder.comment = comment;
    let newDelivery = newOrder.delivery as DeliveryRequestModel;
    newOrder.delivery = {
      ...newDelivery,
      option: { id: deliveryOptionId },
      start_time,
      address,
      price
    };

    setOrder(newOrder);
  };

  const handleStateChange = ({
    status,
    paymentStatus,
    paymentSystem
  }: {
    status: OrderStatuses;
    paymentStatus: paymentsStatus;
    paymentSystem: paymentsSystems;
  }) => {
    let newOrder = { ...order } as NewOrderRequestModel;
    newOrder.status = status;
    newOrder.paymentStatus = paymentStatus;
    newOrder.paymentSystem = paymentSystem;

    setOrder(newOrder);
  };

  const resetHandler = async () => {
    await setOrder(initOrder);
  };

  const handleOrderCreate = async () => {
    setOrderSending(true);

    if (newSemiManufactures) {
      let semisOrder = order as NewOrderRequestModel;
      semisOrder.client = null;
      semisOrder.delivery = null;
      semisOrder.cook = { id: parseInt(user?.id as string) };
      semisOrder.type = 'semis';
      setOrder(semisOrder);
    }

    if (!order.client?.id && !newSemiManufactures) {
      if (
        order.delivery?.name === '' ||
        order.delivery?.phone === '' ||
        !order.delivery?.name ||
        !order.delivery?.phone
      ) {
        setSnack({
          open: true,
          type: 'error',
          message: 'Ошибка: не веедены данные по клиенту'
        });
        setOrderSending(false);
        return;
      }
    }

    if (
      newSemiManufactures ? order.semis.length === 0 : order.items.length === 0
    ) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: не выбрана ни одна позиция'
      });
      setOrderSending(false);
      return;
    }

    if (
      order.delivery?.address === '' &&
      !newSemiManufactures &&
      deliveryOptionsPlaces.filter(dv => dv.id === order.delivery?.option.id)[0]
        .deliveryType !== 'pickup'
    ) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: не указан адрес доставки'
      });
      setOrderSending(false);
      return;
    }

    // Подготовим объект к нужному виду
    let items = prepareItems(newSemiManufactures ? order.semis : order.items);
    if (order.presents.length > 0)
      items = items.concat(prepareItems(order.presents));

    if (order.cutlery.length > 0)
      items = items.concat(prepareItems(order.cutlery));

    // status: OrderStatuses.approved,
    // paymentStatus: paymentsStatus.not_paid,
    // paymentSystem: paymentsSystems.cp,

    let body: OrderRequestBodyModel = {
      place: { id: menuPlaces as number },
      client: { id: order.client?.id as number },
      items,
      delivery: order.delivery as DeliveryRequestModel,
      comment: order.comment,
      price: order.price,
      status: order.status ? order.status : OrderStatuses.approved,
      type: order.type,
      cook: order.cook ? order.cook : null,
      payments: [
        {
          status: order.paymentStatus
            ? order.paymentStatus
            : paymentsStatus.not_paid,
          system: order.paymentSystem ? order.paymentSystem : paymentsSystems.cp
        }
      ]
    };
    try {
      const result = await dispatch(addOrder(body));
      // @ts-ignore
      if (result.payload.error) throw result.payload.data.error;
      setSnack({
        open: true,
        type: 'success',
        // @ts-ignore
        message: `Заказ #${result.payload.data.order.id} добавлен`
      });
      // Разюлокируем кнопку
      setOrderSending(false);
      // Обнулим заказ
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        if (newSemiManufactures) {
          history.push(`/my_orders`);
        } else {
          // location.reload();
          window.location.reload(false);
          // setOrder(initOrder);
        }
      }, 2000);
      // setOrder(initOrder);
    } catch (e) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: заказ не добавлен'
      });
      setOrderSending(false);
      throw e;
    }
  };

  const orderClient = useMemo(() => {
    return {
      name: order.delivery?.name as string,
      phone: order.delivery?.phone as string,
      userId: order.client?.id as number
    };
  }, [order.delivery?.name, order.delivery?.phone, order.client?.id]);

  const orderDelivery = useMemo(() => {
    return {
      start_time: order.delivery?.start_time as string,
      deliveryOptionId: order.delivery?.option.id as number,
      price: order.delivery?.price as number,
      address: order.delivery?.address as string,
      comment: order.comment as string
    };
  }, [order.delivery]);

  const orderStatus = useMemo(() => {
    return {
      status: order.status,
      paymentStatus: order.paymentStatus,
      paymentSystem: order.paymentSystem
    };
  }, [order.status, order.paymentStatus, order.paymentSystem]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} />

      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={2}>
              {!pageReady && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <CircularProgress disableShrink size={56} />
                </div>
              )}
              {newSemiManufactures && (
                <>
                  <Grid item>
                    <Typography variant={'h5'}>{`Заготовки`}</Typography>
                  </Grid>
                  <DishesCard
                    places={menuPlaces as number}
                    itemType={itemTypes.semis}
                    onAddNew={handleAddNew}
                    onAmountChange={handleChangeAmount}
                    listType={'change'}
                    onReset={resetHandler}
                  />
                </>
              )}
              {!newSemiManufactures && (
                <>
                  <Grid item>
                    <Typography variant={'h5'}>{`Клиент`}</Typography>
                  </Grid>
                  <Grid item>
                    <ClientCard
                      onClientChange={handleClientChange}
                      orderClient={orderClient}
                    />
                  </Grid>

                  <Grid item>
                    <Typography variant={'h5'}>{`Позиции`}</Typography>
                  </Grid>
                  <DishesCard
                    itemType={itemTypes.items}
                    places={menuPlaces as number}
                    onAddNew={handleAddNew}
                    onAmountChange={handleChangeAmount}
                    onAmountPlus={handleChangeAmount}
                    onAmountMinus={onAmountMinusHandler}
                    onReset={resetHandler}
                  />

                  <Grid item>
                    <Typography variant={'h5'}>{`Подарки`}</Typography>
                  </Grid>
                  <DishesCard
                    itemType={itemTypes.presents}
                    places={menuPlaces as number}
                    onAddNew={handleAddNew}
                    onAmountChange={handleChangeAmount}
                    onAmountPlus={handleChangeAmount}
                    onAmountMinus={onAmountMinusHandler}
                    onReset={resetHandler}
                  />

                  <PriceChangeCard
                    onPriceChange={handlePriceChange}
                    price={order.price}
                  />
                  <Grid item>
                    <Typography variant={'h5'}>{`Доставка`}</Typography>
                  </Grid>
                  <DeliveryCard
                    places={menuPlaces as number}
                    orderDelivery={orderDelivery}
                    onDeliveryChange={handleDeliveryChange}
                    orderPrice={order.items.reduce((res, item) => {
                      res += item.price * item.amount;
                      return res;
                    }, 0)}
                  />
                  <Grid item>
                    <Typography variant={'h5'}>{`Столовые приборы`}</Typography>
                  </Grid>

                  <DishesCard
                    itemType={itemTypes.cutlery}
                    places={menuPlaces as number}
                    onAddNew={handleAddNew}
                    onAmountChange={handleChangeAmount}
                    onAmountPlus={handleChangeAmount}
                    onAmountMinus={onAmountMinusHandler}
                    onReset={resetHandler}
                  />

                  <Grid item>
                    <Typography variant={'h5'}>{`Статус`}</Typography>
                  </Grid>

                  <StatusCard
                    orderStatus={orderStatus}
                    onStateChange={handleStateChange}
                  />

                  <Grid item>
                    <Typography variant={'h5'}>{`Итого`}</Typography>
                  </Grid>
                  <SummaryCard order={order} placeTitle={placeTitle} />
                </>
              )}
              <Grid item>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  disabled={orderSending}
                  variant="contained"
                  onClick={handleOrderCreate}>
                  {newSemiManufactures ? 'Добавить заготовки' : 'Создать заказ'}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withAlert(NewOrder);
