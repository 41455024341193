import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';


import { roleSelector, getUserSelector } from '../../store/login';
import config from '../../config/config.json';


const RouteWithLayout = props => {
  const { layout: Layout, component: Component, ...rest } = props;
  const userRole = useSelector(roleSelector);
  const user = useSelector(getUserSelector);

  // Если есть роли то проверим есть ли у юзера нужная роль
  if (props.roles) {
    if (props.roles.indexOf(userRole) === -1) {
      // Если пользователь авторизован то редиректим на дефолтную страницу
      if (user.id) return (<Redirect exact to={`${config.defaultPaths[userRole]}`} />)
      // Если не авторизован то на логин
      return (<Redirect to="/login" />)
    }
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
