import moment from 'moment';
import config from '../config/config.json';

// Константа с данными на ближайшие несколько дней

const weekdays = {
  0: { name: 'Воскресенье', shortName: 'Вс' },
  1: { name: 'Поендельник', shortName: 'Пн' },
  2: { name: 'Вторник', shortName: 'Вт' },
  3: { name: 'Среда', shortName: 'Ср' },
  4: { name: 'Четверг', shortName: 'Чт' },
  5: { name: 'Пятница', shortName: 'Пт' },
  6: { name: 'Суббота', shortName: 'Сб' }
};

const months = {
  0: 'января',
  1: 'февраля',
  2: 'марта',
  3: 'апреля',
  4: 'мая',
  5: 'июня',
  6: 'июля',
  7: 'августа',
  8: 'сентября',
  9: 'октября',
  10: 'ноября',
  11: 'декабря'
};

export const getNextDates = (date, daysCount = 6) => {
  const nextDays = [];
  for (let step = 0; step < daysCount; step++) {
    const d = date.clone().add(step, 'days');
    nextDays.push({
      id: step,
      weekdayName: weekdays[d.weekday()]['name'],
      weekdayShortName: weekdays[d.weekday()]['shortName'],
      month: months[d.month()],
      year: d.year(),
      day: d.date(),
      date: d.format('YYYYMMDD')
    });
  }
  return nextDays;
};

export const getDeliverySlots = (isPossibleToday, possibleTime) => {
  let deliverySlots = [];
  // Интервал для слотов закзов в часах. Сйчас 1 час, а не 30 минут
  const deliveryStep = 1;

  let minPossibleHour = config.minOrderHour;
  if (isPossibleToday) {
    if (minPossibleHour < possibleTime.hour()) {
      minPossibleHour = possibleTime.hour() + 1;
    }
  }

  for (
    let step = config.minOrderHour;
    step <= config.maxOrderHour;
    step += deliveryStep
  ) {
    deliverySlots.push({
      hour: step,
      available: step < minPossibleHour ? false : true
    });
  }

  return { deliverySlots, minPossibleHour };
};

const todayDate = () => {
  const today = new Date();
  return prepareDate(today);
};

const prepareDate = day => {
  const dd = String(day.getDate()).padStart(2, '0');
  const mm = String(day.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = day.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

// const formatDate = (d: moment.Moment) => {
const formatDate = d => {
  const isToday = d.isSame(new Date(), 'day');
  let dateLabel = '';
  if (isToday) {
    dateLabel = 'Сегодня';
  } else {
    const isYesterday = d.isSame(moment().subtract(1, 'day'), 'day');
    if (isYesterday) {
      dateLabel = 'Вчера';
    } else {
      const isTomorrow = d.isSame(moment().add(1, 'day'), 'day');
      if (isTomorrow) {
        dateLabel = 'Завтра';
      }
    }
  }

  return dateLabel !== ''
    ? `${dateLabel} ${moment(d).format('HH:mm')}`
    : moment(d).format('DD.MM HH:mm');
};

export { todayDate, prepareDate, formatDate };

export default {
  getNextDates
};
