import { combineReducers } from 'redux';
import entitiesReducer from './entities';
import loginReducer from './login';
import apiReducer from './api';
import wsReducer from '../store/ws';

const rootReducer = combineReducers({
  entities: entitiesReducer,
  login: loginReducer,
  api: apiReducer,
  ws: wsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

// GLOBAL ACTIONS
// export default (state, action) => {
//   if (action.type === 'RESET_APP') {
//     state = undefined;
//   }
//   return rootReducer(state, action);
// };
