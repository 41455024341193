import {
  ItemResponseModel,
  DishResponseModel
} from '../interfaces/OrderResponseModel';

// import { DishResponseModel } from '../interfaces/DishesResponseModel';
import { CategoryModel } from '../interfaces/CategoriesModel';

export const getDishLabel = (
  dish: DishResponseModel,
  showId: boolean = true
) => {
  return `${showId ? `${dish.id} .` : ''}${
    dish.categories[0].title
  } ${dish.title.replace(/<[^>]*>?/gm, '')}`;
};
