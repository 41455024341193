import moment from 'moment';

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Grid,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AddIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';
import AddButtonIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';

import config from '../../config/config.json';

import withAlert from '../../hoc/withAlert';
import {
  changeDeliveryDate,
  changeDeliveryOption,
  changeDeliveryPrice,
  changeDeliveryAddress,
  changeDeliveryComment
} from '../../store/order';
import { getDeliveryOptionsSelector } from '../../store/deliveryOptions';

import { OrderStateModel } from '../../interfaces/OrderResponseModel';
import { DeliveryOptionModel } from '../../interfaces/DeliveriesResponseModel';

import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const DeliveryCard = props => {
  const classes = useStyles();

  const { dispatchHandler, handleInputChange } = props;
  const deliveryOptionsPlaces = useSelector(getDeliveryOptionsSelector);

  const { order }: { order: OrderStateModel } = props;
  const dispatch = useDispatch();

  const [deliveryDate, setDeliveryDate] = useState(
    moment(order.delivery?.start_time)
      .startOf('day')
      .toDate()
  );
  const [deliveryTime, setDeliveryTime] = useState(
    moment(order.delivery?.start_time).toDate()
  );

  // Список дотсупных вариантов доставки
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionModel[]>(
    []
  );

  // Дефолтные состоятия доставки
  const [deliveryOptionId, setDeliveryOptionId] = useState<number | string>('');
  const [deliveryIsPickup, setDeliveryIsPickup] = useState(false);

  const [price, setPrice] = useState({
    amount: 0,
    state: 'init'
  });
  const debouncedPrice = useDebounce(price.amount, 500);

  const [address, setAddress] = useState({
    value: '',
    state: 'init'
  });
  const debouncedAddress = useDebounce(address.value, 1000);

  const [comment, setComment] = useState({
    value: '',
    state: 'init'
  });
  const debouncedComment = useDebounce(comment.value, 1000);

  // @ts-ignore
  const handleDateChange = async date => {
    try {
      await dispatchHandler(
        changeDeliveryDate,
        order.id as number,
        moment(date)
          .add(moment(deliveryTime).hours(), 'hours')
          .add(moment(deliveryTime).minutes(), 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss')
      );
      // Если ок то поменяем локальный статус
      setDeliveryDate(date);
    } catch {}
  };

  // @ts-ignore
  const handleTimeChange = async date => {
    try {
      await dispatchHandler(
        changeDeliveryDate,
        order.id as number,
        moment(deliveryDate)
          .add(moment(date).hours(), 'hours')
          .add(moment(date).minutes(), 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss')
        // date
      );
      // Если ок то поменяем локальный статус
      setDeliveryTime(date);
    } catch {}
  };

  // Order chaged
  useEffect(() => {
    setDeliveryOptionId(order.delivery?.deliveryoption.id as number);
    setDeliveryIsPickup(order.isPickup as boolean);
  }, [order]);

  // Initial state
  useEffect(() => {
    setPrice({
      amount: order.delivery?.price as number,
      state: 'init'
    });

    setAddress({
      value: (order.delivery?.meta.address
        ? order.delivery.meta.address
        : '') as string,
      state: 'init'
    });

    setComment({
      value: (order.comment ? order.comment : '') as string,
      state: 'init'
    });
  }, []);

  // Установим дефолтный список вариантов доставки
  useEffect(() => {
    if (deliveryOptionsPlaces) {
      setDeliveryOptions(
        deliveryOptionsPlaces.filter(d => d.place_id === order.place.id)
      );
    }
  }, [deliveryOptionsPlaces]);

  // Price
  const handleDeliveryPriceChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPrice = event.target.value;
    setPrice({
      amount: newPrice !== '' ? parseInt(newPrice) : 0,
      state: 'changed'
    });
  };

  useEffect(() => {
    if (price.state != 'init') {
      const sendPrice = async (debouncedPrice: number) => {
        try {
          await dispatchHandler(
            changeDeliveryPrice,
            order.id as number,
            debouncedPrice
          );
        } catch {}
      };
      sendPrice(debouncedPrice as number);
    }
  }, [debouncedPrice]);

  // Comment

  useEffect(() => {
    if (debouncedComment && comment.state !== 'init') {
      const sendComment = async (debouncedComment: string) => {
        try {
          await dispatchHandler(
            changeDeliveryComment,
            order.id as number,
            debouncedComment
          );
        } catch {}
      };
      sendComment(debouncedComment as string);
    }
  }, [debouncedComment]);

  // Address

  useEffect(() => {
    if (debouncedAddress && address.state !== 'init') {
      const sendAddress = async (debouncedAddress: string) => {
        try {
          await dispatchHandler(
            changeDeliveryAddress,
            order.id as number,
            debouncedAddress
          );
        } catch {}
      };
      sendAddress(debouncedAddress as string);
    }
  }, [debouncedAddress]);

  // Options
  const handleDeliveryOptionChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const optionId = event.target.value;
    if (optionId === '0') return;
    try {
      await dispatchHandler(
        changeDeliveryOption,
        order.id as number,
        parseInt(optionId)
      );
      setDeliveryOptionId(parseInt(optionId) as number);
    } catch {}
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>
          Доставка ({order.delivery?.price} ₽)
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              multiline
              id="price"
              label="Стоимость"
              name="price"
              type="text"
              // @ts-ignore
              onChange={handleDeliveryPriceChange}
              value={price.amount}
              autoComplete="off"
            />
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={2}>
              <Grid item xs={7}>
                <KeyboardDatePicker
                  // className={classes.datePiker}
                  margin="normal"
                  label="Дата доставки"
                  format="dd.MM.yyyy"
                  value={deliveryDate}
                  // @ts-ignore
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </Grid>
              <Grid item xs={5} style={{ paddingTop: 16 }}>
                <TimePicker
                  clearable
                  ampm={false}
                  label="Время "
                  value={deliveryTime}
                  // @ts-ignore
                  onChange={handleTimeChange}
                  // @ts-ignore
                  views={['hours', 'minutes']}
                  minutesStep={30}
                  autoOk={true}
                  // @ts-ignore
                  clearable={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="status125">Тип доставки</InputLabel>
              <Select
                labelId="status125"
                value={deliveryOptionId}
                // @ts-ignore
                onChange={handleDeliveryOptionChange}>
                {deliveryOptions.map(dv => (
                  <MenuItem value={dv.id} key={dv.id}>
                    {dv.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              multiline
              id="address"
              label="Адрес доставки"
              name="address"
              type="text"
              disabled={deliveryIsPickup}
              value={address.value}
              onChange={handleInputChange(setAddress)}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              multiline
              id="comment"
              label="Комментарий"
              name="comment"
              type="text"
              onChange={handleInputChange(setComment)}
              value={comment.value}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default withAlert(DeliveryCard);
