import * as React from 'react';

import NewIcon from '@material-ui/icons/FiberNew';
import ApprovedIcon from '@material-ui/icons/HeadsetMic';
import CookingIcon from '@material-ui/icons/Fastfood';
import DoneIcon from '@material-ui/icons/InsertEmoticon';
import DeliveringIcon from '@material-ui/icons/DirectionsRun';
import CancelledIcon from '@material-ui/icons/Block';

import ItemCookingIcon from '@material-ui/icons/Fastfood';
import ItemWaitIcon from '@material-ui/icons/AccessTime';
import ItemNotAllIcon from '@material-ui/icons/Timelapse';
import ItemDoneIcon from '@material-ui/icons/Done';

const statusIcons = {
  new: <NewIcon />,
  approved: <ApprovedIcon />,
  cooking: <CookingIcon />,
  delivering: <DeliveringIcon />,
  done: <DoneIcon />,
  cancelled: <CancelledIcon />
};

const itemStatusIcons = {
  new: <ItemWaitIcon />,
  notAll: <ItemNotAllIcon />,
  in_progress: <ItemCookingIcon />,
  done: <ItemDoneIcon />
};

const getStatusIcon = (status: string) => {
  //@ts-ignore
  return statusIcons[status];
};

const getItemStatusIcon = (status: string) => {
  //@ts-ignore
  return itemStatusIcons[status];
};

export { getStatusIcon, getItemStatusIcon };
