import moment from 'moment';

import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';

import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import config from '../../../../config/config.json';

import { useSelector, useDispatch } from 'react-redux';

import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';
import {
  DeliveryResponseModel,
  DeliveryOptionModel
} from '../../../../interfaces/DeliveriesResponseModel';

import { getDeliveryOptionsSelector } from '../../../../store/deliveryOptions';

import {
  getNextDaysForDelivery,
  getDeliverySlotsForDayAndDelivery
} from '../../../../libs/deliveryTimeRules';

import { freeDeliveryByOrderPrice } from '../../../../libs/deliveryPriceRules';

import { getDeliveriesSelector } from '../../../../store/newOrder';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

export interface DeliveryCardProps {
  orderPrice: number;
  places: number;
  orderDelivery: {
    start_time: string | null;
    deliveryOptionId: number | null;
    price: number | null;
    address: string | null;
    comment: string | null;
  };
  onDeliveryChange: ({
    start_time,
    // cutleryAmount,
    deliveryOptionId,
    deliveryIsPickup,
    price,
    address,
    comment
  }: {
    start_time: string;
    // cutleryAmount: number;
    deliveryOptionId: number;
    deliveryIsPickup: boolean;
    price: number;
    address: string;
    comment: string;
  }) => void;
}

// @ts-ignore
const DeliveryCard: React.SFC<DeliveryCardProps> = ({
  orderPrice,
  onDeliveryChange,
  orderDelivery,
  places
}) => {
  // @ts-ignore

  const classes = useStyles();

  // Ве блюда
  const deliveriesState = useSelector(getDeliveriesSelector);
  const deliveryOptionsPlaces = useSelector(getDeliveryOptionsSelector);
  

  const [deliveries, setDeliveries] = useState<string[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionModel[]>(
    []
  );
  const [deliveryId, setDeliveryId] = useState<number | string>('');

  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState(new Date());

  const initDelivery = {
    start_time: '',
    cutleryAmount: 0,
    deliveryOptionId: 0,
    deliveryIsPickup: true,
    price: 0,
    address: '',
    comment: ''
  };
  const [delivery, setDelivery] = useState(initDelivery);

  // @ts-ignore
  const handleDateChange = async date => {
    setDeliveryDate(date);

    const start_time = moment(date)
      .add(moment(deliveryTime).hours(), 'hours')
      .add(moment(deliveryTime).minutes(), 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');

    setDelivery({
      ...delivery,
      start_time
    });

    onDeliveryChange({
      ...delivery,
      start_time
    });
  };

  // @ts-ignore
  const handleTimeChange = async date => {
    setDeliveryTime(date);
    const start_time = moment(deliveryDate)
      .add(moment(date).hours(), 'hours')
      .add(moment(date).minutes(), 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');

    setDelivery({
      ...delivery,
      start_time
    });

    onDeliveryChange({
      ...delivery,
      start_time
    });
    // onDeliveryChange({ ...delivery, deliveryTime: date });
  };

  // Price
  const handleDeliveryPriceChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPrice = event.target.value;
    setDelivery({
      ...delivery,
      price: newPrice !== '' ? parseInt(newPrice) : 0
    });
    onDeliveryChange({
      ...delivery,
      price: newPrice !== '' ? parseInt(newPrice) : 0
    });
  };

  const handleDeliveryAddressChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const address = event.target.value;
    setDelivery({
      ...delivery,
      address
    });
    onDeliveryChange({
      ...delivery,
      address
    });
  };

  const handleDeliveryCommentChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const comment = event.target.value;
    setDelivery({
      ...delivery,
      comment
    });
    onDeliveryChange({
      ...delivery,
      comment
    });
  };

  // Options
  const handleDeliveryOptionChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const optionId = event.target.value;
    if (optionId === '0') return;
    setDelivery({
      ...delivery,
      deliveryOptionId: parseInt(optionId),
      deliveryIsPickup:
        // @ts-ignore
        deliveryOptions.filter(dv => dv.id === parseInt(optionId))[0]
          .deliveryType === 'pickup'
    });
    onDeliveryChange({
      ...delivery,
      deliveryOptionId: parseInt(optionId),
      deliveryIsPickup:
        // @ts-ignore
        deliveryOptions.filter(dv => dv.id === parseInt(optionId))[0]
          .deliveryType === 'pickup'
    });
  };

  // debouncedAddress

  const handleDeliveryIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newId = parseInt(event.target.value);
    setDeliveryId(newId);

    setDelivery({
      ...delivery,
      address: deliveries[newId]
    });
    onDeliveryChange({
      ...delivery,
      address: deliveries[newId]
    });
  };

  // Init state

  const initSate = () => {
    try {
      const currentDelivery = deliveryOptionsPlaces.filter(
        o => o.place_id === places
      );
      setDeliveryOptions(currentDelivery);

      const defaultDeliveryOption = currentDelivery[0]['id'];

      // Метод возвращает ближайшие доступные даты

      const deliveryDate = getNextDaysForDelivery(
        defaultDeliveryOption,
        deliveryOptionsPlaces
      ).nextDays[0].date;

      const deliveryDateFormatted = moment(
        getNextDaysForDelivery(defaultDeliveryOption, deliveryOptionsPlaces)
          .nextDays[0].date,
        'YYYYMMDD'
      ).toDate();

      // Метод возвращает слоты и ближайшее доступнок время для доставки
      const minPossibleHour = getDeliverySlotsForDayAndDelivery(
        deliveryDate,
        defaultDeliveryOption,
        deliveryOptionsPlaces
      ).minPossibleHour;

      const newDeliveryTime = moment(deliveryDateFormatted)
        .add(minPossibleHour, 'hours')
        .toDate();

      // Чтобы несколько сетов не перетирали друг друга, делаем все сразу в одном
      setDeliveryDate(deliveryDateFormatted);
      setDeliveryTime(newDeliveryTime);

      const start_time = moment(newDeliveryTime).format('YYYY-MM-DDTHH:mm:ss');
      setDelivery({
        ...initDelivery,
        start_time,
        deliveryOptionId: defaultDeliveryOption
      });
      onDeliveryChange({
        ...delivery,
        start_time
      });
    } catch {}
  };

  // Подготовим список опшинсов и изменим его при изменении плейса
  useEffect(() => {
    if (deliveryOptionsPlaces.length > 0) {
      initSate();
    }
  }, [deliveryOptionsPlaces]);

  // Изменяем прайс для способа доставки
  useEffect(() => {
    if (deliveryOptions.length === 0) return;
    // Чтобы не перетереть другой useEffect проверяем что значение не равно начальному
    if (delivery.deliveryOptionId !== 0) {
      setDelivery({
        ...delivery,
        price: freeDeliveryByOrderPrice(
          deliveryOptions,
          delivery.deliveryOptionId,
          orderPrice
        )
      });

      onDeliveryChange({
        ...delivery,
        price: freeDeliveryByOrderPrice(
          deliveryOptions,
          delivery.deliveryOptionId,
          orderPrice
        )
      });
    }
  }, [delivery.deliveryOptionId, orderPrice]);

  useEffect(() => {
    const addresses = deliveriesState
      .filter(
        delivery =>
          delivery.address !== null &&
          delivery.address !== '' &&
          delivery.address !== 'null'
      )
      .map(delivery => delivery.address);

    const uniqueAddresses = new Set<string>(addresses);

    setDeliveries(Array.from(uniqueAddresses));
  }, [deliveriesState]);

  return (
    <Fragment>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}>
          <Grid item xs={7}>
            <KeyboardDatePicker
              // className={classes.datePiker}
              margin="normal"
              id="delivery-card"
              label="Дата доставки"
              format="dd.MM.yyyy"
              value={deliveryDate}
              // @ts-ignore
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Grid>
          <Grid item xs={5} style={{ paddingTop: 16 }}>
            <TimePicker
              clearable
              ampm={false}
              label="Время "
              value={deliveryTime}
              // @ts-ignore
              onChange={handleTimeChange}
              // @ts-ignore
              views={['hours', 'minutes']}
              minutesStep={30}
              autoOk={true}
              // @ts-ignore
              clearable={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}>
          <Grid item xs={7}>
            <FormControl>
              <InputLabel id="status125">Тип доставки</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="status125"
                value={delivery.deliveryOptionId.toString()}
                // @ts-ignore
                onChange={handleDeliveryOptionChange}>
                {// @ts-ignore
                deliveryOptions.map(dv => (
                  <MenuItem value={dv.id} key={dv.id}>
                    {dv.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              style={{ width: '100%' }}
              multiline
              id="price"
              label="Стоимость"
              name="price"
              type="text"
              // @ts-ignore
              onChange={handleDeliveryPriceChange}
              value={delivery.price}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Grid>
      {!delivery.deliveryIsPickup && (
        <Fragment>
          <Grid item zeroMinWidth xs={12}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel id="courier">Старые адреса клиента</InputLabel>
              <Select
                fullWidth
                labelId="courier"
                value={deliveryId}
                // @ts-ignore
                onChange={handleDeliveryIdChange}>
                {deliveries.length > 0 &&
                  deliveries.map((address, index) => (
                    <MenuItem
                      value={index}
                      key={index}>{`${address}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              multiline
              id="address"
              label="Адрес доставки"
              name="address"
              type="text"
              disabled={delivery.deliveryIsPickup}
              value={delivery.address}
              // @ts-ignore
              onChange={handleDeliveryAddressChange}
              autoComplete="off"
            />
          </Grid>
        </Fragment>
      )}
      <Grid item>
        <TextField
          style={{ width: '100%' }}
          multiline
          id="comment"
          label="Комментарий"
          name="comment"
          type="text"
          // @ts-ignore
          onChange={handleDeliveryCommentChange}
          value={delivery.comment}
          autoComplete="off"
        />
      </Grid>
    </Fragment>
  );
};

export default DeliveryCard;
