import * as React from 'react';

import Select, { ValueType } from 'react-select';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { SelectOptionType } from '../../interfaces/ItemsSelect';

const customSelectStyle = {
  // @ts-ignore
  control: styles => ({
    ...styles,
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial'
  }),
  // @ts-ignore
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial'
  })
};

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%'
  }
}));

export interface ItemsSelectProps {
  title?: string;
  options: SelectOptionType[];
  onAdd: (option: SelectOptionType) => Promise<void>;
}

const ItemsSelect: React.SFC<ItemsSelectProps> = ({
  title = 'Выберите блюдо',
  options,
  onAdd
}) => {
  const classes = useStyles();

  const handleAdd = async (option: ValueType<SelectOptionType>) => {
    try {
      await onAdd(option as SelectOptionType);
    } catch {}
  };

  return (
    <Grid item>
      <div className={classes.select}>
        <Select
          placeholder={title}
          value={null}
          onChange={option => handleAdd(option)}
          options={options}
          styles={customSelectStyle}
        />
      </div>
    </Grid>
  );
};

export default ItemsSelect;
