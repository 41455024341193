import React, { useState, useEffect, Fragment } from 'react';

import { Grid, TextField, Chip } from '@material-ui/core';

export interface PriceChangeCardProps {
  onPriceChange?: (price: number) => void;
  price: number;
}

const PriceChangeCard: React.SFC<PriceChangeCardProps> = ({
  onPriceChange,
  price: orderPrice
}) => {
  const [price, setPrice] = useState<number>(0);

  // Setup order price
  useEffect(() => {
    setPrice(orderPrice);
  }, [orderPrice]);

  const handleOrderPriceChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPrice = parseInt(event.target.value);
    setPrice(newPrice ? newPrice : 0);
    if (onPriceChange) await onPriceChange(newPrice ? newPrice : 0);
  };

  const handlePriceDiscount = async (discount: number) => {
    const coefficient = (100 - discount) / 100;
    const newPrice = Math.round(price * coefficient);
    setPrice(newPrice ? newPrice : 0);
    if (onPriceChange) await onPriceChange(newPrice ? newPrice : 0);
  };

  return (
    <Fragment>
      <Grid item>
        <TextField
          style={{ width: '100%' }}
          multiline
          id="price"
          label="Стоимость позиций в заказе"
          name="price"
          type="text"
          // @ts-ignore
          onChange={handleOrderPriceChange}
          value={price}
          autoComplete="off"
        />
      </Grid>
      <Grid item>
        <Chip
          size="small"
          label="-10%"
          color="default"
          onClick={e => handlePriceDiscount(10)}
        />{' '}
        <Chip
          size="small"
          label="-20%"
          color="default"
          onClick={e => handlePriceDiscount(20)}
        />{' '}
        <Chip
          size="small"
          label="-30%"
          color="default"
          onClick={e => handlePriceDiscount(30)}
        />
      </Grid>
    </Fragment>
  );
};

export default PriceChangeCard;
