import React from 'react';
import _ from 'underscore';

import makeStyles from '@material-ui/styles/makeStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import {
  OrderStateModel,
  transactionStatus
} from 'interfaces/OrderResponseModel';

const useStyles = makeStyles(theme => ({
  list: {
    width: '100%'
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'flex-start'
  },
  listItemAvatar: {
    marginTop: 6,
    marginBottom: 6,
    opacity: 0.6
  },
  avatar: {
    width: 32,
    height: 32,
    marginLeft: 8
  },
  accordionDetails: {
    paddingTop: 0
  }
}));

const iconsByStatus = {
  [transactionStatus.checked]: <DoneIcon fontSize="small" />,
  [transactionStatus.paid]: <AttachMoneyIcon fontSize="small" />,
  [transactionStatus.failed]: <ErrorOutlineIcon fontSize="small" />
};

const colorByStatus = {
  [transactionStatus.checked]: '#2196f3',
  [transactionStatus.paid]: '#4caf50',
  [transactionStatus.failed]: '#f44336'
};

const labelByStatus = {
  [transactionStatus.checked]: 'Подтверждено',
  [transactionStatus.paid]: 'Оплачено',
  [transactionStatus.failed]: 'Ошибка'
};

export interface TransactionsCardProps {
  order: OrderStateModel;
}

const TransactionsCard: React.FC<TransactionsCardProps> = ({ order }) => {
  const classes = useStyles();

  const transactions = React.useMemo(() => {
    return order?.transactions || [];
  }, [order]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>
          Транзакции ({transactions.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <List className={classes.list}>
          {transactions.map(transaction => (
            <React.Fragment key={transaction.id}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    className={classes.avatar}
                    style={{
                      backgroundColor: colorByStatus[transaction.status]
                    }}>
                    {iconsByStatus[transaction.status]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      ID - {transaction.id}{' '}
                      <Typography component="span" variant="body2">
                        {transaction.receipt_id
                          ? `(Чек: ${transaction.receipt_id})`
                          : ''}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography component="span" variant="body2">
                        {labelByStatus[transaction.status]}
                      </Typography>
                      <br />
                      {transaction.meta?.Reason && (
                        <Typography
                          component="span"
                          variant="body2"
                          color="error">
                          {transaction.meta?.Reason}
                        </Typography>
                      )}
                    </>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default TransactionsCard;
