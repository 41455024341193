import React, { useState, useEffect, Fragment } from 'react';
import { Typography } from '@material-ui/core';

import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';

export interface OrderPriceProps {
  order: OrderStateModel;
}

const OrderPrice: React.SFC<OrderPriceProps> = ({ order }) => {
  return (
    <Fragment>
      {/* <Typography
        variant={
          'body1'
        }>{`${order.price} ₽, дост: ${order.delivery.price} ₽`}</Typography>
      <Typography
        variant={'body1'}
        dangerouslySetInnerHTML={{
          __html: `<b>Итого:</b> ${(order.delivery.price as number) +
            (order.price as number)} ₽ `
        }}></Typography> */}
      <Typography
        variant={'body1'}
        dangerouslySetInnerHTML={{
          __html: `${(order.delivery?.price as number) +
            (order.price as number)} ₽ `
        }}></Typography>
    </Fragment>
  );
};

export default OrderPrice;
