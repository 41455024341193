import moment from 'moment';

import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, CircularProgress } from '@material-ui/core';

import { DatesFilterTopBar, PlaceSelect } from '../../components';
import { OrderCard } from './components';

import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import { AppDispatch } from '../../App';
import { useSelector, useDispatch } from 'react-redux';
import { getPageReadySelector, initPage } from '../../store/api';
import { roleSelector, getOrderPlacesSelector } from '../../store/login';
import {
  getOrderSelector,
  ordersReset,
  getOrderStatusFilterSelector,
  loadOrders
} from '../../store/order';
import { getRefreshSelector, refreshDone } from '../../store/ws';

import {
  OrderStateModel,
  OrderStatuses
} from '../../interfaces/OrderResponseModel';
import { DateStr, toDateStr } from '../../interfaces/DateStr';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { prepareDate } from '../../libs/dateHelper';

import useBecomeVisible, {
  saveRefreshTime
} from '../../hooks/useBecomeVisible';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    paddingTop: theme.spacing(1),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const OrderList = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Selectors
  const pageReady = useSelector(getPageReadySelector('loadOrders'));
  const userRole = useSelector(roleSelector);
  const refresh = useSelector(getRefreshSelector);
  const orderType = userRole === 'manager' ? 'delivery' : 'semis';
  const statusFilter = useSelector(getOrderStatusFilterSelector);
  const ordersState = useSelector(getOrderSelector) as OrderStateModel[];
  const orderPlaces = useSelector(getOrderPlacesSelector);

  // States
  const [orders, setOrders] = React.useState<OrderStateModel[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment(new Date())
      .add(-1, 'days')
      .toDate() as MaterialUiPickersDate
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    new Date() as MaterialUiPickersDate
  );

  // Update state
  const reloadOrders = async () => {
    saveRefreshTime();
    setSelectedStartDate(selectedStartDate);
    setSelectedEndDate(selectedEndDate);

    await dispatch(
      loadOrders({
        startDate: toDateStr(prepareDate(selectedStartDate)),
        endDate: toDateStr(prepareDate(selectedEndDate)),
        type: orderType
      })
    );
  };
  useBecomeVisible(reloadOrders);

  // Handlers

  const handleOrdersReset = () => {
    setOrders([]);
  };

  // Обработчик изменения дат
  const handleDatesChanged = async (startDate: DateStr, endDate: DateStr) => {
    // setOrders([]);
    await dispatch(
      loadOrders({
        startDate: startDate,
        endDate: endDate,
        type: orderType
      })
    );
  };

  const handleStartDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedStartDate(date);
    },
    [selectedStartDate]
  );

  const handleEndDateChange = useCallback(
    (date: MaterialUiPickersDate) => {
      setSelectedEndDate(date);
    },
    [selectedEndDate]
  );

  // UseEffects

  useEffect(() => {
    const getData = async () => {
      saveRefreshTime();
      await dispatch({ type: ordersReset.type });
      await initPage(props.location.pathname, dispatch);
      await dispatch(
        loadOrders({
          startDate: toDateStr(prepareDate(selectedStartDate)),
          endDate: toDateStr(prepareDate(selectedEndDate)),
          type: orderType
        })
      );
    };
    if (orderPlaces) {
      getData();
    }
  }, [dispatch, orderPlaces]);

  // @ts-ignore
  useEffect(() => {
    // let mounted = true;

    // if (mounted) {
    setOrders(
      ordersState.filter(order => order.status !== OrderStatuses.cancelled)
    );
    // }
    // return () => (mounted = false);
  }, [ordersState]);

  useEffect(() => {
    if (statusFilter) {
      setOrders(
        ordersState.filter(
          order => order.status === (statusFilter as OrderStatuses)
        )
      );
    } else {
      setOrders(
        ordersState.filter(order => order.status !== OrderStatuses.cancelled)
      );
    }
  }, [statusFilter]);

  // При событии рефреш провеярем нужно ли делать обновление и если нужно обновляем данные
  useEffect(() => {
    const refreshData = async () => {
      await dispatch({ type: ordersReset.type });
      setOrders([]);
      await dispatch(
        loadOrders({
          startDate: prepareDate(selectedStartDate),
          endDate: prepareDate(selectedEndDate),
          type: orderType
        })
      );
      await dispatch({ type: refreshDone.type });
    };

    const refreshClear = async () => {
      await dispatch({ type: refreshDone.type });
    };
    // Если пришел пустой рефреш ничего не делаем
    if (refresh) {
      // Проверяем  сегодняшний день находится в текущем дипазоне или нет. Так как в этом компоненте фильтрации по дате создания, а не по дате заказа
      const isOrderBetweenSelected = moment().isBetween(
        moment(selectedStartDate),
        moment(selectedEndDate),
        'days',
        '[]'
      );

      if (isOrderBetweenSelected) {
        // console.log('refresh OrderList page');
        refreshData();
      } else {
        refreshClear();
      }
    }
  }, [refresh]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'order'} />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          <Grid item>
            {/* <OrderTopBar
              orderType={orderType}
              onOrdersReset={handleOrdersReset}
            /> */}

            <DatesFilterTopBar
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              onDatesChanged={handleDatesChanged}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
            />
          </Grid>
          {!pageReady && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <CircularProgress disableShrink size={56} />
            </div>
          )}

          {pageReady &&
            orders.length > 0 &&
            orders.map(order => (
              <Grid item key={order.id}>
                <OrderCard order={order} />
              </Grid>
            ))}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default OrderList;
