import * as React from 'react';

import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DishesCard } from '../../../../components';

import { itemTypes } from '../../../../interfaces/OrderRequestModel';
import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';
import { SelectItemModel } from '../../../../interfaces/ItemsSelect';

import {
  changeItemAmount,
  resetOrderPrice,
  addItem,
  deleteItem
} from '../../../../store/order';

import withAlert from '../../../../hoc/withAlert';

export interface CutleryAccordionProps {
  order: OrderStateModel;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

const CutleryAccordion: React.FunctionComponent<CutleryAccordionProps> = ({
  order,
  dispatchHandler
}) => {
  const handleAddNew = async (child: SelectItemModel) => {
    try {
      await dispatchHandler(addItem, order.id as number, child.id, 0);
    } catch {}
  };

  const onAmountMinusHandler = async (child: SelectItemModel) => {
    try {
      const itemId = order.cutleries?.filter(
        cutlery => cutlery.dish.id == child.id
      )[0].id;
      if (child.amount > 0) {
        // Обновим количество
        await dispatchHandler(
          changeItemAmount,
          order.id as number,
          itemId,
          child.amount
        );
      } else {
        // Удалим айтем
        await dispatchHandler(deleteItem, {
          orderId: order.id as number,
          itemId: itemId,
          dishId: child.id
        });
      }
    } catch {}
  };

  const handleChangeAmount = async (child: SelectItemModel) => {
    const itemId = order.cutleries?.filter(
      cutlery => cutlery.dish.id == child.id
    )[0].id;
    await dispatchHandler(
      changeItemAmount,
      order.id as number,
      itemId,
      child.amount
    );
  };

  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant={'h5'}>
            {'Приборы'} ({order.cutleries?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}>
            <DishesCard
              places={order.place.id as number}
              items={order.cutleries}
              itemType={itemTypes.cutlery}
              onAddNew={handleAddNew}
              onAmountPlus={handleChangeAmount}
              onAmountMinus={onAmountMinusHandler}
              onAmountChange={handleChangeAmount}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default withAlert(CutleryAccordion);
