import * as React from 'react';
import { useState, useCallback } from 'react';

import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';

import { makeStyles } from '@material-ui/styles';

import { SelectItemModel } from '../../interfaces/ItemsSelect';

const useStyles = makeStyles(theme => ({
  bottom: {
    paddingBottom: 32
  },
  deleteButton: {
    marginRight: 10
  }
}));

export interface ItemsIncDecListProps {
  items: SelectItemModel[];
  onSetAmount: (itemId: number, amount: number) => Promise<void>;
  onAddAmount: (itemId: number) => Promise<void>;
  onMinusAmount: (itemId: number) => Promise<void>;
}

const ItemsIncDecList: React.SFC<ItemsIncDecListProps> = ({
  items,
  onSetAmount,
  onAddAmount,
  onMinusAmount
}) => {
  const classes = useStyles();
  // Блокируем кнопки и юзэффекты пока не сделаем полностью все обонвление
  const [blockUi, setBlockUi] = useState<boolean>(false);

  const handleMinus = async (dishId: number) => {
    setBlockUi(true);
    await onMinusAmount(dishId);
    setBlockUi(false);
  };

  const handleAdd = async (dishId: number) => {
    setBlockUi(true);
    await onAddAmount(dishId);
    setBlockUi(false);
  };

  const handleAmountChange = async (dishId: number, value: string) => {
    setBlockUi(true);
    await onSetAmount(dishId, Number(value || 0));
    setBlockUi(false);
  };

  return (
    <>
      {items.length > 0 &&
        items.map(item => (
          <Grid item key={item.id}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              wrap="nowrap">
              {/* <IconButton
                aria-label="delete"
                size="small"
                className={classes.deleteButton}
                onClick={() => handleAmountChange(item.id, '0')}>
                <DeleteIcon fontSize="small" />
              </IconButton> */}
              <Grid item>
                <IconButton
                  disabled={blockUi}
                  aria-label="minus"
                  onClick={() => handleMinus(item.id)}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ fontSize: 14 }}>
                  {' '}
                  {item.amount}{' '}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="add"
                  disabled={blockUi}
                  onClick={() => handleAdd(item.id)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </Grid>

              <Grid item zeroMinWidth>
                <Typography
                  style={{
                    paddingLeft: 10
                  }}>
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default ItemsIncDecList;
