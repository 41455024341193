import React, { useState, useEffect } from 'react';
import { IconButton, Typography, Badge } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import {
  getStatusesCounterSelector,
  ordersStatusFilterChanged
} from '../../../../store/order';
import { getStatusIcon } from '../../../../libs/statusesHelper';

const OrdersTopBar = props => {
  const { pageName } = props;
  const dispatch = useDispatch();

  const statusesCounterState = useSelector(getStatusesCounterSelector);
  const topBarStatusesTemplate = [
    'new',
    'approved',
    'cooking',
    'delivering',
    'done'
  ];
  const topBarStatuses =
    pageName && pageName == 'cook'
      ? topBarStatusesTemplate.slice(1)
      : topBarStatusesTemplate;

  const getStatusesCounter = (statusesNames, statusesValues) => {
    return statusesNames.reduce((res, name) => {
      res[name] = statusesValues[name];
      return res;
    }, {});
  };

  const [statusesCounter, setStatusesCounter] = React.useState({});

  const [statusFilter, setStatusFilter] = React.useState('');
  // console.log('statusesCounter', statusesCounter);
  useEffect(() => {
    setStatusesCounter(
      getStatusesCounter(topBarStatuses, statusesCounterState)
    );
  }, [statusesCounterState]);

  const handelFilterByStatus = async status => {
    if (status === statusFilter) {
      setStatusFilter('');
      await dispatch({ type: ordersStatusFilterChanged.type, payload: null });
    } else {
      setStatusFilter(status);
      await dispatch({ type: ordersStatusFilterChanged.type, payload: status });
    }
  };

  return (
    <React.Fragment>
      {topBarStatuses.map(key => (
        <IconButton
          size="small"
          color="inherit"
          key={key}
          onClick={e => handelFilterByStatus(key)}
          style={{ color: key === statusFilter ? '#005996' : 'white' }}>
          {getStatusIcon(key)}
          <Typography
            variant="h4"
            style={{
              marginLeft: 5,
              color: key === statusFilter ? '#005996' : 'white'
            }}>
            {statusesCounter[key] ? statusesCounter[key] : 0}
          </Typography>
        </IconButton>
      ))}
    </React.Fragment>
  );
};

export default OrdersTopBar;
