import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';

import { DeliveriesResponseModel } from '../interfaces/DeliveriesResponseModel';

const initialState: DeliveriesResponseModel = {
  deliveries: []
};

const slice = createSlice({
  name: 'newOrder',
  initialState: initialState,
  reducers: {
    userDeliveriesLoaded: (newOrder, action) => {
      const { deliveries } = action.payload.data;
      newOrder.deliveries = deliveries;
    },
    userDeliveriesReset: (newOrder, action) => {
      newOrder.deliveries = [];
    }
  }
});

export const { userDeliveriesLoaded, userDeliveriesReset } = slice.actions;

export default slice.reducer;

export const loadUserDeliveries = (userId: number | null) => async (
  dispatch: AppDispatch
) => {
  if (userId === null)
    return await dispatch({ type: userDeliveriesReset.type, payload: {} });

  // Добавим курьера
  return await dispatch(
    apiCallBegan({
      url: `/custom/user/${userId}/deliveries/`,
      method: 'get',
      onSuccess: userDeliveriesLoaded.type,
      actionName: 'loadUserDeliveries'
    })
  );
  // console.log('loadUserDeliveries data',data);
};

// Selectors;
export const getDeliveriesSelector = createSelector(
  (state: RootState) => state.entities.newOrder.deliveries,
  deliveries => deliveries
);

// Helpers
