import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import {
  paymentsStatus,
  paymentsSystems,
  OrderStateModel,
  newStatusesLabels,
  OrderStatuses
} from '../../../../interfaces/OrderResponseModel';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

export interface StatusCardProps {
  orderStatus: {
    status: OrderStatuses | null;
    paymentStatus: paymentsStatus | null;
    paymentSystem: paymentsSystems | null;
  };
  onStateChange: ({
    status,
    paymentStatus,
    paymentSystem
  }: {
    status: OrderStatuses;
    paymentStatus: paymentsStatus;
    paymentSystem: paymentsSystems;
  }) => void;
}

const StatusCard: React.SFC<StatusCardProps> = ({
  onStateChange,
  orderStatus
}) => {
  const classes = useStyles();

  const initState = {
    status: OrderStatuses.approved,
    paymentStatus: paymentsStatus.not_paid,
    paymentSystem: paymentsSystems.cp
  };
  const [state, setState] = useState(initState);

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const status = event.target.value as OrderStatuses;
    setState({ ...state, status });
    onStateChange({ ...state, status });
  };

  const handlePaymentStatusChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const paymentStatus = event.target.value as paymentsStatus;
    setState({ ...state, paymentStatus });
    onStateChange({ ...state, paymentStatus });
  };

  const handlePaymentSystemChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const paymentSystem = event.target.value as paymentsSystems;
    setState({ ...state, paymentSystem });
    onStateChange({ ...state, paymentSystem });
  };

  useEffect(() => {
    // reset only
    if (
      orderStatus.status === null &&
      orderStatus.paymentStatus === null &&
      orderStatus.paymentSystem === null
    ) {
      setState(initState);
    }
  }, [orderStatus]);

  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      alignItems="center"
      spacing={3}>
      <Grid item>
        <FormControl>
          <InputLabel id="status">Статус</InputLabel>
          <Select
            labelId="status"
            value={state.status}
            // @ts-ignore
            onChange={e => handleStatusChange(e)}>
            {Object.keys(newStatusesLabels).map((key: string) => (
              <MenuItem value={key} key={key}>
                {
                  // @ts-ignore
                  newStatusesLabels[key]
                }
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel id="statusType">Способ</InputLabel>
          <Select
            labelId="statusType"
            value={state.paymentSystem}
            onChange={handlePaymentSystemChange}>
            <MenuItem value={paymentsSystems.cp}>Карта</MenuItem>
            <MenuItem value={paymentsSystems.sber}>Сбер</MenuItem>
            <MenuItem value={paymentsSystems.nal}>Нал</MenuItem>
            <MenuItem value={paymentsSystems.terminal}>Терм</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <InputLabel
            id="statusPaid"
            disabled={state.paymentSystem === paymentsSystems.cp}>
            Оплачен
          </InputLabel>
          <Select
            disabled={state.paymentSystem === paymentsSystems.cp}
            labelId="statusPaid"
            value={state.paymentStatus}
            onChange={handlePaymentStatusChange}>
            <MenuItem value={paymentsStatus.not_paid}>Нет</MenuItem>
            <MenuItem value={paymentsStatus.paid}>Да</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StatusCard;
