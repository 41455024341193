import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  TextField,
  IconButton,
  Collapse
} from '@material-ui/core';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import { PhotoUpload } from '../../../../components';

const useStyles = makeStyles(theme => ({
  photo: {
    // @ts-ignore
    paddingTop: theme.spacing(2)
  }
}));

export interface SeoProps {
  state: {
    slug: string;
    seoTitle: string;
    seoDescription: string;
    seoKeywords: string;
  };
  onChangeField: (event: React.ChangeEvent<HTMLSelectElement>) => Promise<void>;
  imagePath: string;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  onSeoPhotoUpload: (file: File) => Promise<void>;
}

const Seo: React.SFC<SeoProps> = ({
  state,
  onChangeField,
  imagePath,
  setSnack,
  onSeoPhotoUpload
}) => {
  const classes = useStyles();

  const [collapse, setCollapse] = useState(false);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <Card
      style={{
        overflow: 'visible',
        zIndex: 0,
        marginTop: 10
      }}>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          wrap="nowrap">
          <Grid item>
            <Typography variant={'h5'}>SEO</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center">
              <Grid item>
                <IconButton aria-label="minus" onClick={handleCollapse}>
                  <DownIcon />
                </IconButton>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={collapse}>
          {collapse && (
            <Grid item>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  required
                  label="ЧПУ"
                  name="slug"
                  type="text"
                  value={state.slug}
                  // @ts-ignore
                  onChange={onChangeField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Title"
                  name="seoTitle"
                  type="text"
                  value={state.seoTitle}
                  // @ts-ignore
                  onChange={onChangeField}
                  autoComplete="off"
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Description"
                  name="seoDescription"
                  type="text"
                  value={state.seoDescription}
                  // @ts-ignore
                  onChange={onChangeField}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ width: '100%' }}
                  label="Keywords"
                  name="seoKeywords"
                  type="text"
                  value={state.seoKeywords}
                  // @ts-ignore
                  onChange={onChangeField}
                  autoComplete="off"
                />
              </Grid>
              <PhotoUpload
                imagePath={imagePath}
                onUpload={onSeoPhotoUpload}
                classes={classes.photo}
                setSnack={setSnack}
              />
            </Grid>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default Seo;
