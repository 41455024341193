import React, { useState, useEffect, useCallback, useRef } from 'react';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputProps } from '@material-ui/core/Input';

export interface NumberFieldProps {
  unit?: string;
  setFocus?: boolean;
}

const NumberField: React.FC<NumberFieldProps & TextFieldProps> = ({
  value,
  disabled = false,
  unit,
  setFocus,
  onChange = () => null,
  onBlur = () => null
}) => {
  const [innerValue, setInnerValue] = useState<string>(value as string);
  const inputRef = useRef(null);

  useEffect(() => {
    if (innerValue !== value) {
      setInnerValue(innerValue);
    }
    // Dirty hack to set focus after rerender
    // https://github.com/mui-org/material-ui/issues/1594
    const timeout = setTimeout(() => {
      // @ts-ignore
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  const inputProps = React.useMemo(() => {
    const props: Partial<InputProps> = {};

    if (unit) {
      props.endAdornment = (
        <InputAdornment position="end">{unit}</InputAdornment>
      );
    }

    return props;
  }, [unit]);

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setInnerValue(ev.target.value);

      if (Number(ev.target.value) !== 0) {
        onChange(ev);
      }
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      if (Number(ev.target.value) === 0) {
        onChange(ev);
      }

      onBlur(ev);
    },
    [onBlur, onChange]
  );

  return (
    <TextField
      inputRef={inputRef}
      // style={{ maxWidth: 100 }}
      type="number"
      value={innerValue}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={inputProps}
    />
  );
};

export default NumberField;
