import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';

import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  Switch,
  TextField,
  colors,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Collapse,
  CircularProgress
} from '@material-ui/core';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import withAlert from '../../hoc/withAlert';

import { initPage } from '../../store/api';
import { addCategory, changeCategory } from '../../store/categories';
import { getCategorySelector } from '../../store/categories';
import { getMenuPlacesSelector } from '../../store/login';

import { PlaceSelect, PhotoUpload } from '../../components';
import { Seo } from './components';
import { photoUpload, PhotoUploadType } from '../../utils/photoUpload';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    paddingTop: theme.spacing(2),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  photo: {
    // @ts-ignore
    paddingTop: theme.spacing(2)
  }
}));

type TParams = { group: string; categoryId: string };
type NewCategoryPropsRoute = RouteComponentProps<TParams>;

export interface NewCategoryProps extends NewCategoryPropsRoute {
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  handleInputChange: (
    setMethod: Function
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

const NewCategory: React.SFC<NewCategoryProps> = ({
  handleInputChange,
  dispatchHandler,
  setSnack,
  match
}) => {
  const categoryId = match.params.categoryId;
  const group = match.params.group;

  const isCreate = group ? true : false;

  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  const category = useSelector(getCategorySelector(parseInt(categoryId)));
  const menuPlace = useSelector(getMenuPlacesSelector);

  const [state, setState] = useState({
    active: true,
    title: '',
    slug: '',
    groupType: 'categories',
    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    siteDescription: '',
    siteDescriptionHeader: ''
  });

  const [collapse, setCollapse] = useState(false);
  const [siteCollapse, setSiteCollapse] = useState(false);

  // handlers
  const handleChangeField = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, type, name } = event.target;
    const newValue = type === 'checkbox' ? !state.active : value;
    setState(state => {
      return { ...state, [name]: newValue };
    });
  };
  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const handleSiteCollapse = () => {
    setSiteCollapse(!siteCollapse);
  };

  const handelSeoPhotoUpload = async (file: File) => {
    await photoUpload(file, category.id, PhotoUploadType.seoCategory);
  };

  const handleAddCategory = async () => {
    if (state.title === '') {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: укажите название'
      });
      return;
    }

    if (isCreate) {
      try {
        await dispatchHandler(addCategory, {
          ...state,
          group: state.groupType,
          place: { id: menuPlace }
        });
        setTimeout(() => {
          history.push(`/categories`);
        }, 1000);
      } catch {}
    } else {
      try {
        if (state.slug === '') {
          setSnack({
            open: true,
            type: 'error',
            message: 'Ошибка: укажите ЧПУ'
          });
          return;
        }
        await dispatchHandler(changeCategory, parseInt(categoryId), {
          ...state,
          meta: {
            slug: state.slug,
            title: state.seoTitle,
            description: state.seoDescription,
            keywords: state.seoKeywords,
            siteDescription: state.siteDescription,
            siteDescriptionHeader: state.siteDescriptionHeader
          }
        });
      } catch {}
    }
  };

  // UseEffects

  // На всякий случай загрузим список категорий
  useEffect(() => {
    const getData = async () => {
      initPage('categories', dispatch);
    };
    getData();
  }, [dispatch]);

  // После загрузки категории установим текущее значение

  useEffect(() => {
    if (category) {
      setState(state => {
        return {
          ...state,
          active: category.active,
          title: category.title,
          slug: category.meta.slug ? (category.meta.slug as string) : '',
          groupType: group,
          seoTitle: category.meta.title ? (category.meta.title as string) : '',
          seoDescription: category.meta.description
            ? (category.meta.description as string)
            : '',
          seoKeywords: category.meta.keywords
            ? (category.meta.keywords as string)
            : '',
          siteDescription: category.meta.siteDescription
            ? (category.meta.siteDescription as string)
            : '',
          siteDescriptionHeader: category.meta.siteDescriptionHeader
            ? (category.meta.siteDescriptionHeader as string)
            : ''
        };
      });
    }
    if (isCreate) {
      setState(state => {
        return { ...state, groupType: group };
      });
    }
  }, [category]);

  return (
    <div className={classes.root}>
      <PlaceSelect placeType={'menu'} block={true} />

      <Card
        style={{
          backgroundColor: `${state.active ? 'white' : colors.grey[200]}`,
          overflow: 'visible',
          zIndex: 0
        }}>
        <CardContent>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}>
            <Grid item>
              <Typography variant={'h5'}>
                {isCreate ? `Добавление категории` : `Редактирование категории`}
              </Typography>{' '}
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={0}>
                <Grid item>
                  {' '}
                  <TextField
                    style={{ width: '100%' }}
                    label="Название"
                    name="title"
                    type="text"
                    value={state.title}
                    // @ts-ignore
                    onChange={handleChangeField}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item>
                  <Switch
                    checked={state.active}
                    // @ts-ignore
                    onChange={handleChangeField}
                    name="active"
                  />
                </Grid>
              </Grid>
            </Grid>
            {isCreate && group === 'categories' && (
              <Grid item>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id="courierSystem">Тип категории</InputLabel>
                  <Select
                    fullWidth
                    labelId="courierSystem"
                    value={state.groupType}
                    name="groupType"
                    // @ts-ignore
                    onChange={handleChangeField}>
                    <MenuItem value={'categories'}>Блюда</MenuItem>
                    <MenuItem value={'semis'}>Заготовки</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {!isCreate && (
              <>
                {/* Site */}
                <Card
                  style={{
                    overflow: 'visible',
                    zIndex: 0,
                    marginTop: 10
                  }}>
                  <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={0}
                      wrap="nowrap">
                      <Grid item>
                        <Typography variant={'h5'}>Сайт</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid
                          container
                          direction="row"
                          justify="flex-end"
                          alignItems="center">
                          <Grid item>
                            <IconButton
                              aria-label="minus"
                              onClick={handleSiteCollapse}>
                              <DownIcon />
                            </IconButton>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Collapse in={siteCollapse}>
                      {siteCollapse && (
                        <>
                          <Grid item>
                            <TextField
                              style={{ width: '100%', marginBottom: 10 }}
                              label="Заголовок для описания на сайте"
                              name="siteDescriptionHeader"
                              type="text"
                              value={state.siteDescriptionHeader}
                              // @ts-ignore
                              onChange={handleChangeField}
                              autoComplete="off"
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              style={{ width: '100%', marginBottom: 10 }}
                              label="Описание для сайта"
                              name="siteDescription"
                              type="text"
                              value={state.siteDescription}
                              // @ts-ignore
                              onChange={handleChangeField}
                              autoComplete="off"
                              multiline
                              rows={4}
                            />
                          </Grid>
                        </>
                      )}
                    </Collapse>
                  </CardContent>
                </Card>
                {/* Site */}
                {/* SEO */}

                <Seo
                  state={state}
                  onChangeField={handleChangeField}
                  imagePath={`/images/site/categories/s_${categoryId}.png`}
                  setSnack={setSnack}
                  onSeoPhotoUpload={handelSeoPhotoUpload}
                />
                {/* SEO */}
              </>
            )}
            <Grid item style={{ paddingTop: 32 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                // disabled={true}
                variant="contained"
                onClick={handleAddCategory}>
                {isCreate && `Добавить категорию`}
                {!isCreate && `Сохранить изменения`}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default withRouter(withAlert(NewCategory));
