import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button
} from '@material-ui/core';

import { useSelector } from 'react-redux';

import withAlert from '../../hoc/withAlert';
import usePrevious from '../../hooks/usePrevious';

import {
  CourierSystemEnum,
  OrderStatuses
} from '../../interfaces/OrderResponseModel';

import { getOwnCouriersSelector } from '../../store/roles';

import {
  changeDeliveryCourier,
  deleteDeliveryCourier,
  addCourier,
  changeCourier
} from '../../store/order';

import { sortUsers } from '../../utils/sortUsers';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const CourierCardBody = props => {
  const classes = useStyles();
  // const { order }: { order: OrderStateModel } = props;
  const { order, canEdit = true } = props;
  const { courierParamsSet: handleCourierParamsSet } = props;
  const { dispatchHandler, setSnack } = props;

  const couriers = useSelector(getOwnCouriersSelector);

  const placeCouriers = useMemo(() => {
    return sortUsers(
      couriers.filter(c =>
        c.places?.find(places => places.id === order.place.id)
      )
    );
  }, [order.place.id]);

  const [courierSystem, setCourierSystem] = React.useState(
    CourierSystemEnum.own
  );

  const [courierId, setCourierId] = React.useState<number | string>('');

  const [courierName, setCourierName] = React.useState('');
  const [courierPhone, setCourierPhone] = React.useState('');
  const [courierParamsSet, setCourierParamsSet] = React.useState(false);

  const prevOrderID = usePrevious(order.id);

  const handleCourierSystemChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const system = event.target.value;
    try {
      // Если ок то поменяем локальный статус
      setCourierSystem(system as CourierSystemEnum);
      // При переключении типа курьерской системы сбрасывам признак того что курьер выбран
      // setCourierName('')
      // setCourierPhone('')
      if (
        (system === CourierSystemEnum.own && courierId !== '') ||
        (system === CourierSystemEnum.dostavista && courierName !== '')
      ) {
        setCourierParamsSet(true);
        handleCourierParamsSet(true);
      } else {
        setCourierParamsSet(false);
        handleCourierParamsSet(false);
      }
    } catch {}
  };

  const handleCourierIdChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const courier_id = event.target.value;
    try {
      if (courierParamsSet === true && courier_id === '') {
        await dispatchHandler(deleteDeliveryCourier, order.id as number);
        setCourierId('');
        setCourierParamsSet(false);
        handleCourierParamsSet(false);
      } else {
        await dispatchHandler(
          changeDeliveryCourier,
          order.id as number,
          parseInt(courier_id)
        );
        // Если ок то поменяем локальный статус
        setCourierId(parseInt(courier_id));
        // Устанавливаем статус что курьер не выбран
        setCourierParamsSet(true);
        handleCourierParamsSet(true);
      }
    } catch {}
  };

  // @ts-ignore
  const handleNameChange = event => {
    setCourierName(event.target.value);
  };

  // @ts-ignore
  const handlePhoneChange = event => {
    setCourierPhone(event.target.value);
  };

  // @ts-ignore
  const handleSaveCourier = async event => {
    try {
      if (courierName === '' || courierPhone === '') {
        setSnack({
          open: true,
          type: 'error',
          message: 'Ошибка: укажите имя и телефон'
        });
        throw 'Ошибка: укажите имя и телефон';
      }
      if (courierParamsSet === false) {
        await dispatchHandler(
          addCourier,
          order.id as number,
          courierName,
          courierPhone,
          'dostavista',
          order.place.id
        );
      } else {
        await dispatchHandler(
          changeCourier,
          order.id as number,
          order.delivery.courier.id,
          courierName,
          courierPhone,
          'dostavista'
        );
      }
      // Установим статус что данные о курьере сохранены - это нужно чтобы менять стейт кнопки сохранить или редактировать
      setCourierParamsSet(true);
      handleCourierParamsSet(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Initial state
  useEffect(() => {
    if (order && order.delivery.courier && order.delivery.courier.id) {
      // Если есть курьер найдем его в списке всех курьеров и проверим он собственный или из службы доставки

      const courierData = order.delivery.courier;

      if (courierData && courierData.person && courierData.person.meta?.type) {
        setCourierName(courierData.person.name);
        setCourierPhone(courierData.person.meta.phone as string);
        setCourierSystem(courierData.person.meta.type as CourierSystemEnum);
      } else {
        setCourierId(order.delivery.courier.id);
        setCourierName('');
        setCourierPhone('');
        setCourierSystem(CourierSystemEnum.own);
      }
      setCourierParamsSet(true);
      handleCourierParamsSet(true);
    } else {
      // Есил нет курьера и другой заказ то обнуляем. Если тот же заказ то оставляем как есть
      // Это нужно для того чтобы при рефреше данных при возвращении в приложении не сбраслывались не сохранненные данные
      // https://trello.com/c/1ebVpsB7
      if (order.id !== prevOrderID) {
        setCourierParamsSet(false);
        setCourierId('');
        setCourierName('');
        setCourierPhone('');
        setCourierSystem(CourierSystemEnum.own);
      }
    }
  }, [order]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={0}>
      <Grid item>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="courierSystem">Курьерская служба</InputLabel>
          <Select
            disabled={!canEdit || order.status === OrderStatuses.done}
            fullWidth
            labelId="courierSystem"
            value={courierSystem}
            // @ts-ignore
            onChange={handleCourierSystemChange}>
            <MenuItem value={CourierSystemEnum.own}>Свои курьеры</MenuItem>
            <MenuItem value={CourierSystemEnum.dostavista}>Достависта</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {courierSystem === CourierSystemEnum.own && (
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="courier" disabled={!canEdit}>
              Выберите курьера
            </InputLabel>
            <Select
              disabled={!canEdit || order.status === OrderStatuses.done}
              fullWidth
              labelId="courier"
              value={courierId}
              // @ts-ignore
              onChange={handleCourierIdChange}>
              {courierParamsSet === true && (
                <MenuItem value="">
                  <em>Курьер не назначен</em>
                </MenuItem>
              )}
              {placeCouriers.length > 0 &&
                placeCouriers.map(courier => (
                  <MenuItem value={courier.id} key={courier.id}>
                    {`${courier.person.surname ? courier.person.surname : ''} ${
                      courier.person.name
                    }`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {courierSystem === CourierSystemEnum.dostavista && (
        <Fragment>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Фамилия Имя"
              name="name"
              type="text"
              // @ts-ignore
              onChange={handleNameChange}
              value={courierName}
              disabled={!canEdit}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Телефон"
              name="phone"
              type="text"
              // @ts-ignore
              onChange={handlePhoneChange}
              value={courierPhone}
              disabled={!canEdit}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!canEdit || order.status === OrderStatuses.done}
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={handleSaveCourier}>
              {courierParamsSet === false ? 'Сохранить' : 'Редактировать'}
            </Button>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default withAlert(CourierCardBody);
