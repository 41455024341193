import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';

import {
  paymentsSystems,
  OrderStateModel
} from '../../../../interfaces/OrderResponseModel';

import {
  addPayment,
  changePaymentStatus,
  changePaymentSystem
} from '../../../../store/order';

import withAlert from '../../../../hoc/withAlert';
import { paymentsStatus } from '../../../../interfaces/OrderResponseModel';

export interface OrderPriceChangeProps {
  order: OrderStateModel;
  canEdit: boolean;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

const OrderPriceChange: React.SFC<OrderPriceChangeProps> = ({
  order,
  canEdit,
  dispatchHandler
}) => {
  const dispatch = useDispatch();

  const [paymentStatus, setPaymentStatus] = useState<paymentsStatus>(
    paymentsStatus.not_paid
  );
  const [paymentSystem, setPaymentSystem] = useState<paymentsSystems>(
    paymentsSystems.nal
  );

  const handlePaymentStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const status = event.target.value as paymentsStatus;
    try {
      if (order.payments === null || order.payments.length === 0) {
        await dispatchHandler(
          addPayment,
          order.id as number,
          status,
          paymentSystem
        );
      } else {
        await dispatchHandler(
          // deletePayment,
          changePaymentStatus,
          order.id as number,
          order.payments[0].id,
          status
        );
      }
      // Если ок то поменяем локальный статус
      setPaymentStatus(status);
    } catch {}
  };

  const handlePaymentSystemChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const system = event.target.value;
    try {
      if (order.payments === null || order.payments.length === 0) {
        await dispatchHandler(
          addPayment,
          order.id as number,
          paymentStatus,
          system
        );
      } else {
        // console.log('changePaymentSystem',order.payment?.id);
        await dispatchHandler(
          changePaymentSystem,
          order.id as number,
          order.payments[0].id as number,
          system
        );
      }
      // Если ок то поменяем локальный статус
      setPaymentSystem(system as paymentsSystems);
    } catch {}
  };

  // Init
  useEffect(() => {
    setPaymentStatus(
      order.payments !== null && order.payments.length > 0
        ? (order.payments[0].status as paymentsStatus)
        : paymentsStatus.not_paid
    );
    setPaymentSystem(
      order.payments !== null && order.payments.length > 0
        ? paymentsSystems[order.payments[0].system as paymentsSystems]
        : paymentsSystems.nal
    );
  }, [dispatch, order]);

  return (
    <Fragment>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={3}>
        <Grid item>
          <FormControl>
            <InputLabel id="statusType">Способ</InputLabel>
            <Select
              disabled={!canEdit}
              labelId="statusType"
              value={paymentSystem}
              // @ts-ignore
              onChange={handlePaymentSystemChange}>
              <MenuItem value={paymentsSystems.cp}>Карта</MenuItem>
              <MenuItem value={paymentsSystems.sber}>Сбер</MenuItem>
              <MenuItem value={paymentsSystems.nal}>Нал</MenuItem>
              <MenuItem value={paymentsSystems.terminal}>Терм</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel
              id="statusPaid"
              disabled={
                !canEdit || (canEdit && paymentSystem === paymentsSystems.cp)
              }>
              Оплачен
            </InputLabel>
            <Select
              disabled={
                !canEdit || (canEdit && paymentSystem === paymentsSystems.cp)
              }
              labelId="statusPaid"
              value={paymentStatus}
              // @ts-ignore
              onChange={handlePaymentStatusChange}>
              <MenuItem value={paymentsStatus.not_paid}>Нет</MenuItem>
              <MenuItem value={paymentsStatus.paid}>Да</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withAlert(OrderPriceChange);
