import React, { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';

import { CategoryModel } from '../../../../interfaces/CategoriesModel';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore

    padding: theme.spacing(4),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore

const DishesTopBar = props => {
  const classes = useStyles();
  const { category }: { category: CategoryModel } = props;
  let history = useHistory();

  const handleBack = () => {
    history.push(`/categories`);
  };

  const handleEdit = () => {
    history.push(`/category/edit/${category.id}`);
  };
  // category
  return (
    // <Card>
    //   <CardContent>
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={2}
      wrap="nowrap">
      {category && (
        <Fragment>
          <Grid item>
            <IconButton onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item zeroMinWidth>
            <Typography variant={'h4'} noWrap>
              {category.title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Fragment>
      )}
    </Grid>
    //   </CardContent>
    // </Card>
  );
};

export default DishesTopBar;
