import React, { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  Badge,
  Popover,
  Typography,
  IconButton,
  Grid
} from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';

import { ConnectionState } from '../../../../interfaces/WsModel';

import { getWsStateSelector, notificationsWasSeen } from '../../../../store/ws';

import withAlert from '../../../../hoc/withAlert';
import usePrevious from '../../../../hooks/usePrevious';

import notificationSoundFile from '../../../../assets/sounds/notification.mp4';

const useStyles = makeStyles(theme => ({
  typography: {
    // padding: theme.spacing(2)
  },
  grid: {
    padding: theme.spacing(2)
  }
}));

const Notification = ({ setSnack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const wsState = useSelector(getWsStateSelector);
  const prevNotificationsLength = usePrevious(wsState.notifications.length);

  const notificationSound = useRef(null);

  // Если в нотификация появился новая нотификация покажем пуш
  useEffect(() => {
    if (
      wsState.notifications.length > 0 &&
      wsState.notifications.length !== prevNotificationsLength
    ) {
      notificationSound.current.play();
      notificationSound.current.muted = false;
      setSnack({
        open: true,
        type: 'info',
        message: wsState.notifications[0].message
      });
    }
  }, [wsState.notifications.length]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (wsState.notifications.length > 0) {
      // Помечаем все нотификации как просмотренные
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = async () => {
    await dispatch(notificationsWasSeen());
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton
        size="small"
        onClick={handleClick}
        color={
          wsState.connectionState === ConnectionState.connected
            ? 'inherit'
            : 'default'
        }>
        {wsState.notifications.filter(
          notification => notification.wasSeen === false
        ).length > 0 && (
          <Badge
            badgeContent={
              wsState.notifications.filter(
                notification => notification.wasSeen === false
              ).length
            }
            color="secondary">
            <NotificationsIcon />
          </Badge>
        )}
        {wsState.notifications.filter(
          notification => notification.wasSeen === false
        ).length == 0 && <NotificationsIcon />}
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Grid
          className={classes.grid}
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch">
          {wsState.notifications.slice(0, 5).map((notification, index) => (
            <Grid item key={index}>
              <Typography className={classes.typography}>
                {notification.message}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>
      <audio className="audio-element" ref={notificationSound} muted="muted">
        <source src={notificationSoundFile}></source>
      </audio>
    </React.Fragment>
  );
};

export default withAlert(Notification);
