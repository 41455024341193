import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import {
  OrderListStateModel,
  OrderStateModel,
  OrderStatuses,
  statusesCounterModel,
  paymentsStatus,
  paymentsSystems,
  ItemStatuses,
  CourierSystemEnum,
  DeliveryResponseModel,
  DeliveryResponseMetaModel,
  OrderPaymentResponseModel,
  ItemResponseModel
} from '../interfaces/OrderResponseModel';

import { OrderRequestBodyModel } from '../interfaces/OrderRequestModel';

import { todayDate, prepareDate } from '../libs/dateHelper';
import moment from 'moment';

const initialState: OrderListStateModel = {
  list: [],
  statusesCounter: {},
  statusFilter: null
};

const getStatusesCounter = (orders: OrderStateModel[]) => {
  return orders.reduce((res: statusesCounterModel, order: OrderStateModel) => {
    const status = order.status as OrderStatuses;
    if (res[status]) {
      res[status] = (res[status] as number) + 1;
    } else {
      res[status] = 1;
    }
    return res;
  }, {});
};

const getItemPrice = (items: { price: number; amount: number }[]) => {
  return items.reduce(
    (res: number, item: { price: number; amount: number }) => {
      res += item.price * item.amount;
      return res;
    },
    0
  );
};

const isCutleryCheck = (
  orders: OrderStateModel[],
  orderId: number,
  itemId: number
): boolean => {
  const index = orders.findIndex(o => o.id === orderId);
  const allItemIndex = orders[index].allItems.findIndex(i => i.id === itemId);

  return !!orders[index].allItems[allItemIndex].dish.categories.find(
    category => category.group === 'cutlery'
  );
};

const slice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    ordersLoaded: (order, action) => {
      const orders = action.payload.data.orders;
      // Посчиатем здесь стоимость айдемов в ордере чтобы потом не пересичтывать их внутри вью
      orders.map((o: OrderStateModel) => {
        // Пока бек не возвращает опшены
        // o.delivery.deliveryoption = { id: 1 };

        o.itemsPrice = o.price ? o.price : getItemPrice(o.items);
        // адрес доставки с учетом пикапа

        // o.deliveryAddress = o.delivery.meta.address;
        o.deliveryAddress = o.delivery
          ? o.delivery?.deliveryoption.type === 'pickup'
            ? o.delivery?.deliveryoption.title
            : o.delivery?.meta.address
          : '';

        // o.delivery.meta.time = o.delivery.meta.time.substr(0, 5);
        // Признак пикап нужен для того чтобы показывать либо адрес доставки либо адрес пикапа
        o.isPickup = o.delivery
          ? o.delivery?.deliveryoption.type === 'pickup'
          : false;

        o.allItems = [...o.items];
        o.cutleries = o.items.filter(item =>
          item.dish.categories.find(category => category.group === 'cutlery')
        );
        // .filter(item => item.dish.categories);
        o.items = o.items
          .filter(item => item.amount > 0)
          .filter(
            item =>
              item.dish.categories.filter(
                category => category.group === 'cutlery'
              ).length === 0
          );
      });
      // console.log('orders prepared', orders);
      // Отсортируем по дате создания
      // orders.sort((a: OrderStateModel, b: OrderStateModel) =>
      //   a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
      // );

      order.list = orders;

      // Добавим колво заказов для каждого стейта чтобы учитывать их в шапке

      order.statusesCounter = getStatusesCounter(orders);
    },
    ordersReset: () => initialState,
    ordersStatusFilterChanged: (order, action) => {
      order.statusFilter = action.payload;
    },
    orderStatusChanged: (order, action) => {
      const { status, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].status = status;
      // Изменим каунтер чтобы он обонвился в топбаре
      order.statusesCounter = getStatusesCounter(order.list);
    },
    orderPriceChanged: (order, action) => {
      const { price, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].price = price;
      order.list[index].itemsPrice = price
        ? price
        : getItemPrice(order.list[index].items);
    },

    orderAdded: (order, action) => {
      // console.log('data', action.payload.data);
    },

    deliveryDateChanged: (order, action) => {
      const { start_time, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);

      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        start_time
      };
    },

    deliveryOptionChanged: (order, action) => {
      const { optionId, orderId } = action.payload.params;
      const deliveryoption = action.payload.data.deliveryoption;

      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        deliveryoption
      };
      order.list[index].isPickup = deliveryoption.type === 'pickup';
    },
    deliveryPriceChanged: (order, action) => {
      const { price, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        price
      };
    },
    deliveryAddressChanged: (order, action) => {
      const { address, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        meta: {
          ...(order.list[index].delivery?.meta as DeliveryResponseMetaModel),
          address
        }
      };

      order.list[index].deliveryAddress = order.list[index].delivery
        ? order.list[index].delivery?.deliveryoption.type === 'pickup'
          ? (order.list[index].delivery?.deliveryoption.title as string)
          : (order.list[index].delivery?.meta.address as string)
        : '';
    },
    deliveryNameChanged: (order, action) => {
      const { name, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        meta: {
          ...(order.list[index].delivery?.meta as DeliveryResponseMetaModel),
          name
        }
      };
    },

    deliveryPhoneChanged: (order, action) => {
      const { phone, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        meta: {
          ...(order.list[index].delivery?.meta as DeliveryResponseMetaModel),
          phone
        }
      };
    },

    deliveryCourierChanged: (order, action) => {
      const { orderId } = action.payload.params;
      const { courier } = action.payload.data;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        courier: courier
      };
    },

    deliveryCourierDeleted: (order, action) => {
      const { orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        courier: null
      };
    },

    courierAdded: (order, action) => {
      const { orderId } = action.payload.params;
      const courier = action.payload.data.user;

      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].delivery = {
        ...(order.list[index].delivery as DeliveryResponseModel),
        courier: courier
      };
    },

    deliveryCommentChanged: (order, action) => {
      const { comment, orderId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].comment = comment;
    },

    itemAmountChanged: (order, action) => {
      const { itemId, orderId, amount } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      const allItemIndex = order.list[index].allItems.findIndex(
        i => i.id === itemId
      );

      // Получим признак это катлери или нет
      const isCutlery = isCutleryCheck(order.list, orderId, itemId);

      const itemKey = isCutlery ? 'cutleries' : 'items';

      // Найдем айди айтем в зависимости от его типа
      // @ts-ignore
      const itemIndex = order.list[index][itemKey].findIndex(
        i => i.id === itemId
      );

      // Если амауонт стал 0 то удаляем из списка айтеммов, чтобы больше не показывать в списке заказов
      if (amount === 0) {
        // @ts-ignore
        order.list[index][itemKey] = order.list[index][itemKey].filter(
          // @ts-ignore
          item => item !== order.list[index][itemKey][itemIndex]
        );
      } else {
        // Если айтем есть в списке то измененям амаунт
        if (itemIndex > -1) {
          // @ts-ignore
          order.list[index][itemKey][itemIndex].amount = amount;
          // Если айтема нет в списке то значит он стал 0 и его удалили из списка, но он должен быть в спике всех айтемов, если его там нет то это ошибка
        } else {
          if (!allItemIndex) throw 'Cant find item';
          const item = order.list[index].allItems[allItemIndex];
          item.amount = amount;
          // @ts-ignore
          order.list[index][itemKey].push(item);
        }
      }
      // Пересчитаем стоимость
      order.list[index].itemsPrice = getItemPrice(order.list[index].items);
    },

    itemAdded: (order, action) => {
      const { orderId } = action.payload.params;
      const newItem = action.payload.data.item as ItemResponseModel;
      const index = order.list.findIndex(o => o.id === orderId);
      //  Нужно понять в какой стейт добавить новый айтем в катлери или айтемы
      const isCutlery = !!newItem.dish.categories.find(
        category => category.group === 'cutlery'
      );
      const itemKey = isCutlery ? 'cutleries' : 'items';

      // Добавим в список либо дишей либо катлери
      // @ts-ignore
      order.list[index][itemKey].push(newItem);
      // Добавим также в список всех дишей
      order.list[index].allItems.push(newItem);
      // Пересчитаем стоимость
      order.list[index].itemsPrice = getItemPrice(order.list[index].items);
    },

    itemDeleted: (order, action) => {
      const { orderId, itemId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);

      const isCutlery = isCutleryCheck(order.list, orderId, itemId);
      const itemKey = isCutlery ? 'cutleries' : 'items';

      // @ts-ignore
      order.list[index][itemKey] = order.list[index][itemKey].filter(
        i => i.id !== itemId
      );
      // @ts-ignore
      order.list[index].allItems = order.list[index].allItems.filter(
        i => i.id !== itemId
      );

      // Пересчитаем стоимость
      order.list[index].itemsPrice = getItemPrice(order.list[index].items);
    },

    paymentAdded: (order, action) => {
      const { orderId } = action.payload.params;
      const payment = action.payload.data.payment;
      const index = order.list.findIndex(o => o.id === orderId);
      order.list[index].payments = [payment];
    },
    paymentStatusChanged: (order, action) => {
      const { orderId, paymentStatus } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      if (order.list[index].payments) {
        order.list[index].payments = [
          {
            id: (order.list[index].payments as OrderPaymentResponseModel[])[0]
              .id as number,
            status: paymentStatus,
            system: (order.list[index]
              .payments as OrderPaymentResponseModel[])[0].system as string
          }
        ];
      }
    },
    paymentSystemChanged: (order, action) => {
      const { paymentId, orderId, paymentSystem } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      if (order.list[index].payments) {
        order.list[index].payments = [
          {
            id: (order.list[index].payments as OrderPaymentResponseModel[])[0]
              .id as number,
            status: (order.list[index]
              .payments as OrderPaymentResponseModel[])[0].status as string,
            system: paymentSystem
          }
        ];
      }
    },
    itemStatusChanged: (order, action) => {
      const { itemId, orderId, status } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      const itemIndex = order.list[index].items.findIndex(i => i.id === itemId);

      order.list[index].items[itemIndex].status = status;
    },
    itemCookChanged: (order, action) => {
      const { itemId, orderId, cookId } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      const itemIndex = order.list[index].items.findIndex(i => i.id === itemId);

      order.list[index].items[itemIndex].cook = { id: parseInt(cookId) };
    },

    minusDishCookChanged: (order, action) => {
      const { orderId } = action.payload.params;
      const { id: itemId } = action.payload.data.item;
      const index = order.list.findIndex(o => o.id === orderId);
      const itemIndex = order.list[index].items.findIndex(i => i.id === itemId);

      order.list[index].items[itemIndex].cook = null;
      order.list[index].items[itemIndex].status = ItemStatuses.new;
    },

    itemDishCookChanged: (order, action) => {
      const { dishId, orderId, cookId, amount } = action.payload.params;
      const index = order.list.findIndex(o => o.id === orderId);
      const itemsIndex: number[] = [];
      order.list[index].items.filter((i, index) => {
        if (i.dish.id === dishId && !i.cook?.id) {
          itemsIndex.push(index);
          return true;
        }
      });

      if (itemsIndex.length > 0) {
        const maxStep = amount ? amount : itemsIndex.length;
        for (let step = 0; step < maxStep; step++) {
          order.list[index].items[itemsIndex[step]].cook = {
            id: parseInt(cookId)
          };
          order.list[index].items[itemsIndex[step]].status =
            ItemStatuses.in_progress;
        }
      }
    }
  }
});

export const {
  ordersLoaded,
  orderStatusChanged,
  orderPriceChanged,
  orderAdded,
  ordersReset,
  ordersStatusFilterChanged,
  deliveryDateChanged,
  // deliveryTimeChanged,
  deliveryOptionChanged,
  deliveryPriceChanged,
  deliveryAddressChanged,
  deliveryPhoneChanged,
  deliveryNameChanged,
  deliveryCommentChanged,
  deliveryCourierChanged,
  deliveryCourierDeleted,
  courierAdded,
  // courierChanged,
  itemAmountChanged,
  itemAdded,
  itemDeleted,
  paymentAdded,
  paymentStatusChanged,
  paymentSystemChanged,
  itemStatusChanged,
  // itemCookChanged,
  itemDishCookChanged,
  minusDishCookChanged
} = slice.actions;

export default slice.reducer;

// Action Creators
export const loadOrders = ({
  startDate = null,
  endDate = null,
  type = null,
  orderBy = 'orders',
  placesType = 'order'
}: {
  startDate?: string | null;
  endDate?: string | null;
  type?: string | null;
  orderBy?: string;
  placesType?: string;
}) => (dispatch: AppDispatch, getState: () => RootState) => {
  if (!startDate)
    startDate = prepareDate(
      moment(new Date())
        .add(-1, 'days')
        .toDate()
    );
  if (!endDate) endDate = todayDate();
  let places;
  if (placesType === 'order') {
    const orderPlaces = getState().login.orderPlaces;
    places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  } else if (placesType === 'menu') {
    const orderPlaces = getState().login.menuPlace;
    places = `place_id=${orderPlaces}`;
  }

  return dispatch(
    apiCallBegan({
      url: `/api/orders/?${places}&start_date=${startDate}&end_date=${endDate}&order_by=${orderBy}${
        type ? `&type=${type}` : ''
      }`,
      onSuccess: ordersLoaded.type,
      actionName: 'loadOrders'
    })
  );
};

export const changeOrderStatus = (
  orderId: number,
  status: OrderStatuses
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      // url: `/api/orders/status/${orderId}/`,
      url: `/api/orders/${orderId}/status/`,
      method: 'patch',
      onSuccess: orderStatusChanged.type,
      data: { status },
      params: { status, orderId },
      actionName: 'changeOrderStatus'
    })
  );
};

export const changeOrderPrice = (orderId: number, price: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/orders/${orderId}/`,
      method: 'put',
      onSuccess: orderPriceChanged.type,
      data: { price },
      params: { price, orderId },
      actionName: 'changeOrderPrice'
    })
  );
};

export const resetOrderPrice = (orderId: number) => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const price = getState().entities.order.list.filter(
    order => order.id === orderId
  )[0].itemsPrice;
  return dispatch(
    apiCallBegan({
      url: `/api/orders/${orderId}/`,
      method: 'put',
      onSuccess: orderPriceChanged.type,
      data: { price },
      params: { price, orderId },
      actionName: 'resetOrderPrice'
    })
  );
};

export const changeDeliveryDate = (
  orderId: number,
  start_time: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/`,
      method: 'put',
      onSuccess: deliveryDateChanged.type,
      data: { start_time },
      params: { start_time, orderId },
      actionName: 'changeDeliveryDate'
    })
  );
};

export const changeDeliveryCourier = (
  orderId: number,
  courier_id: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/courier/`,
      method: 'patch',
      onSuccess: deliveryCourierChanged.type,
      data: { courier: { id: courier_id } },
      params: { orderId },
      actionName: 'changeDeliveryCourier'
    })
  );
};

export const deleteDeliveryCourier = (orderId: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/courier/`,
      method: 'delete',
      onSuccess: deliveryCourierDeleted.type,
      params: { orderId },
      actionName: 'deleteDeliveryCourier'
    })
  );
};

export const changeCourier = (
  orderId: number,
  courier_id: number,
  name: string,
  phone: string,
  type: CourierSystemEnum
) => async (dispatch: AppDispatch) => {
  // Поменяем данные на сервере
  return await dispatch(
    apiCallBegan({
      url: `/custom/couriers/${courier_id}/`,
      method: 'put',
      onSuccess: deliveryCourierChanged.type,
      data: { name, phone, type },
      params: { orderId },

      actionName: 'changeCourier'
    })
  );
};

export const addCourier = (
  orderId: number,
  name: string,
  phone: string,
  type: CourierSystemEnum,
  placeId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/courier/`,
      method: 'post',
      onSuccess: courierAdded.type,
      data: {
        name,
        phone,
        type,
        place: { id: placeId },
        order: { id: orderId }
      },
      params: { orderId },
      actionName: 'addCourier'
    })
  );
};

export const changeDeliveryOption = (
  orderId: number,
  optionId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/deliveryoption/`,
      method: 'patch',
      onSuccess: deliveryOptionChanged.type,
      data: { option_id: optionId },
      params: { optionId, orderId },
      actionName: 'changeDeliveryOption'
    })
  );
};

export const changeDeliveryPrice = (orderId: number, price: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/price/`,
      method: 'patch',
      onSuccess: deliveryPriceChanged.type,
      data: { price },
      params: { price, orderId },
      actionName: 'changeDeliveryPrice'
    })
  );
};

export const changeDeliveryAddress = (
  orderId: number,
  address: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/address/`,
      method: 'patch',
      onSuccess: deliveryAddressChanged.type,
      data: { address },
      params: { address, orderId },
      actionName: 'changeDeliveryAddress'
    })
  );
};

export const changeDeliveryName = (orderId: number, name: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/name/`,
      method: 'patch',
      onSuccess: deliveryNameChanged.type,
      data: { name },
      params: { name, orderId },
      actionName: 'changeDeliveryName'
    })
  );
};

export const changeDeliveryPhone = (orderId: number, phone: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/delivery/deliveries/${orderId}/phone/`,
      method: 'patch',
      onSuccess: deliveryPhoneChanged.type,
      data: { phone },
      params: { phone, orderId },
      actionName: 'changeDeliveryName'
    })
  );
};

export const changeDeliveryComment = (
  orderId: number,
  comment: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/orders/${orderId}/comment/`,
      method: 'patch',
      onSuccess: deliveryCommentChanged.type,
      data: { comment },
      params: { comment, orderId },
      actionName: 'changeDeliveryComment'
    })
  );
};

// Deprecated
export const changeItemAmount = (
  orderId: number,
  itemId: number,
  amount: number
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/items/${itemId}/amount/`,
      method: 'patch',
      onSuccess: itemAmountChanged.type,
      data: {
        item_id: itemId,
        order_id: orderId,
        amount
      },
      params: { itemId, orderId, amount },
      actionName: 'addItemAmount'
    })
  );
};

export const deleteItem = ({
  orderId,
  dishId,
  itemId
}: {
  orderId: number;
  dishId: number;
  itemId: number;
}) => async (dispatch: AppDispatch, getState: () => RootState) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/items/${itemId}/`,
      method: 'delete',
      onSuccess: itemDeleted.type,
      params: { orderId, itemId },
      actionName: 'deleteItem'
    })
  );
};

export const addItem = (
  orderId: number,
  dishId: number,
  price: number,
  cook: { id: number } | null = null,
  status: string | null = null
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/items/`,
      method: 'post',
      onSuccess: itemAdded.type,
      data: { dish_id: dishId, order_id: orderId, price, cook, status },
      params: { orderId },
      actionName: 'addItem'
    })
  );
};

export const addPayment = (
  orderId: number,
  paymentStatus: paymentsStatus,
  paymentSystem: paymentsSystems
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/payments/`,
      method: 'post',
      onSuccess: paymentAdded.type,
      data: {
        system: paymentSystem,
        order: { id: orderId },
        status: paymentStatus
      },
      params: { orderId },
      actionName: 'addPayment'
    })
  );
};

export const changePaymentStatus = (
  orderId: number,
  paymentId: number,
  paymentStatus: paymentsStatus
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/payments/${paymentId}/status/`,
      method: 'patch',
      onSuccess: paymentStatusChanged.type,
      data: { status: paymentStatus },
      params: { orderId, paymentId, paymentStatus },
      actionName: 'changePaymentStatus'
    })
  );
};

export const changePaymentSystem = (
  orderId: number,
  paymentId: number,
  paymentSystem: paymentsSystems
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/payments/${paymentId}/system/`,
      method: 'patch',
      onSuccess: paymentSystemChanged.type,
      data: { system: paymentSystem },
      params: { orderId, paymentId, paymentSystem },
      actionName: 'changePaymentSystem'
    })
  );
};

export const changeItemStatus = (
  orderId: number,
  itemId: number,
  status: ItemStatuses
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/items/${itemId}/status/`,
      method: 'patch',
      onSuccess: itemStatusChanged.type,
      data: { status },
      params: { status, orderId, itemId },
      actionName: 'changeItemStatus'
    })
  );
};

// export const changeItemCook = (
//   orderId: number,
//   itemId: number,
//   cookId: number | null
// ) => async (dispatch: AppDispatch) => {
//   return dispatch(
//     apiCallBegan({
//       url: `/api/cafe/items/${itemId}/cook/`,
//       method: 'patch',
//       onSuccess: itemCookChanged.type,
//       data: { cook: { id: cookId } },
//       params: { cookId, orderId, itemId },
//       actionName: 'changeItemCook'
//     })
//   );
// };

export const setItemDishCook = (
  orderId: number,
  dishId: number,
  cookId: number,
  amount: number | null
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/orders/cook/`,
      method: 'patch',
      onSuccess: itemDishCookChanged.type,
      data: {
        cook: { id: cookId },
        order: { id: orderId },
        dish: { id: dishId },
        amount
      },
      params: { cookId, orderId, dishId, amount },
      actionName: 'setItemDishCook'
    })
  );
};

export const minusItemDishCook = (
  orderId: number,
  dishId: number,
  cookId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/orders/cook/`,
      method: 'delete',
      onSuccess: minusDishCookChanged.type,
      data: {
        cook: { id: cookId },
        order: { id: orderId },
        dish: { id: dishId }
      },
      params: { orderId, dishId },
      actionName: 'minusItemDishCook'
    })
  );
};

export const addOrder = (body: OrderRequestBodyModel) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/orders/`,
      method: 'post',
      onSuccess: orderAdded.type,
      data: body,
      actionName: 'addOrder'
    })
  );
};

export const getPaymentLink = (orderId: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/payment/${orderId}/link/`,
      actionName: 'getPaymentLink'
    })
  );
};

export const reloadDockerContainer = () => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/reload_container/`,
      method: 'post',
      actionName: 'reloadDockerContainer'
    })
  );
};

// Selectors;
export const getOrderSelector = createSelector(
  (state: RootState) => state.entities.order.list,
  order => order
);

export const getOrderStatusFilterSelector = createSelector(
  (state: RootState) => state.entities.order.statusFilter,
  statusFilter => statusFilter
);

export const getOrderItemsSelector = (orderId: number) =>
  createSelector(
    (state: RootState) => state.entities.order.list,
    order => {
      const currentOrder = order.filter(order => order.id === orderId);
      return currentOrder && currentOrder.length > 0 && currentOrder[0].items
        ? currentOrder[0].items
        : [];
    }
  );

export const getStatusesCounterSelector = createSelector(
  (state: RootState) => state.entities.order.statusesCounter,
  statusesCounter => statusesCounter
);

export const getItemsByOrderAndDishSelector = (
  orderId: number,
  dishId: number
) =>
  createSelector(
    (state: RootState) => state.entities.order.list,
    order => {
      const ordersById = order.filter(order => order.id === orderId);
      if (ordersById.length === 0) return [];
      return ordersById[0].allItems.filter(item => item.dish.id === dishId);
    }
  );

// Helpers
