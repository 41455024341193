import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';

import { useSelector } from 'react-redux';
import { roleSelector } from '../../store/login';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    paddingBottom: 80,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const Main = props => {
  const { children } = props;
  const userRole = useSelector(roleSelector);

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const showMenu =
    userRole === 'manager' || userRole === 'chef' || userRole === 'cook' || userRole === 'trainee';

  const showSidebar = showMenu;

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop && showMenu ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}>
      <Topbar openSidebar={openSidebar} onSidebarOpen={handleSidebarOpen} />
      {userRole !== 'trainee' && (
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          userRole={userRole}
          variant={isDesktop ? 'persistent' : 'temporary'}
          isDesktop={isDesktop}
        />
      )}
      <main className={classes.content}>
        {children}
        {showMenu && <Footer userRole={userRole} />}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
