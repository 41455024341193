import moment from 'moment';

import { getRandomFruit } from '../libs/emojesHelper';
import {
  OrderStateModel,
  ItemResponseModel
} from '../interfaces/OrderResponseModel';

const formatOrderText = (forCourier: boolean, order: OrderStateModel) => {
  let orderText: string = '';

  if (forCourier) {
    orderText += (('Заказ ' + order.id) as string) + '\n';
  }

  orderText += order.delivery?.meta.name + '\n';
  orderText += order.delivery?.meta.phone + '\n';

  if (
    !forCourier &&
    order.delivery?.courier &&
    order.place.meta.process.needCourier
  ) {
    orderText += 'Курьер (';
    orderText +=
      (order.delivery?.courier?.person.meta.type as string) === 'dostavista'
        ? 'Достависта'
        : 'Доброкафе';
    orderText += '):\n';
    orderText += order.delivery?.courier?.person.name + '\n';
    orderText += (order.delivery?.courier?.person.meta.phone as string) + '\n';
  }

  orderText += order.place.meta.process.needCourier
    ? 'Время доставки:'
    : 'Заказ будет готов:' + '\n';
  orderText +=
    moment(order.delivery?.start_time).format('YYYY-MM-DD HH:mm') +
    ' — ' +
    moment(order.delivery?.start_time)
      .add(1, 'hours')
      .format('HH:mm') +
    '\n';
  orderText += order.place.meta.process.needCourier ? 'Адрес:\n' : '';
  orderText += order.place.meta.process.needCourier
    ? order.deliveryAddress + '\n'
    : '';

  if (order.comment) {
    orderText += '\nКомментарий:' + '\n';
    orderText += order.comment + '\n';
  }

  // Group items by id
  const items = order.items.reduce(
    (res: ItemResponseModel[], item: ItemResponseModel) => {
      if (res.map(i => i.dish.id).indexOf(item.dish.id) === -1) {
        res.push({ ...item });
      } else {
        const index = res.findIndex(i => i.dish.id === item.dish.id);
        res[index].amount = res[index].amount + item.amount;
      }
      return res;
    },
    []
  );

  if (!forCourier) {
    orderText += '\n';
    items.forEach(item => {
      orderText += `${getRandomFruit()} ${
        item.dish.categories[0].title
      } ${item.dish.title.replace(/<[^>]*>?/gm, '')}${
        item.amount === 1 ? '' : ` (${item.amount})`
      } — ${item.price * item.amount} ₽ \n`;
    });
  }

  if (!forCourier) {
    // @ts-ignore
    if (order.cutleries?.length > 0) {
      orderText += '\n' + 'Приборы:' + '\n';

      order.cutleries?.forEach(item => {
        orderText += `${item.dish.title.replace(
          /<[^>]*>?/gm,
          ''
        )}${` (${item.amount})`} — ${item.price * item.amount} ₽ \n`;
      });
    }
  }

  if (!forCourier && order.place.meta.process.needCourier) {
    orderText += '\n🐝 Доставка: ';
    orderText += order.delivery?.price + ' ₽\n';
  }
  if (!forCourier) {
    orderText += order.place.meta.process.needCourier
      ? '💶 Сумма заказа с учетом доставки: '
      : '💶 Сумма заказа ';
    // const itemsPrice = order.items.reduce((res, item) => {
    //   res += item.price * item.amount;
    //   return res;
    // }, 0);

    orderText +=
      (order.delivery?.price as number) + (order.itemsPrice as number) + ' ₽\n';
  }

  // console.log(orderText);
  return orderText;
};
export default formatOrderText;
