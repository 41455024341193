import { RoleResponseModel } from '../interfaces/RolesResponseModel';

export const sortUsers = (cooks: RoleResponseModel[]) => {
  return cooks.slice().sort((a: RoleResponseModel, b: RoleResponseModel) => {
    const a_name = `${
      a.person.surname ? a.person.surname.toLowerCase() + ' ' : ''
    }${a.person.name.toLowerCase()}`;
    const b_name = `${
      b.person.surname ? b.person.surname.toLowerCase() + ' ' : ''
    }${b.person.name.toLowerCase()}`;

    return a_name > b_name ? 1 : b_name > a_name ? -1 : 0;
  });
};
