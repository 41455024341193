import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './reducer';
import api from './middleware/api';
import socket from './middleware/socket';

export default function() {
  return configureStore({
    reducer,
    middleware: [...getDefaultMiddleware(), api, socket]
  });
}
