import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';

import Select from 'react-select';

import DeleteIcon from '@material-ui/icons/Delete';

import AddButtonIcon from '@material-ui/icons/Add';

const customSelectStyle = {
  control: styles => ({
    ...styles,
    fontSize: 13,
    fontFamily: 'Roboto, Helvetica, Arial'
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    fontFamily: 'Roboto, Helvetica, Arial'
  })
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  }
}));

const EatingCard = props => {
  const classes = useStyles();
  const { label } = props;
  const [options, setOptions] = useState([]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
      spacing={0}>
      <Grid item>
        <Typography variant={'h6'}>{label}</Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          wrap="nowrap">
          <Grid item xs={10}>
            <div style={{ width: '100%' }}>
              <Select
                // value={selectedValue}
                // onChange={handleSelectChange}
                options={options}
                styles={customSelectStyle}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              edge="end"
              aria-label="add"
              // disabled={options.length === 0 ? true : false}
              // onClick={handleAdd}
            >
              <AddButtonIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography
              noWrap
              style={{ paddingLeft: 10 }}>{`Окрошка`}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="add">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography
              noWrap
              style={{ paddingLeft: 10 }}>{`Бургер`}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="add">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EatingCard;
