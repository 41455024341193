import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider
} from '@material-ui/core';

import { EatingCard } from '../';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  }
}));

const WeekDayCard = props => {
  const classes = useStyles();
  const { label } = props;

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>{label} (20)</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}>
          <Grid item>
            <EatingCard label={'Завтрак'} />
          </Grid>
          <Divider variant="middle" />
          <Grid item>
            <EatingCard label={'Обед'} />
          </Grid>
          <Divider variant="middle" />

          <Grid item>
            <EatingCard label={'Ужин'} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default WeekDayCard;
