import React, { useState, useEffect } from 'react';

import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { DishesCard, PriceChangeCard } from '../../../../components';

import { itemTypes } from '../../../../interfaces/OrderRequestModel';
import { OrderStateModel } from '../../../../interfaces/OrderResponseModel';
import { SelectItemModel } from '../../../../interfaces/ItemsSelect';
import {
  ItemResponseModel,
  ItemStatuses
} from '../../../../interfaces/OrderResponseModel';

import {
  changeItemAmount,
  changeOrderPrice,
  resetOrderPrice,
  addItem,
  deleteItem
} from '../../../../store/order';

import withAlert from '../../../../hoc/withAlert';

export interface ItemsAccordionProps {
  order: OrderStateModel;
  presents: boolean;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

const ItemsAccordion: React.FunctionComponent<ItemsAccordionProps> = ({
  order,
  presents,
  dispatchHandler,
  setSnack
}) => {
  // Этот список нужен для рахделения кеса подаарок и нет
  const [items, setItems] = useState<ItemResponseModel[]>([]);

  const handleAddNew = async (child: SelectItemModel) => {
    try {
      await dispatchHandler(
        addItem,
        order.id as number,
        child.id,
        presents ? 0 : child.price
      );
      await dispatchHandler(resetOrderPrice, order.id as number);
    } catch {}
  };

  const onAmountPlusHandler = async (child: SelectItemModel) => {
    try {
      await dispatchHandler(
        addItem,
        order.id as number,
        child.id,
        presents ? 0 : child.price
      );
      // И меняем цену
      await dispatchHandler(resetOrderPrice, order.id as number);
    } catch {}
  };

  const onAmountMinusHandler = async (child: SelectItemModel) => {
    try {
      const dishItems = order.items
        .filter(item => (presents ? item.price === 0 : item.price > 0))
        .filter(
          i =>
            i.dish.id === child.id &&
            i.status === ItemStatuses.new &&
            i.cook === null
        );

      if (dishItems.length === 0) {
        setSnack({
          open: true,
          type: 'error',
          message: 'Нельзя уменьшить количество. Все позиции уже в работе'
        });
        return;
      }

      // Удалим первый возможный айтем
      const itemIdDelete = dishItems[0].id;
      await dispatchHandler(deleteItem, {
        orderId: order.id as number,
        itemId: itemIdDelete,
        dishId: child.id
      });

      // И меняем цену
      await dispatchHandler(resetOrderPrice, order.id as number);
    } catch {}
  };

  const onAmountChangeHandler = async (child: SelectItemModel) => {
    const itemId = order.items
      ?.filter(item => (presents ? item.price === 0 : item.price > 0))
      .filter(item => item.dish.id == child.id)[0].id;
    await dispatchHandler(
      changeItemAmount,
      order.id as number,
      itemId,
      child.amount
    );

    // И меняем цену
    await dispatchHandler(resetOrderPrice, order.id as number);
  };

  // Price
  const handlePriceChange = async (price: number) => {
    try {
      await dispatchHandler(changeOrderPrice, order.id as number, price);
    } catch {}
  };

  // Helpers
  const sortItems = (items: ItemResponseModel[]) => {
    return items.sort((a: ItemResponseModel, b: ItemResponseModel) =>
      a.id < b.id ? 1 : b.id < a.id ? -1 : 0
    );
  };

  const groupItemsByDish = (items: ItemResponseModel[]) => {
    return sortItems(
      items
        .filter(item => (presents ? item.price === 0 : item.price > 0))
        .reduce((res: ItemResponseModel[], item: ItemResponseModel) => {
          const resItem = res.filter(r => r.dish.id === item.dish.id)[0];
          if (resItem) {
            const index = res.indexOf(resItem);
            res[index].amount += 1;
          } else {
            res.push({ ...item });
          }
          return res;
        }, [])
    );
  };

  // Первоначальная загрузка
  useEffect(() => {
    setItems(
      presents
        ? groupItemsByDish(order.items.filter(item => item.price === 0))
        : groupItemsByDish(order.items.filter(item => item.price > 0))
    );
  }, [order]);

  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant={'h5'}>
            {presents ? 'Подарки' : 'Позиции'} ({items?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}>
            <DishesCard
              places={order.place.id as number}
              items={items}
              itemType={itemTypes.items}
              onAddNew={handleAddNew}
              onAmountPlus={onAmountPlusHandler}
              onAmountMinus={onAmountMinusHandler}
              onAmountChange={onAmountChangeHandler}
            />
            {presents === false && (
              <PriceChangeCard
                onPriceChange={handlePriceChange}
                price={order.itemsPrice as number}
              />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default withAlert(ItemsAccordion);
