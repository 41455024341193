import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

// import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import { getRoles } from '../store/login';
import auth from '../libs/authService';
import { getPlaceQueryParam } from '../utils/placeHelper';
import { getUserRole } from '../utils/userHelper';

import {
  ConnectionState,
  WsModel,
  WsNotificationModel,
  WsNotificationRequestModel
} from '../interfaces/WsModel';
import { UserModel, UserRoles } from '../interfaces/LoginModel';

import config from '../config/config.json';

const initialState: WsModel = {
  connectionState: ConnectionState.disconnected,
  notifications: [],
  refresh: null
};

let WEBSOCKET: WebSocket;

const slice = createSlice({
  name: 'ws',
  initialState: initialState,
  reducers: {
    connectionStateChanged: (ws, action) => {
      const { connectionState } = action.payload.params;
      ws.connectionState = connectionState;
    },
    notificationAdded: (ws, action) => {
      const {
        time,
        message,
        user_id,
        user_name,
        message_data,
        refresh
      } = action.payload;

      ws.notifications.unshift({
        time,
        message_data,
        message,
        user_id,
        user_name,
        wasSeen: false
      });
      // Оставляем только посление 10 нотификаций, так как мы не показываем больше
      ws.notifications = ws.notifications.slice(0, 10);

      if (refresh) {
        ws.refresh = {
          time,
          message_data,
          user_id,
          user_name,
          wasSeen: false
        };
      }
    },
    notificationsWasSeen: (ws, action) => {
      ws.notifications = ws.notifications.map(notification => {
        return { ...notification, wasSeen: true };
      });
    },
    refreshDone: (ws, action) => {
      ws.refresh = null;
    }
  }
});

export const {
  connectionStateChanged,
  notificationAdded,
  notificationsWasSeen,
  refreshDone
} = slice.actions;

export default slice.reducer;

// Action Creators

export const closeWs = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  // const websocket = getState().ws.websocket;
  if (WEBSOCKET) {
    WEBSOCKET.close();
    await dispatch({
      type: connectionStateChanged.type,
      payload: {
        data: {},
        params: {
          connectionState: ConnectionState.disconnected
        }
      }
    });
    console.log('WebSocket closed');
  }
};

// For all roles
export const addOrder = (payload: WsNotificationModel) => async (
  dispatch: AppDispatch
) => {
  // console.log('addOrder payload', payload);
  return dispatch({
    type: notificationAdded.type,
    payload: {
      ...payload,
      message: `Добавлен заказ №${payload.message_data?.order_id} на ${payload.message_data?.delivery_date}, ${payload.user_name}`,
      wasSeen: false,
      refresh: true
    }
  });
};

// For manager
export const changeOrderStatus = (payload: WsNotificationModel) => async (
  dispatch: AppDispatch
) => {
  // console.log('addOrder payload', payload);
  return dispatch({
    type: notificationAdded.type,
    payload: {
      ...payload,
      message: `Изменен статус заказа №${payload.message_data?.order_id}, ${payload.user_name}`,
      wasSeen: false,
      refresh: true
    }
  });
};

export const payOrder = (payload: WsNotificationModel) => async (
  dispatch: AppDispatch
) => {
  // console.log('addOrder payload', payload);
  return dispatch({
    type: notificationAdded.type,
    payload: {
      ...payload,
      message: `Заказ №${payload.message_data?.order_id} оплачен`,
      wasSeen: false,
      refresh: true
    }
  });
};

export const changeRole = (payload: WsNotificationModel) => async (
  dispatch: AppDispatch
) => {
  // console.log('roleChanged payload', payload);
  // @ts-ignore
  await dispatch(getRoles());
  return dispatch({
    type: notificationAdded.type,
    payload: {
      ...payload,
      message: `Назначена роль повар. Новый шеф ${payload.user_name}`,
      wasSeen: false,
      refresh: false
    }
  });
};

export const dispatchWs = (message: string, refresh: boolean) => (
  payload: WsNotificationModel
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const userId = getState().login.user?.id;
  // Update only for user message was send
  if (payload.user_id === userId) {
    // @ts-ignore
    await dispatch(getRoles());
    return dispatch({
      type: notificationAdded.type,
      payload: {
        ...payload,
        message: message,
        wasSeen: false,
        refresh: refresh
      }
    });
  }
};

// For cook

// Словарь соответсвий между названиями нотификаций от бека и методами обработичками
const notification_types = {
  manager: {
    order_added: addOrder,
    order_status_changed: changeOrderStatus,
    order_paid: payOrder
  },
  chef: {
    order_added: addOrder,
    role_changed: changeRole,
    item_assigned: dispatchWs('Назначено новое блюдо', true),
    item_cancled: dispatchWs('Блюдо перенесено на другого повара', true)
  },
  cook: {
    order_added: addOrder,
    role_changed: changeRole,
    item_assigned: dispatchWs('Назначено новое блюдо', true),
    item_cancled: dispatchWs('Блюдо перенесено на другого повара', true)
  },
  trainee: {
    order_added: addOrder,
    role_changed: changeRole,
    item_assigned: dispatchWs('Назначено новое блюдо', true),
    item_cancled: dispatchWs('Блюдо перенесено на другого повара', true)
  }
};

export const wsConnect = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  var pongTimeout = 0;

  // @ts-ignore
  const waitPong = ws => {
    // console.log('waitPong', pongTimeout);
    clearTimeout(pongTimeout);
    pongTimeout = window.setTimeout(() => {
      // console.log('Pong timer');
      console.log('close connection no pong');
      ws.close();
    }, config.ws.pingDelay * 1.5);
  };

  var pingTimeout = 0;
  // @ts-ignore
  const sendPing = ws => {
    // console.log('sendPing', pingTimeout);
    // clearTimeout(pingTimeout);
    try {
      ws.send('ping');
    } catch {
      console.error('Cant send ping');
    }

    pingTimeout = window.setTimeout(() => {
      // console.log('Ping timer');
      sendPing(ws);
    }, config.ws.pingDelay);
  };

  var reconnectTimeout = 0;
  // @ts-ignore
  const reconnect = async () => {
    if (getState().ws.connectionState !== ConnectionState.connected) {
      await dispatch({
        type: connectionStateChanged.type,
        payload: {
          data: {},
          params: { connectionState: ConnectionState.wait }
        }
      });
      reconnectTimeout = window.setTimeout(async () => {
        console.log('reconnect try');
        // @ts-ignore
        await dispatch(wsConnect());
        // console.log('Ping timer');
        // sendPing(ws);
      }, config.ws.reconnectDelay);
    }
  };

  // @ts-ignore
  const userId = getState().login.user.id;
  const userRoles = getState().login.user?.roles;

  if (userId) {
    // console.log('WS_CONNECT');

    const userRole = getUserRole(getState().login.user as UserModel);
    const placeQueryParam = getPlaceQueryParam(
      getState().login,
      userRole as UserRoles
    );

    // @ts-ignore
    const apiUrl = new URL(process.env.REACT_APP_API_URL);
    let wsHostname = apiUrl.hostname;
    let wsProtocol = 'wss';
    if (process.env.NODE_ENV === 'development') {
      wsProtocol = 'ws';
      wsHostname = 'localhost:8000';
    }
    const connectionUrl =
      // @ts-ignore
      `${wsProtocol}://${wsHostname}/ws/?token=${auth.getJwt()}&${placeQueryParam}`;
    // console.log('connectionUrl', connectionUrl);
    // const ws = new WebSocket(connectionUrl);
    try {
      WEBSOCKET = new WebSocket(connectionUrl);
    } catch {
      console.error("WebSocket can't connect");
    }

    // Connection opened
    WEBSOCKET.addEventListener('open', async function(event) {
      await dispatch({
        type: connectionStateChanged.type,
        payload: {
          data: {},
          params: { connectionState: ConnectionState.connected }
        }
      });
      // Запускаем пинг
      sendPing(WEBSOCKET);
      // Ждем понг
      waitPong(WEBSOCKET);
    });

    // Connection Closed
    WEBSOCKET.addEventListener('close', async function(event) {
      // console.log('Closed event', event.code);

      await dispatch({
        type: connectionStateChanged.type,
        payload: {
          data: {},
          params: {
            connectionState: ConnectionState.disconnected
          }
        }
      });

      // Останавливаем таймауты
      clearTimeout(pongTimeout);
      clearTimeout(pingTimeout);
      // If JWT token expired
      if (event.code === 1008) {
        console.log('Closed refresh');

        try {
          await auth.refreshTokens();
          // @ts-ignore
          await dispatch(wsConnect());
        } catch (ex) {
          console.log('ex', ex);
          // Разлогон
          auth.logout();
        }
      } else {
        reconnect();
      }
    });

    // Connection error
    WEBSOCKET.addEventListener('error', function(event) {
      console.log('Error');
    });

    // Listen for messages
    WEBSOCKET.addEventListener('message', function(event) {
      const payload = JSON.parse(event.data) as WsNotificationRequestModel;
      // console.log('userRoles message', payload, userRoles);
      if ('type' in payload) {
        if (payload.type === 'pong') {
          // console.log('received pong');
          waitPong(WEBSOCKET);
        } else {
          // console.log('Message from server ', event.data);
          // Проверим что нотифкаиця пришла роли которая есть у пользователя
          if ((userRoles as string[]).indexOf(payload.user_type) > -1) {
            // Проверим есть ли тип события в справочнике обработчкиов собыйти
            if (payload.type in notification_types[payload.user_type]) {
              // @ts-ignore
              // Если есть то вызовем метод обработчик события
              dispatch(
                // @ts-ignore
                notification_types[payload.user_type][payload.type](
                  payload.data
                )
              );
            }
          }
        }
      }
    });
  } else {
    console.log('Not login');
  }

  return;
};

// Selectors;
export const getConnectionStateSelector = createSelector(
  (state: RootState) => state.ws.connectionState,
  connectionState => connectionState
);

export const getRefreshSelector = createSelector(
  (state: RootState) => state.ws.refresh,
  refresh => refresh
);

export const getWsStateSelector = createSelector(
  (state: RootState) => state.ws,
  ws => ws
);

// Helpers
