import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton
} from '@material-ui/core';

import CopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import PhoneIcon from '@material-ui/icons/Phone';
// import InstagramIcon from '@material-ui/icons/Instagram';

import { copyTextToClipboard } from '../../../../libs/copyHelper';
import formatOrderText from '../../../../utils/formatOrderText';
// import { getCPPaymentLink } from '../../libs/cpHelper';

import {
  ClientCard,
  DeliveryCard,
  StatusCard,
  OrderSummary,
  CourierCard
} from '../../../../components';
import TransactionsCard from '../../../../components/TransactionsCard';

import { CutleryAccordion, ItemsAccordion } from '../';

import { getDishesSelector } from '../../../../store/dishes';
import { getPaymentLink } from '../../../../store/order';

import {
  OrderProps,
  paymentsStatus,
  paymentsSystems
} from '../../../../interfaces/OrderResponseModel';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore

    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const OrderCard: React.SFC<OrderProps> = props => {
  const classes = useStyles();
  const { order } = props;

  const dispatch = useDispatch();

  const isTextForCourier = false;

  const [opened, setOpened] = useState(false);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleCopy = async () => {
    const orderText = formatOrderText(isTextForCourier, order);

    copyTextToClipboard(orderText);
  };

  const handleGetPaymentLink = async () => {
    const result = await dispatch(getPaymentLink(order.id));
    // @ts-ignore
    const payment_link: string = result.payment_link;
    // console.log('before');
    copyTextToClipboard(payment_link);
    window.prompt('Copy to clipboard: ', payment_link);
    console.log(payment_link);
    // console.log('after');
  };

  return (
    <Accordion onChange={handleOpen}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <OrderSummary order={order} />
      </AccordionSummary>
      {opened && (
        <AccordionDetails>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            spacing={2}>
            {order.type !== 'semis' && (
              <>
                <Grid item>
                  <StatusCard order={order} />
                </Grid>
                <Grid item>
                  <DeliveryCard order={order} />
                </Grid>
                <Grid item>
                  <CutleryAccordion order={order} />
                </Grid>

                <Grid item>
                  <ItemsAccordion order={order} presents={false} />
                </Grid>
                <Grid item>
                  <ItemsAccordion order={order} presents={true} />
                </Grid>
              </>
            )}
            {!order.isPickup && (
              <Grid item>
                <CourierCard order={order} />
              </Grid>
            )}
            {order.type !== 'semis' && (
              <>
                <Grid item>
                  <ClientCard order={order} />
                </Grid>
                <Grid item>
                  <TransactionsCard order={order} />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={e => {
                      handleCopy();
                    }}>
                    <CopyIcon fontSize="small" />
                  </IconButton>{' '}
                  <IconButton
                    disabled={
                      !(
                        !!order.payments &&
                        order.payments.length > 0 &&
                        order.payments[0].system === paymentsSystems.cp &&
                        order.payments[0].status === paymentsStatus.not_paid
                      )
                    }
                    onClick={e => {
                      handleGetPaymentLink();
                    }}>
                    <PaymentIcon fontSize="small" />
                  </IconButton>{' '}
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

// @ts-ignore
function renderRool(prevProps, nextProps) {
  if (nextProps.order === prevProps.order) return true;
}

// @ts-ignore
export default React.memo(OrderCard, renderRool);
