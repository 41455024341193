import { useState, useEffect } from 'react';
import config from '../config/config.json';

const useBecomeVisible = (callBack: () => void) => {
  //   const [isVisible, setIsVisible] = useState<boolean | null>();
  const handleVisibilityChange = async () => {
    if (!shouldRefresh()) {
      return;
    }

    let { hidden } = getVisibilityAPI();

    // @ts-ignore
    if (document[hidden]) {
    } else {
      await callBack();
    }
  };

  const getVisibilityAPI = () => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
      // @ts-ignore
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    return { hidden, visibilityChange };
  };

  //   useEffect(() => {
  //     console.log('useEffect visible', isVisible);
  //   }, [isVisible]);

  useEffect(() => {
    let { hidden, visibilityChange } = getVisibilityAPI();
    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (
      typeof document.addEventListener === 'undefined' ||
      hidden === undefined
    ) {
      console.log('Not supports the Page Visibility API.');
    } else {
      // Handle page visibility change
      document.addEventListener(
        // @ts-ignore
        visibilityChange,
        handleVisibilityChange,
        false
      );
    }

    return () => {
      // console.log('unmount');
      // @ts-ignore
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);
};

const shouldRefresh = () => {
  const path = document.location.pathname;
  const refreshTime = JSON.parse(
    sessionStorage.getItem('refreshTime') as string
  );
  if (!refreshTime) return true;

  if (refreshTime[path]) {
    const lastDate = new Date(refreshTime[path]);
    return addMinutes(new Date(), config.refreshMinutes) < lastDate;
  }
  return true;
};

const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const saveRefreshTime = () => {
  const path = document.location.pathname;
  let refreshTime = JSON.parse(sessionStorage.getItem('refreshTime') as string);
  if (!refreshTime) {
    refreshTime = {};
  }
  refreshTime[path] = new Date();
  sessionStorage.setItem('refreshTime', JSON.stringify(refreshTime));
};

export default useBecomeVisible;
