import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TextField,
  Button,
  colors,
  Card,
  CardHeader,
  CardMedia,
  IconButton,
  CardActions,
  Typography,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { PhotoUpload } from '../../../../../../components';
import {
  photoUpload,
  PhotoUploadType
} from '../../../../../../utils/photoUpload';

import {
  getDishMeta,
  changeDishMeta,
  deleteDishSitePhoto
} from '../../../../../../store/dishes';

import {
  DishMetaType,
  SiteMetaModel
} from '../../../../../../interfaces/DishesResponseModel';

const useStyles = makeStyles(theme => ({
  photo: {
    // @ts-ignore
    paddingTop: theme.spacing(2)
  },
  media: {
    height: 100,
    width: 200
  },
  delete: { marginLeft: 'auto' },
  action: { padding: 0 }
}));

export interface SiteProps {
  dishId: number;
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
}

const MAX_PHOTO_SIZE_KB = 250;
const ACCEPTED_PHOTO_FORMATS = 'image/x-png,image/png';

const Site: React.FunctionComponent<SiteProps> = ({ dishId, setSnack }) => {
  const [state, setState] = useState({
    description: '',
    photos: []
  });

  const [showPhotoLoading, setShowPhotoLoading] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const validatePhoto = (
    file: File,
    setSnack: React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        type: string;
        message: string;
      }>
    >
  ) => {
    // @ts-ignore

    if (file.size > 1024 * MAX_PHOTO_SIZE_KB) {
      setSnack({
        open: true,
        type: 'error',
        message: `Ошибка: размер картинки должен быть меньше ${MAX_PHOTO_SIZE_KB} килобайт`
      });
      throw 'File not valid';
    }

    if (
      ACCEPTED_PHOTO_FORMATS.toLowerCase().indexOf(file.type.toLowerCase()) ===
      -1
    ) {
      setSnack({
        open: true,
        type: 'error',
        message: `Ошибка: картинка должна быть в формате ${ACCEPTED_PHOTO_FORMATS}`
      });
      throw 'File not valid';
    }
  };

  const handleChangeField = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, type, name } = event.target;
    // @ts-ignore
    const newValue = type === 'checkbox' ? !state[name] : value;
    setState(state => {
      return { ...state, [name]: newValue };
    });
  };
  const handelPhotoUpload = async (file: File) => {
    await photoUpload(file, dishId, PhotoUploadType.seoDish);
  };

  const handelSave = async (state: SiteMetaModel) => {
    try {
      const result = await dispatch(
        changeDishMeta(dishId as number, state, DishMetaType.site)
      );
      // @ts-ignore
      if (result.payload.error) throw result.payload.data.error;
      setSnack({
        open: true,
        type: 'success',
        message: 'Изменения сохранены'
      });
    } catch (e) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: изменения не сохранены'
      });
      throw e;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file = e.target.files[0];
    try {
      validatePhoto(file, setSnack);
    } catch {
      return;
    }

    let newPhotoId = '';
    setShowPhotoLoading(true);
    try {
      const newPhoto = await photoUpload(
        file,
        dishId,
        PhotoUploadType.siteDish
      );
      // @ts-ignore
      newPhotoId = newPhoto.data.image.id;
    } catch {
      setShowPhotoLoading(false);
    }
    setShowPhotoLoading(false);
    // @ts-ignore
    setState(state => ({
      ...state,
      photos: [...state.photos, newPhotoId]
    }));
  };

  const handleDeletePhoto = async (id: string) => {
    try {
      const result = await dispatch(deleteDishSitePhoto(dishId, id));
      // @ts-ignore
      if (result.payload.error) throw result.payload.data.error;

      const newPhotos = state.photos.filter(photo => photo !== id);
      setState(state => ({
        ...state,
        photos: newPhotos
      }));

      setSnack({
        open: true,
        type: 'success',
        message: 'Изменения сохранены'
      });
    } catch (e) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Ошибка: изменения не сохранены'
      });
      throw e;
    }
  };

  const handelDrugEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Reorder
    const oldPhotos = Array.from(state.photos);
    const newPhotos = Array.from(state.photos);
    const [removed] = newPhotos.splice(result.source.index, 1);
    newPhotos.splice(result.destination.index, 0, removed);

    const newState = {
      ...state,
      photos: newPhotos
    };
    setState(newState);

    try {
      await handelSave(newState);
    } catch {
      // If problem revert order
      setState(state => ({
        ...state,
        photos: oldPhotos
      }));
    }
  };

  const init = async (dishId: number) => {
    const data = await dispatch(getDishMeta(dishId));
    // @ts-ignore
    setState(state => ({ ...state, ...data.meta.site }));
  };

  useEffect(() => {
    init(dishId);
  }, []);

  return (
    <DragDropContext onDragEnd={handelDrugEnd}>
      <Grid item>
        <TextField
          style={{ width: '100%' }}
          label="Описание"
          name="description"
          type="text"
          value={state.description}
          // @ts-ignore
          onChange={handleChangeField}
          autoComplete="off"
          multiline
          rows={4}
        />
      </Grid>
      <Grid item style={{ paddingTop: 32, marginBottom: 20 }}>
        <Button
          color="primary"
          // fullWidth
          // size="large"
          // disabled={true}
          variant="contained"
          onClick={() => {
            handelSave(state);
          }}>
          Сохранить
        </Button>
      </Grid>

      <Grid item>
        <Typography variant={'h5'}>Фотографии блюда</Typography>
      </Grid>
      <Grid item>
        {/* Photos list */}
        {/* Photos list */}
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Grid
              ref={provided.innerRef}
              // @ts-ignore
              {...provided.draggableProps}
              // @ts-ignore
              {...provided.dragHandleProps}
              container
              spacing={1}
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                backgroundColor: colors.grey[200],
                width: 220,
                marginTop: 10,
                marginBottom: 10,
                borderRadius: 4,
                paddingTop: 10
              }}>
              {state.photos.length === 0 && (
                <Grid item>
                  <Card>
                    <CardMedia
                      className={classes.media}
                      image={`/images/no_image.png`}
                    />
                  </Card>
                </Grid>
              )}
              {state.photos.length > 0 &&
                state.photos.map((photo, index) => (
                  <Draggable key={photo} draggableId={photo} index={index}>
                    {(provided, snapshot) => (
                      <Grid
                        item
                        ref={provided.innerRef}
                        // @ts-ignore
                        {...provided.draggableProps}
                        // @ts-ignore
                        {...provided.dragHandleProps}>
                        <Card>
                          <CardMedia
                            className={classes.media}
                            image={`${process.env.REACT_APP_FRONT_URL}/images/site/dishes/${photo}.png`}
                          />
                          <CardActions className={classes.action}>
                            <IconButton
                              className={classes.delete}
                              onClick={() => handleDeletePhoto(photo)}>
                              <DeleteIcon />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Grid>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                      style={{
                        paddingRight: 10,
                        marginTop: 10,
                        marginBottom: 10
                      }}>
                      Выберите файл
                      <input
                        type="file"
                        accept={ACCEPTED_PHOTO_FORMATS}
                        id="inputFileId"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    {showPhotoLoading && (
                      <CircularProgress disableShrink size={30} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Droppable>
        {/* Photos list */}
      </Grid>
    </DragDropContext>
  );
};

export default Site;
