import * as React from 'react';
import { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';

import { ItemResponseModel, ItemStatuses } from 'interfaces/OrderResponseModel';
import { addItem, changeItemAmount, deleteItem } from 'store/order';
import withAlert from 'hoc/withAlert';

import NumberField from 'components/NumberField';

export interface SemisChangeCardProps {
  item: ItemResponseModel & { unit?: string };
  label: string;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
}

const SemisChangeCard: React.SFC<SemisChangeCardProps> = ({
  item,
  label,
  dispatchHandler
}) => {
  // State
  const [blockUI, setBlockUI] = useState<boolean>(false);

  const handleAddAmount = async () => {
    setBlockUI(true);
    try {
      await dispatchHandler(
        addItem,
        item.orderId as number,
        item.dish.id,
        0,
        { id: item.cook?.id },
        ItemStatuses.in_progress
      );
      setBlockUI(false);
    } catch {
      setBlockUI(false);
    }
  };

  const handleMinusAmount = async () => {
    setBlockUI(true);

    try {
      if (item.dishItems && item.dishItems?.length > 0) {
        await dispatchHandler(deleteItem, {
          orderId: item.orderId as number,
          //   itemId: (item.dishItems as number[])[0],
          itemId: (item.dishItems as number[]).slice(-1)[0],
          dishId: item.dish.id
        });
        setBlockUI(false);
      }
    } catch {
      setBlockUI(false);
    }
  };

  const handleAmountChange = async (value: string) => {
    setBlockUI(true);
    try {
      if (item.dishItems && item.dishItems?.length > 0) {
        if (parseInt(value) <= 0) {
          await dispatchHandler(deleteItem, {
            orderId: item.orderId as number,
            itemId: (item.dishItems as number[]).slice(-1)[0],
            dishId: item.dish.id
          });
        } else {
          await dispatchHandler(
            changeItemAmount,
            item.orderId,
            (item.dishItems as number[]).slice(-1)[0],
            value
          );
        }
        setBlockUI(false);
      }
    } catch {
      setBlockUI(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      wrap="nowrap">
      <NumberField
        disabled={blockUI}
        value={item.amount}
        // @ts-ignore
        unit={item.dish.dishes_unit ? item.dish.dishes_unit.title : ''}
        onChange={ev => handleAmountChange(ev.target.value)}
      />
      <Grid item zeroMinWidth>
        <Typography
          style={{
            paddingLeft: 10
          }}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withAlert(SemisChangeCard);
