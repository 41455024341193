import React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, IconButton, Hidden } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';

import auth from '../../../../libs/authService';

import { roleSelector } from '../../../../store/login';
import { closeWs } from '../../../../store/ws';

import {
  OrdersTopBar,
  CategoriesTopBar,
  DishesTopBar,
  Notification,
  Chef,
  SemisTopBar
} from '../';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, openSidebar, ...rest } = props;
  const dispatch = useDispatch();

  const classes = useStyles();
  let history = useHistory();
  let params = useParams();

  const userRole = useSelector(roleSelector);

  const pathname = history.location.pathname;
  const pageName = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  const handelLogout = async () => {
    await auth.logout();

    await dispatch(closeWs());

    // eslint-disable-next-line no-restricted-globals
    location.reload();
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        {userRole !== 'trainee' && (
          <Hidden lgUp>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onSidebarOpen}
              edge="start"
              className={clsx(classes.menuButton, openSidebar && classes.hide)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}

        <div className={classes.flexGrow}>
          {pageName === 'orders' && <OrdersTopBar pageName={pageName} />}
          {pageName === 'my_orders' && <SemisTopBar pageName={pageName} />}
          {pageName === 'categories' && (
            <CategoriesTopBar userRole={userRole} />
          )}
          {pageName.startsWith('category') &&
            !pageName.startsWith('category/new') &&
            !pageName.startsWith('category/edit') && (
              <DishesTopBar userRole={userRole} />
            )}
          {pageName === 'cook' && <OrdersTopBar pageName={pageName} />}
        </div>
        {userRole !== 'manager' && <Chef userRole={userRole} />}
        <Notification />
        <IconButton color="inherit" size="small" onClick={handelLogout}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
