import * as React from 'react';
import { useState, useCallback } from 'react';

import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';

import { makeStyles } from '@material-ui/styles';

import { SelectItemModel } from '../../interfaces/ItemsSelect';

import NumberField from '../NumberField';

const useStyles = makeStyles(theme => ({
  bottom: {
    paddingBottom: 32
  },
  deleteButton: {
    marginRight: 10
  }
}));

export interface ItemsChangeListProps {
  items: SelectItemModel[];
  onSetAmount: (itemId: number, amount: number) => Promise<void>;
}

const ItemsChangeList: React.SFC<ItemsChangeListProps> = ({
  items,
  onSetAmount
}) => {
  const classes = useStyles();
  // Блокируем кнопки и юзэффекты пока не сделаем полностью все обонвление
  const [blockUi, setBlockUi] = useState<boolean>(false);

  const handleAmountChange = async (dishId: number, value: string) => {
    setBlockUi(true);
    await onSetAmount(dishId, Number(value || 0));
    setBlockUi(false);
  };

  return (
    <>
      {items.length > 0 &&
        items.map(item => (
          <Grid item key={item.id}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              wrap="nowrap">
              <Grid item>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className={classes.deleteButton}
                  onClick={() => handleAmountChange(item.id, '0')}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item style={{ minWidth: 80 }}>
                <NumberField
                  disabled={blockUi}
                  value={item.amount}
                  unit={item?.unitLabel}
                  setFocus={true}
                  onChange={ev => handleAmountChange(item.id, ev.target.value)}
                />
              </Grid>
              <Grid item zeroMinWidth>
                <Typography
                  style={{
                    paddingLeft: 10
                  }}>
                  {item.title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default ItemsChangeList;
