import jwtDecode from 'jwt-decode';
import http from './httpService';
// import { apiUrl } from '../configs/config.json';

// В качестве префикса используется REACT_APP_API_URL из .env.<env>
// const apiEndpoint = '/auth/';
const apiEndpoint = '/auth/';

export async function register(email) {
  // const { data } = await http.post(`${apiEndpoint}?user=${email}&type=email`);
  const { data } = await http.post(`${apiEndpoint}email/?alias=${email}`);
  sessionStorage.setItem('position', data.position);
}

export async function login(code) {
  // const { data } = await http.get(`${apiEndpoint}?code=${code}`);
  const { data } = await http.post(`${apiEndpoint}?code=${code}`);
  const { access_token, refresh_token } = data;
  // console.log('login', access_token);
  if (access_token) {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    http.setJwt(access_token);
  }
}

export async function refreshTokens() {
  http.setJwt(getRefreshToken());
  const { data } = await http.post(`${apiEndpoint}refresh/`);
  const { access_token, refresh_token } = data;
  // console.log('refreshTokens', access_token, refresh_token);
  setAccessToken(access_token);
  setRefreshToken(refresh_token);
}

export async function loginByToken(token) {
  const { data } = await http.post(`${apiEndpoint}?token=${token}`);
  const { access_token, refresh_token } = data;
  if (access_token) {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    http.setJwt(access_token);
  }
}

export function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  window.location = '/login';
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem('access_token');
    const identity = jwtDecode(jwt);
    // console.log('identity', identity);

    return identity.actor;
  } catch (ex) {
    return false;
  }
  return false;
}

export function getJwt() {
  return localStorage.getItem('access_token');
}
export function setJwt() {
  const access_token = localStorage.getItem('access_token');
  if (access_token) {
    http.setJwt(access_token);
  }
}

export function setAccessToken(token) {
  return localStorage.setItem('access_token', token);
}

export function setRefreshToken(token) {
  return localStorage.setItem('refresh_token', token);
}

export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export default {
  register,
  login,
  loginByToken,
  logout,
  getCurrentUser,
  getJwt,
  setJwt,
  refreshTokens,
  setAccessToken,
  setRefreshToken,
  getAccessToken,
  getRefreshToken
};
