import config from '../config/config.json';
import moment from 'moment';
import { getNextDates, getDeliverySlots } from './dateHelper';

// Метод который возвращает доступный список дат для способа доставки
export const getNextDaysForDelivery = (deliveryOption, deliveryOptions) => {
  const now = moment();
  const latestDeliveryTimeForToday = now
    .clone()
    .startOf('day')
    .add(config.maxOrderHour, 'hours');

  const possibleDeliveryTime = getPossibleDeliveryTime(deliveryOption, deliveryOptions, now);

  const isDeliveryTodayPossible = !latestDeliveryTimeForToday
    .clone()
    .isBefore(possibleDeliveryTime);

  return {
    nextDays: isDeliveryTodayPossible
      ? getNextDates(now)
      : getNextDates(now.clone().add(1, 'days'))
  };
};
// Метод который возвращает список слотов и минимальный доступный слот для даты и типа доставки
export const getDeliverySlotsForDayAndDelivery = (date, deliveryOption, deliveryOptions) => {
  const isToday = date === moment().format('YYYYMMDD');

  const possibleDeliveryTime = getPossibleDeliveryTime(deliveryOption, deliveryOptions);
  return getDeliverySlots(isToday, possibleDeliveryTime);
};

// Вспомогательный метод котрый возвращает ближайшее время доставки для типа доставки
export const getPossibleDeliveryTime = (deliveryOption, deliveryOptions, now = moment()) => {
  const hoursForDelivery = deliveryOptions.filter(
    d => d.id === deliveryOption
  )[0]['defaultDeliveryHours'];
  return now.clone().add(hoursForDelivery, 'hours');
};

export default {
  getNextDaysForDelivery,
  getDeliverySlotsForDayAndDelivery,
  getPossibleDeliveryTime
};
