export const freeDeliveryByOrderPrice = (deliveryOptions,deliveryOptionId, orderPrice) => {
  const delivery = deliveryOptions.find(
    (d) => d.id === deliveryOptionId
  );
  if(delivery)
  return orderPrice >= delivery.freeDeliveryOrderPrice ? 0 : delivery.price;
};

export default {
  freeDeliveryByOrderPrice,
};
