import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan, callSuccess, callFailed } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import {
  DishesStateModel,
  DishResponseModel,
  DishesRequestModel,
  DishesModel
} from '../interfaces/DishesResponseModel';

import {
  DishMetaType,
  SeoMetaModel,
  SiteMetaModel
} from '../interfaces/DishesResponseModel';

import config from '../config/config.json';
import { normalize, schema } from 'normalizr';

// Define normalize schemas
const categorySchema = new schema.Entity('categories');
const partnerSchema = new schema.Entity('partner');

// @ts-ignore
const dishProcessStrategy = (value, parent, key) => {
  return {
    ...value,
    minAmount: value.min_amount,
    dishesUnit: value.dishes_unit
  };
};

// https://medium.com/@jmsoliv95/normalizing-your-data-with-normalizr-d0e95a1b16ea
const dishSchema = new schema.Entity(
  'dishes',
  {
    partner: partnerSchema,
    categories: [categorySchema]
  },
  {
    processStrategy: dishProcessStrategy
  }
);

const initialState: DishesStateModel = {
  dict: {}
};

const slice = createSlice({
  name: 'dishes',
  initialState: initialState,
  reducers: {
    dishesLoaded: (dishes, action) => {
      const normalizedData = normalize(action.payload.data.dishes, [
        dishSchema
      ]);
      // @ts-ignore
      normalizedData.entities.dishes = Object.keys(
        // @ts-ignore
        normalizedData.entities.dishes as DishesRequestModel
      ).reduce(
        // @ts-ignore
        (res, key: DishesModel) => {
          // @ts-ignore
          res[parseInt(key)] = {
            // @ts-ignore
            ...normalizedData.entities.dishes[key],
            // @ts-ignore
            children: normalizedData.entities.dishes[key].children
              ? // @ts-ignore
                normalizedData.entities.dishes[key].children.reduce(
                  // @ts-ignore
                  (child_res, child) => {
                    child_res[child.child_id] = child.amount;
                    return child_res;
                  },
                  {}
                )
              : {}
          };
          return res;
        },
        {}
      );

      dishes.dict = normalizedData.entities.dishes as {
        [key: number]: DishResponseModel;
      };
    },
    dishStatusChanged: (dishes, action) => {
      const { active, dishId } = action.payload.params;
      dishes.dict[dishId].active = active;
    },
    dishPriceChanged: (dishes, action) => {
      const { price, dishId } = action.payload.params;
      dishes.dict[dishId].price = price;
    },
    dishDifficultyChanged: (dishes, action) => {
      const { difficulty, dishId } = action.payload.params;
      dishes.dict[dishId].difficulty = difficulty;
    },
    dishMinAmountChanged: (dishes, action) => {
      const { minAmount, dishId } = action.payload.params;
      dishes.dict[dishId].minAmount = minAmount;
    },
    dishMeasureChanged: (dishes, action) => {
      const { dishesUnitId, dishId } = action.payload.params;
      dishes.dict[dishId].dishesUnit = { id: dishesUnitId };
    },
    dishTitleChanged: (dishes, action) => {
      const { title, dishId } = action.payload.params;
      dishes.dict[dishId].title = title;
    },
    dishDescriptionChanged: (dishes, action) => {
      const { description, dishId } = action.payload.params;
      dishes.dict[dishId].meta.description = description;
    },
    dishBguChanged: (dishes, action) => {
      const { bgu, dishId } = action.payload.params;
      dishes.dict[dishId].meta.bgu = bgu;
    },
    childAdded: (dishes, action) => {
      const { childId, amount, dishId } = action.payload.params;
      dishes.dict[dishId].children[childId] = amount;
    },
    childAmountChanged: (dishes, action) => {
      const { childId, amount, dishId } = action.payload.params;
      if (amount === 0) {
        delete dishes.dict[dishId].children[childId];
      } else {
        dishes.dict[dishId].children[childId] = amount;
      }
    },
    dishCategoryChanged: (dishes, action) => {
      const { dishId, categoryId } = action.payload.params;
      dishes.dict[dishId].categories = [categoryId];
    },
    dishMetaChanged: (dishes, action) => {},
    dishSitePhotoDeleted: (dishes, action) => {},
    dishAdded: (dishes, action) => {
      const normalizedData = normalize(action.payload.data.dish, dishSchema);
      // @ts-ignore
      const dishId = Object.keys(normalizedData.entities.dishes)[0];
      // @ts-ignore
      // normalizedData.entities.dishes[dishId].children = normalizedData.entities // @ts-ignore
      //   .dishes[dishId].children
      //   ? // @ts-ignore
      //     normalizedData.entities.dishes[dishId].children.map(child => {
      //       return { dishId: child.child_id, amount: child.amount };
      //     })
      //   : [];
      normalizedData.entities.dishes[dishId].children = normalizedData.entities
        .dishes[dishId].children
        ? // @ts-ignore
          normalizedData.entities.dishes[dishId].children.reduce(
            // @ts-ignore
            (child_res, child) => {
              // return { dishId: child.child_id, amount: child.amount };
              child_res[child.child_id] = child.amount;
              return child_res;
            },
            {}
          )
        : {};
      // @ts-ignore
      dishes.dict[parseInt(dishId)] = normalizedData.entities.dishes[dishId];
    }
  }
});

export const {
  dishesLoaded,
  dishStatusChanged,
  dishPriceChanged,
  dishDifficultyChanged,
  dishMinAmountChanged,
  dishMeasureChanged,
  dishTitleChanged,
  dishDescriptionChanged,
  dishBguChanged,
  dishAdded,
  childAdded,
  childAmountChanged,
  dishCategoryChanged,
  dishMetaChanged,
  dishSitePhotoDeleted
} = slice.actions;

export default slice.reducer;

// Action Creators

export const loadOrderDishes = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  // @ts-ignore
  return await dispatch(loadDishes(places, 'loadOrderDishes'));
};

export const loadMenuDishes = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let menuPlace = getState().login.menuPlace;
  const places = `place_id=${menuPlace}`;
  // @ts-ignore
  return await dispatch(loadDishes(places, 'loadMenuDishes'));
};

export const loadDishes = (places: string, actionName: string) => (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/?${places}`,
      onSuccess: dishesLoaded.type,
      actionName: actionName
    })
  );
};

export const changeDishStatus = (dishId: number, active: boolean) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/active/`,
      method: 'patch',
      onSuccess: dishStatusChanged.type,
      data: { active },
      params: { dishId, active },
      actionName: 'changeDishStatus'
    })
  );
};

export const changeDishPrice = (dishId: number, price: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishPriceChanged.type,
      data: { price },
      params: { price, dishId },
      actionName: 'changeDishPrice'
    })
  );
};

export const changeDishDifficulty = (
  dishId: number,
  difficulty: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishDifficultyChanged.type,
      data: { difficulty },
      params: { difficulty, dishId },
      actionName: 'changeDishDifficulty'
    })
  );
};

export const changeDishMinAmount = (
  dishId: number,
  minAmount: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishMinAmountChanged.type,
      data: { min_amount: minAmount },
      params: { minAmount, dishId },
      actionName: 'changeDishMinAmount'
    })
  );
};

export const changeDishMeasure = (
  dishId: number,
  dishesUnitId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishMeasureChanged.type,
      // data: { dishes_unit: { id: dishesUnitId } },
      data: { dishes_unit_id: dishesUnitId },
      params: { dishesUnitId, dishId },
      actionName: 'changeDishMeasure'
    })
  );
};

export const changeDishTitle = (dishId: number, title: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishTitleChanged.type,
      data: { title },
      params: { title, dishId },
      actionName: 'changeDishTitle'
    })
  );
};

export const changeDishDescription = (
  dishId: number,
  bgu: string,
  description: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishDescriptionChanged.type,
      data: { meta: { description, bgu } },
      params: { description, dishId },
      actionName: 'changeDishDescription'
    })
  );
};

export const changeDishBgu = (
  dishId: number,
  description: string,
  bgu: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'put',
      onSuccess: dishBguChanged.type,
      data: { meta: { bgu, description } },
      params: { bgu, dishId },
      actionName: 'changeDishBgu'
    })
  );
};

export const changeDishMeta = (
  dishId: number,
  state: SeoMetaModel | SiteMetaModel,
  metaType: DishMetaType
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/meta/`,
      method: 'put',
      onSuccess: dishMetaChanged.type,
      data: {
        type: metaType,
        ...(metaType === DishMetaType.seo ? { seo: state } : { site: state })
      },
      params: { state, dishId },
      actionName: 'changeDishMeta'
    })
  );
};

export const deleteDishSitePhoto = (dishId: number, photoId: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/image/?photoId=${photoId}`,
      method: 'delete',
      onSuccess: dishSitePhotoDeleted.type,
      actionName: 'deleteDishSitePhoto'
    })
  );
};

export const getDishMeta = (dishId: number) => async (
  dispatch: AppDispatch
) => {
  const response = await dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/`,
      method: 'get',
      actionName: 'getDishMeta'
    })
  );
  // @ts-ignore
  if (response.type === 'api/callFailed') return response;

  // Установим в апи признак того что запрос завершился
  dispatch({
    type: callSuccess.type,
    payload: { actionName: 'sendName' }
  });
  return response;
};

export const addDish = (
  title: string,
  category: number,
  active: boolean,
  price: number,
  difficulty: number,
  minAmount: number,
  dishesUnitId: number,
  description: string,
  bgu: string,
  children: { dish_id: number; amount: number }[],
  place_id: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/`,
      method: 'post',
      onSuccess: dishAdded.type,
      data: {
        title,
        active,
        price,
        difficulty: difficulty ? difficulty : null,
        min_amount: minAmount ? minAmount : null,
        dishes_unit_id: dishesUnitId ? dishesUnitId : null,
        meta: { description, bgu },
        children,
        category,
        place: { id: place_id }
      },
      actionName: 'addDish'
    })
  );
};

export const addChild = (
  dishId: number,
  childId: number,
  amount: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/child/`,
      method: 'post',
      onSuccess: childAdded.type,
      data: {
        child_id: childId,
        amount
      },
      params: { dishId, childId, amount },

      actionName: 'addChild'
    })
  );
};

export const changeChildAmount = (
  dishId: number,
  childId: number,
  amount: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/child/`,
      method: 'patch',
      onSuccess: childAmountChanged.type,
      data: {
        child_id: childId,
        amount
      },
      params: { dishId, childId, amount },

      actionName: 'changeChildAmount'
    })
  );
};

export const changeDishCategory = (
  dishId: number,
  categoryId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/dishes/${dishId}/category/`,
      method: 'patch',
      onSuccess: dishCategoryChanged.type,
      data: {
        categoryId
      },
      params: { dishId, categoryId },

      actionName: 'changeDishCategory'
    })
  );
};

// Selectors;
export const getDishesSelector = createSelector(
  (state: RootState) => state.entities.dishes.dict,
  dishes => dishes
);

export const getDishesByCategorySelector = (categoryId: number) =>
  createSelector(
    (state: RootState) => state.entities.dishes.dict,
    dishes =>
      Object.keys(dishes)
        .filter(
          key => dishes[parseInt(key)].categories.indexOf(categoryId) > -1
        )
        .map(key => dishes[parseInt(key)])
  );
// Helpers
