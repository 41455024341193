import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import {
  CategoriesStateModel,
  CategoryModel
} from '../interfaces/CategoriesModel';
import config from '../config/config.json';
import { normalize, schema } from 'normalizr';

// Define normalize schemas
const categorySchema = new schema.Entity('categories');

const initialState: CategoriesStateModel = {
  dict: {}
};

const slice = createSlice({
  name: 'categories',
  initialState: initialState,
  reducers: {
    categoriesLoaded: (categories, action) => {
      const normalizedData = normalize(action.payload.data.categories, [
        categorySchema
      ]);
      categories.dict = normalizedData.entities.categories as {
        [key: number]: CategoryModel;
      };
    },
    categoryAdded: (categories, action) => {
      const category = action.payload.data.category;
      categories.dict[category.id] = category;
    },
    categoryChanged: (categories, action) => {
      const categoryId = action.payload.data.category.id;
      // @ts-ignore
      categories.dict[categoryId] = {
        ...categories.dict[categoryId],
        ...action.payload.params.category
      };
    }
  }
});

export const {
  categoriesLoaded,
  categoryAdded,
  categoryChanged
} = slice.actions;

export default slice.reducer;

// Action Creators

// Action Creators
export const loadMenuCategories = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let menuPlace = getState().login.menuPlace;
  const places = `place_id=${menuPlace}`;
  // @ts-ignore
  return await dispatch(loadCategories(places, 'loadMenuCategories'));
};

export const loadOrderCategories = () => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  // @ts-ignore
  return await dispatch(loadCategories(places, 'loadOrderCategories'));
};

export const loadCategories = (places: string, actionName: string) => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/categories/?${places}`,
      onSuccess: categoriesLoaded.type,
      actionName: actionName
    })
  );
};

export const addCategory = (category: {
  title: string;
  group: string;
  active: boolean;
  place: { id: number };
}) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/categories/`,
      method: 'post',
      onSuccess: categoryAdded.type,
      data: { ...category },
      actionName: 'addCategory'
    })
  );
};

export const changeCategory = (
  categoryId: number,
  category: {
    title: string;
    active: boolean;
    meta: {
      slug: string;
      title: string;
      description: string;
      keywords: string;
    };
  }
) => (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/api/cafe/categories/${categoryId}/`,
      method: 'put',
      onSuccess: categoryChanged.type,
      data: category,
      params: { category },
      actionName: 'changeCategory'
    })
  );
};

// Selectors;
export const getCategoriesSelector = createSelector(
  (state: RootState) => state.entities.categories.dict,
  categories => categories
);

export const getCategorySelector = (categoryId: number) =>
  createSelector(
    (state: RootState) => state.entities.categories.dict,
    categories => categories[categoryId]
  );

export const getCutleryCategoriesSelector = createSelector(
  (state: RootState) => state.entities.categories.dict,
  categories =>
    Object.keys(categories).filter(
      key => categories[parseInt(key)].group === 'cutlery'
    )
);

export const getSemisCategoriesSelector = createSelector(
  (state: RootState) => state.entities.categories.dict,
  categories => {
    let semisCategories: { [key: number]: CategoryModel } = {};
    Object.keys(categories)
      .filter(key => categories[parseInt(key)].group === 'semis')
      .forEach(
        (key: string) =>
          (semisCategories[parseInt(key)] = categories[parseInt(key)])
      );

    return semisCategories;
  }
);
// Helpers
