import { useState, useEffect, useRef } from 'react';

const useDebounce = (value: string | number | boolean, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is changed ...
    // .. within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]); // Only re-call effect if value or delay changes

  return debouncedValue;
};

export default useDebounce;

export const useDebounceStringField = (initValue: string, callback: any) => {
  const [value, setValue] = useState(initValue);

  const componentJustMounted = useRef(true);

  const handleChangeValue = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value;
    setValue(newValue);
  };

  const debouncedValue = useDebounce(value, 1000);
  useEffect(() => {
    if (!componentJustMounted.current) {
      if (debouncedValue && value) {
        const sendValue = async (debouncedValue: string) => {
          try {
            await callback(debouncedValue);
          } catch {}
        };
        sendValue(debouncedValue as string);
      }
    } else {
      componentJustMounted.current = false;
    }
  }, [debouncedValue]);

  return { value, setValue: handleChangeValue };
};
