import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// import Joi from 'joi-browser';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';

import {
  validateEmail,
  sendEmail,
  sendCode,
  sendName,
  roleSelector,
  getUserSelector
} from '../../store/login';
import { getRequestErrorsByPageSelector } from '../../store/api';

// import auth from '../../helpers/authService';
// import api from '../../helpers/apiService';
import withAlert from '../../hoc/withAlert';

import config from '../../config/config.json';
// import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/artur.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const Login = props => {
  const {
    className,
    currentUser,
    onCurrentUserChange,
    showErrorSnackbar
  } = props;

  const { setSnack } = props;

  const classes = useStyles();

  // Получим данные из REDUX
  const dispatch = useDispatch();

  const requestsError = useSelector(
    getRequestErrorsByPageSelector(props.location.pathname)
  );
  const validationError = useSelector(state => state.login.validationError);
  const email = useSelector(state => state.login.email);
  const emailSent = useSelector(state => state.login.emailSent);
  // const user = useSelector(state => state.login.user);
  const user = useSelector(getUserSelector);
  const userRole = useSelector(roleSelector);

  const handleBack = () => {
    history.goBack();
  };

  // Возьмем урл для редиректа после логина. Либо предыдущая страница либо дефаулт путь
  let history = useHistory();


  const [key, setKey] = useState('');
  const [reg, setReg] = useState({ name: '', surname: '' });

  const [keyError, setKeyError] = useState({
    status: false,
    message: ''
  });

  useEffect(() => {
    if (requestsError.hasError) {
      setSnack({
        open: true,
        type: 'error',
        message: `Ошибка: ${requestsError.error}`
      });
    }
  }, [requestsError.hasError]);

  // Когда авторизовались появился ключ user делаем редирект
  useEffect(() => {
    if (user && user.id) {
      history.replace(config.defaultPaths[userRole]);
    }
  }, [user]);

  const handleEmailChange = async e => {
    const input = e.target.value;
    await dispatch(validateEmail(input));
  };

  const handleEmailSend = () => {
    // console.log('handleEmailSend', email);
    // try {
    dispatch(sendEmail(email));
  };

  const handleKeyChange = e => {
    const input = e.target.value;
    setKey(input);
  };

  const handleKeySend = async () =>  {
    // console.log(key);
    await dispatch(sendCode(key.trim()));
  };

  const handleNameSend = () => {
    // console.log(key);
    dispatch(sendName(reg));
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={6} xs={12}>
          <div className={classes.quote}></div>
        </Grid>
        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                {user.id !== null && userRole === 'guest' && (
                  <Typography className={classes.title} variant="h2">
                    У Вас нет прав достпуа, попробуйте перезагрузить страницу и
                    войти заново.
                  </Typography>
                )}
                {user.id === null && (
                  <React.Fragment>
                    <Typography className={classes.title} variant="h2">
                      Dobrawcafe
                    </Typography>

                    <Typography color="textSecondary" gutterBottom>
                      Введите ваш емейл, на почту прийдет код для входа
                    </Typography>

                    <TextField
                      className={classes.textField}
                      fullWidth
                      type="email"
                      autoComplete="email"
                      value={email}
                      error={validationError ? true : false}
                      helperText={validationError}
                      onChange={handleEmailChange}
                      label="Email"
                      name="email"
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      fullWidth
                      size="large"
                      disabled={
                        !((email !== '') & (validationError === null)) ||
                        user.id !== null
                      }
                      variant="contained"
                      onClick={handleEmailSend}>
                      {emailSent && 'Отправить повторно'}
                      {!emailSent && 'Отправить Email'}
                    </Button>
                  </React.Fragment>
                )}
                {emailSent && user.id === null && (
                  <React.Fragment>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      id="outlined-name"
                      label="Введите код из письма"
                      value={key}
                      autoComplete="off"
                      error={keyError.status ? true : false}
                      helperText={keyError.message}
                      onChange={handleKeyChange}
                      type="text"
                      variant="outlined"
                    />
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      fullWidth
                      disabled={key === '' || user.id !== null}
                      size="large"
                      variant="contained"
                      onClick={handleKeySend}>
                      Войти в админку
                    </Button>
                  </React.Fragment>
                )}
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

// export default withRouter(SignIn);
export default withAlert(withRouter(Login));
