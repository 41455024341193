import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Menu,
  IconButton
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/AddCircleTwoTone';
import RemoveIcon from '@material-ui/icons/RemoveCircleTwoTone';

import {
  ItemResponseModel,
  ItemStatuses
} from '../../../../interfaces/OrderResponseModel';

import { RoleResponseModel } from '../../../../interfaces/RolesResponseModel';

import { setItemDishCook, minusItemDishCook } from '../../../../store/order';

import { getCooksSelector } from '../../../../store/roles';

import { getItemStatusIcon } from '../../../../libs/statusesHelper';
import { sortUsers } from '../../../../utils/sortUsers';

import withAlert from '../../../../hoc/withAlert';

export interface OrderItemProps {
  item: ItemResponseModel;
  orderId: number;
  placeId: number;
  canEdit: boolean;
  dishItems: ItemResponseModel[];
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  dispatchHandler: (action: Function, ...params: any) => Promise<void>;
  handleInputChange: (
    setMethod: Function
  ) => (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
}

const OrderItem: React.SFC<OrderItemProps> = ({
  item,
  dispatchHandler,
  orderId,
  placeId,
  canEdit,
  dishItems,
  setSnack
}) => {
  const dispatch = useDispatch();
  // console.log('item', item);
  // Selectors
  const cooks = useSelector(getCooksSelector);
  // State
  // Для открытия закрытия меню
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Вычисляемый статус сгруппированного блюда
  const [itemStatus, setItemStatus] = useState<ItemStatuses | null>();

  // Блокируем кнопки и юзэффекты пока не сделаем полностью все обонвление
  const [blockUi, setBlockUi] = useState<boolean>(false);

  // Обект со всеми блюдами для каждого повара, нужен для отрисовки списка выбранных поваров
  const [cookIds, setCookIds] = useState<{
    [key: number]: ItemResponseModel[];
  }>({});

  // Список позиций без повара
  const [itemsNoCook, setItemsNoCook] = useState<ItemResponseModel[]>([]);

  // Handlers
  // @ts-ignore

  const handleCookChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newCookId = parseInt(event.target.value);

    if (itemsNoCook.length === 0) {
      setSnack({
        open: true,
        type: 'error',
        message: 'Все блюда уже назначены на поваров'
      });
      return;
    }
    // Пробуем установить айди повара
    try {
      setBlockUi(true);

      await dispatchHandler(
        setItemDishCook,
        orderId as number,
        item.dish.id as number,
        newCookId
      );

      setBlockUi(false);
    } catch {
      setBlockUi(false);
      return;
    }

    return;
  };

  const handleMinusAmount = async (cookId: number) => {
    if (cookIds[cookId]) {
      const newItems = cookIds[cookId].filter(
        i => i.status !== ItemStatuses.done
      );
      // Нельзя менять повара для заказа в статусе дан
      if (newItems.length > 0) {
        try {
          setBlockUi(true);
          await dispatchHandler(
            minusItemDishCook,
            orderId as number,
            item.dish.id as number,
            cookId
          );

          setBlockUi(false);
        } catch {
          setBlockUi(false);
        }
      }
    }
  };
  const handleAddAmount = async (cookId: number) => {
    if (cookIds[cookId]) {
      if (itemsNoCook.length > 0) {
        try {
          setBlockUi(true);
          await dispatchHandler(
            setItemDishCook,
            orderId as number,
            item.dish.id as number,
            cookId,
            1
          );

          setBlockUi(false);
        } catch {
          setBlockUi(false);
        }
      }
    }
  };

  // Helpers
  const getChipColor = (status: ItemStatuses | null | undefined) => {
    const chipColor = {
      new: 'default',
      notAll: 'default',
      in_progress: 'secondary',
      done: 'primary'
    };
    // @ts-ignore
    return chipColor[status];
  };
  const getNotAssignedCooks = (cooks: RoleResponseModel[]) => {
    return cooks.filter(
      c =>
        Object.keys(cookIds).indexOf(c.id.toString()) === -1 &&
        c.places?.find(places => places.id === placeId)
    );
  };

  const getCookTitle = (cookId: number) => {
    const newCook = cooks.filter(c => c.id === cookId)[0];
    return `${
      newCook && newCook.person.surname ? newCook.person.surname : ''
    } ${newCook && newCook.person.name}`;
  };

  const getItemStatus = () => {
    if (dishItems.every(i => i.status === ItemStatuses.new)) {
      return ItemStatuses.new;
    }
    if (dishItems.some(i => i.status === ItemStatuses.new)) {
      return ItemStatuses.notAll;
    }
    if (dishItems.every(i => i.status === ItemStatuses.in_progress)) {
      return ItemStatuses.in_progress;
    }
    if (dishItems.every(i => i.status === ItemStatuses.done)) {
      return ItemStatuses.done;
    }
  };

  // UseEffects
  // Начальная закгрузка
  // @ts-ignore
  useEffect(() => {
    let mounted = true;
    if (mounted && blockUi === false) {
      setItemStatus(getItemStatus() as ItemStatuses);

      const newItemsNoCook = dishItems.filter(
        i => i.cook === null || (i.cook && isNaN(i.cook.id) === true)
      );

      setItemsNoCook(newItemsNoCook);

      const newItems = {
        ...dishItems.reduce(
          (
            res: { [key: number]: ItemResponseModel[] },
            i: ItemResponseModel
          ) => {
            if (i.cook !== null) {
              if (i.cook && isNaN(i.cook.id) === false) {
                if (Object.keys(res).indexOf(i.cook.id.toString()) > -1) {
                  res[i.cook.id].push(i);
                } else {
                  res[i.cook.id] = [i];
                }
              }
            }
            return res;
          },
          {}
        )
      };
      setCookIds(newItems);
    }
    return () => {
      mounted = false;
    };
  }, [dishItems, blockUi]);

  return (
    <Fragment>
      {' '}
      <Grid item>
        <Card>
          <CardContent>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              spacing={0}
              wrap="nowrap">
              {/* Название блюда */}
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={1}
                  wrap="nowrap">
                  <Grid item>
                    <Chip
                      size="small"
                      label={`${item.amount}`}
                      color={getChipColor(itemStatus)}
                      // @ts-ignore
                      icon={getItemStatusIcon(itemStatus)}
                      // @ts-ignore
                      // onClick={handleOpenMenu}
                    />
                  </Grid>
                  <Grid item>
                    {' '}
                    <Typography
                      variant={'body1'}
                      dangerouslySetInnerHTML={{
                        __html: `${item.dish.categories[0].title} ${item.dish.title}`
                      }}></Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Название блюда */}

              {/* Выбор повара */}
              <Grid item>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel
                    id="courier"
                    disabled={
                      !canEdit || (canEdit && itemsNoCook.length === 0)
                    }>
                    Повар
                  </InputLabel>
                  <Select
                    fullWidth
                    // Отключаем редакирование если нельзя по роли или если нет блюд с неназначенными поварами
                    disabled={!canEdit || (canEdit && itemsNoCook.length === 0)}
                    labelId="courier"
                    // value={cookId}
                    value={''}
                    // @ts-ignore
                    onChange={handleCookChange}>
                    {getNotAssignedCooks(cooks).length > 0 &&
                      sortUsers(getNotAssignedCooks(cooks)).map(cook => (
                        <MenuItem value={cook.id} key={cook.id}>{`${
                          cook.person.surname ? cook.person.surname : ''
                        } ${cook.person.name}`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Выбор повара */}

              {/* Список выбранных поваров */}
              <Grid item style={{ paddingTop: 12 }}>
                {Object.keys(cookIds).length > 0 &&
                  Object.keys(cookIds).map(cookId => (
                    <Grid
                      item
                      key={cookId}
                      // @ts-ignore
                    >
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        wrap="nowrap">
                        <Grid item>
                          <IconButton
                            style={{ padding: 0 }}
                            aria-label="minus"
                            // Нельзя менять повара для заказа в статусе дан
                            disabled={
                              cookIds[parseInt(cookId)].filter(
                                i => i.status !== ItemStatuses.done
                              ).length === 0 || blockUi
                            }
                            onClick={e => {
                              handleMinusAmount(parseInt(cookId));
                            }}>
                            <RemoveIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h3"
                            style={{ fontSize: 14, padding: '0 4px 0 4px' }}>
                            {' '}
                            {cookIds[parseInt(cookId)].length}{' '}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            style={{ padding: 0 }}
                            aria-label="add"
                            disabled={
                              !canEdit ||
                              (canEdit && itemsNoCook.length === 0) ||
                              blockUi
                            }
                            onClick={e => {
                              handleAddAmount(parseInt(cookId));
                            }}>
                            <AddIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                        <Grid item xs={7} style={{ paddingLeft: 4 }}>
                          <Typography
                            variant="h3"
                            style={{ fontSize: 14, lineHeight: 'inherit' }}>
                            {getCookTitle(parseInt(cookId))}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              {/* Список выбранных поваров */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Fragment>
  );
};

export default withAlert(OrderItem);
