import { combineReducers } from 'redux';

import orderReducer from '../store/order';
import newOrderReducer from '../store/newOrder';
import dishesReducer from '../store/dishes';
import categoriesReducer from '../store/categories';
import rolesReducer from '../store/roles';
import dishesUnitsReducer from '../store/dishesUnits';
import deliveryOptionsReducer from '../store/deliveryOptions';

export default combineReducers({
  order: orderReducer,
  newOrder: newOrderReducer,
  dishes: dishesReducer,
  categories: categoriesReducer,
  roles: rolesReducer,
  dishesUnits: dishesUnitsReducer,
  deliveryOptions:deliveryOptionsReducer
});
