import React from 'react';
import { useSelector } from 'react-redux';

import { useItemsState } from '../../hooks/ItemsSelect';
import {
  ItemsSelect,
  ItemsContainer,
  ItemsIncDecList,
  ItemsChangeList
} from '../ItemsSelect';

import { itemTypes } from '../../interfaces/OrderRequestModel';
import { SelectItemModel } from '../../interfaces/ItemsSelect';
import { ItemResponseModel } from '../../interfaces/OrderResponseModel';

export interface DishesCardProps {
  onAddNew: (items: SelectItemModel, itemType: itemTypes) => Promise<void>;
  onReset?: () => Promise<void>;
  onAmountChange?: (
    items: SelectItemModel,
    itemType: itemTypes
  ) => Promise<void>;
  onAmountPlus?: (items: SelectItemModel, itemType: itemTypes) => Promise<void>;
  onAmountMinus?: (
    items: SelectItemModel,
    itemType: itemTypes
  ) => Promise<void>;
  itemType: itemTypes;
  // todo: must param
  places?: number;
  listType?: 'change' | 'incDec';
  items?: ItemResponseModel[];
}

const DishesCard: React.FunctionComponent<DishesCardProps> = ({
  onAddNew,
  onAmountChange,
  onAmountPlus,
  onAmountMinus,
  onReset,
  itemType,
  places,
  listType = 'incDec',
  items
}) => {
  const addHandler = async (child: SelectItemModel) => {
    if (itemType !== itemTypes.items) {
      child = { ...child, price: 0 };
    }
    //Передати событие родителю
    onAddNew(child, itemType);
  };

  const changeAmountHandler = async (child: SelectItemModel) => {
    if (itemType !== itemTypes.items) {
      child = { ...child, price: 0 };
    }
    if (onAmountChange) await onAmountChange(child, itemType);
  };
  const onAmountPlusHandler = async (child: SelectItemModel) => {
    if (itemType !== itemTypes.items) {
      child = { ...child, price: 0 };
    }
    if (onAmountPlus) await onAmountPlus(child, itemType);
  };
  const onAmountMinusHandler = async (child: SelectItemModel) => {
    if (itemType !== itemTypes.items) {
      child = { ...child, price: 0 };
    }
    if (onAmountMinus) await onAmountMinus(child, itemType);
  };

  const onResetHandler = async () => {
    if (onReset) {
      await onReset();
    }
  };
  // Получим список выранных блюд и проперти для селекта и списка
  const { getItemsSelectProps, getItemsListProps } = useItemsState({
    itemType: itemType,
    places: places as number,
    onAdd: addHandler,
    onReset: onResetHandler,
    onAmountChange: changeAmountHandler,
    onAmountPlus: onAmountPlusHandler,
    onAmountMinus: onAmountMinusHandler,
    currentItems: items as ItemResponseModel[]
  });

  return (
    <React.Fragment>
      <ItemsContainer>
        <ItemsSelect {...getItemsSelectProps} />
        {listType === 'change' && <ItemsChangeList {...getItemsListProps} />}
        {listType === 'incDec' && <ItemsIncDecList {...getItemsListProps} />}
      </ItemsContainer>
    </React.Fragment>
  );
};

export default DishesCard;
