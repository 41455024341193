import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector, useDispatch } from 'react-redux';

import withAlert from '../../hoc/withAlert';

import CourierCardBody from '../CourierCardBody';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const CourierCard = props => {
  const classes = useStyles();
  const { order } = props;

  const [courierParamsSet, setCourierParamsSet] = React.useState(false);

  const handleCourierParamsSet = (value: boolean) => {
    setCourierParamsSet(value);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>
          Курьер ({courierParamsSet ? 'назначен' : 'не назначен'})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CourierCardBody
          courierParamsSet={handleCourierParamsSet}
          order={order}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default withAlert(CourierCard);
