/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  Button,
  Divider,
  Snackbar,
  colors
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CachedIcon from '@material-ui/icons/Cached';

import { reloadDockerContainer } from '../../../../../../store/order';
import { roleSelector } from '../../../../../../store/login';

import { UserRoles } from '../../../../../../interfaces/LoginModel';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SidebarNav = props => {
  const { pages, className, onClose, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const userRole = useSelector(roleSelector);

  const [open, setOpen] = useState(false);

  const handelReload = async () => {
    await dispatch(reloadDockerContainer());
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)}>
        {pages.map(page => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              onClick={e => handleClose()}>
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
        <Divider />
        {userRole === UserRoles.manager && (
          <ListItem className={classes.item} disableGutters>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              onClick={handelReload}>
              <div className={classes.icon}>
                <CachedIcon />
              </div>
              Обновить
            </Button>
          </ListItem>
        )}
      </List>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={'success'}>
          {'Отправлен запрос на обновление'}
        </Alert>
      </Snackbar>
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
