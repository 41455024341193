import * as Sentry from '@sentry/browser';
// import credentials from "../config/credentials.json";

function init() {
  if (process.env.NODE_ENV !== 'development') {
    // Sentry.init({
    //   dsn: credentials.NEXT_PUBLIC_SENTRY_DSN,
    // });
  }
}

function log(error) {
  if (process.env.NODE_ENV !== 'development') {
    // Sentry.captureException(error);
  } else {
    console.error('LOGGER Error', error);
  }
}

export default {
  init,
  log
};
