import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { AppDispatch } from '../App';
import { RootState } from '../store/reducer';
import {
  RolesStateModel,
  RoleResponseModel,
  GuestModel,
  GuestResponseModel
} from '../interfaces/RolesResponseModel';
import config from '../config/config.json';

const initialState: RolesStateModel = {
  cooks: [],
  couriers: [],
  guests: [],
  staff: []
};

const slice = createSlice({
  name: 'roles',
  initialState: initialState,
  reducers: {
    cooksLoaded: (roles, action) => {
      roles.cooks = action.payload.data.users;
    },
    couriersLoaded: (roles, action) => {
      roles.couriers = action.payload.data.users;
    },
    staffLoaded: (roles, action) => {
      roles.staff = action.payload.data.users;
    },
    personNameChanged: (roles, action) => {
      const { personId, name } = action.payload.params;
      const index = roles.staff.findIndex(c => c.id === personId);
      roles.staff[index].person.name = name;
    },
    personSurnameChanged: (roles, action) => {
      const { personId, surname } = action.payload.params;
      const index = roles.staff.findIndex(c => c.id === personId);
      roles.staff[index].person.surname = surname;
    },
    personPhoneChanged: (roles, action) => {
      const { personId, phone } = action.payload.params;
      const index = roles.staff.findIndex(c => c.id === personId);
      roles.staff[index].person.meta = {
        ...roles.staff[index].person.meta,
        phone
      };
    },
    traineeMentorChanged: (roles, action) => {
      const { personId, mentorId } = action.payload.params;
      const index = roles.staff.findIndex(c => c.id === personId);
      roles.staff[index].person.meta = {
        ...roles.staff[index].person.meta,
        mentor_id: mentorId
      };
    },
    traineeMentorDeleted: (roles, action) => {
      const { personId } = action.payload.params;
      const index = roles.staff.findIndex(c => c.id === personId);
      roles.staff[index].person.meta = {
        ...roles.staff[index].person.meta,
        mentor_id: undefined
      };
    },

    guestsLoaded: (roles, action) => {
      roles.guests = action.payload.data.users
        .filter(
          (user: RoleResponseModel) =>
            user.person.name && user.person.meta && user.person.meta.phone
        )
        .reduce((res: GuestResponseModel, user: RoleResponseModel) => {
          res[user.id] = {
            id: user.id,
            name: user.person.name,
            phone: user.person.meta ? user.person.meta.phone : ''
          };
          return res;
        }, {});
    }
  }
});

export const {
  cooksLoaded,
  couriersLoaded,
  guestsLoaded,
  staffLoaded,
  personNameChanged,
  personSurnameChanged,
  personPhoneChanged,
  traineeMentorDeleted,
  traineeMentorChanged
} = slice.actions;

export default slice.reducer;

// Action Creators
export const loadCooks = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');

  return dispatch(
    apiCallBegan({
      url: `/custom/roles/cook/users/?${places}`,
      onSuccess: cooksLoaded.type,
      actionName: 'loadCooks'
    })
  );
};

export const loadCouriers = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  return dispatch(
    apiCallBegan({
      url: `/custom/roles/courier/users/?${places}`,
      onSuccess: couriersLoaded.type,
      actionName: 'loadCouriers'
    })
  );
};

export const loadStaff = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let orderPlaces = getState().login.orderPlaces;
  const places = orderPlaces?.map(p => `place_id=${p}`)?.join('&');
  return dispatch(
    apiCallBegan({
      url: `/custom/roles/staff/?${places}`,
      onSuccess: staffLoaded.type,
      actionName: 'loadStaff'
    })
  );
};

export const loadGuests = () => (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  let userPlaces = getState().login.places;
  const places = userPlaces?.map(p => `place_id=${p.id}`)?.join('&');
  return dispatch(
    apiCallBegan({
      url: `/custom/guests/?${places}`,
      onSuccess: guestsLoaded.type,
      actionName: 'loadGuests'
    })
  );
};

export const changePersonName = (personId: number, name: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/person/${personId}/`,
      method: 'put',
      onSuccess: personNameChanged.type,
      data: { name },
      params: { personId, name },
      actionName: 'changePersonName'
    })
  );
};

export const changePersonSurname = (
  personId: number,
  surname: string
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/person/${personId}/`,
      method: 'put',
      onSuccess: personSurnameChanged.type,
      data: { surname },
      params: { personId, surname },
      actionName: 'changePersonSurname'
    })
  );
};

export const changePersonPhone = (personId: number, phone: string) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/person/${personId}/phone/`,
      method: 'put',
      onSuccess: personPhoneChanged.type,
      data: { phone },
      params: { personId, phone },
      actionName: 'changePersonPhone'
    })
  );
};

export const changeTraineeMentor = (
  personId: number,
  mentorId: number
) => async (dispatch: AppDispatch) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/person/${personId}/mentor/`,
      method: 'put',
      onSuccess: traineeMentorChanged.type,
      data: { id: mentorId },
      params: { personId, mentorId },
      actionName: 'changeTraineeMentor'
    })
  );
};

export const deleteTraineeMentor = (personId: number) => async (
  dispatch: AppDispatch
) => {
  return dispatch(
    apiCallBegan({
      url: `/custom/person/${personId}/mentor/`,
      method: 'delete',
      onSuccess: traineeMentorDeleted.type,
      params: { personId },
      actionName: 'deleteTraineeMentor'
    })
  );
};

// Selectors;
export const getCooksSelector = createSelector(
  (state: RootState) => state.entities.roles.cooks,
  cooks => cooks
);

export const getCouriersSelector = createSelector(
  (state: RootState) => state.entities.roles.couriers,
  couriers => couriers
);

export const getOwnCouriersSelector = createSelector(
  (state: RootState) => state.entities.roles.couriers,
  couriers => couriers.filter(courier => !courier.person.meta?.type)
);

export const getGuestsSelector = createSelector(
  (state: RootState) => state.entities.roles.guests,
  guests => guests
);

export const getStaffSelector = createSelector(
  (state: RootState) => state.entities.roles.staff,
  staff => staff
);

// Helpers
