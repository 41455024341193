import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  SelectOptionType,
  SelectItemModel
} from '../../interfaces/ItemsSelect';
import { itemTypes } from '../../interfaces/OrderRequestModel';
import { ItemResponseModel } from '../../interfaces/OrderResponseModel';
import { ChildModel } from '../../interfaces/DishesResponseModel';
import { useDishes } from '../../hooks/ItemsSelect';

type prepareItemsType = (
  items: any[],
  dishes: SelectItemModel[]
) => SelectItemModel[];

type Hook = (params: {
  itemType: itemTypes;
  // todo: must always be set
  places?: number;
  currentItems?: any[];
  prepareItems?: (items: any[], dishes: SelectItemModel[]) => SelectItemModel[];
  onAdd?: (item: SelectItemModel) => Promise<void>;
  onReset?: () => Promise<void>;
  onAmountPlus?: (item: SelectItemModel) => Promise<void>;
  onAmountMinus?: (item: SelectItemModel) => Promise<void>;
  onAmountChange?: (item: SelectItemModel) => Promise<void>;
}) => {
  items: SelectItemModel[];
  getItemsSelectProps: {
    onAdd: (option: SelectOptionType) => Promise<void>;
    options: SelectOptionType[];
  };
  getItemsListProps: {
    items: SelectItemModel[];
    onSetAmount: (itemId: number, amount: number) => Promise<void>;
    onAddAmount: (itemId: number) => Promise<void>;
    onMinusAmount: (itemId: number) => Promise<void>;
  };
  // helpers: {
  //   prepareDishes: (currentDishes: ItemResponseModel[]) => SelectItemModel[];
  // };
};

const useItemsState: Hook = ({
  currentItems,
  prepareItems = prepareFromItemResponseModel,
  itemType,
  places,
  onAdd,
  onAmountPlus,
  onAmountMinus,
  onAmountChange,
  onReset
}) => {
  // Значения добавленные в список
  const [items, setItems] = useState<SelectItemModel[]>([]);
  // Значения для селекта
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  // Get prepared dishes list
  const dishes = useDishes(itemType, places as number);
  // Setup default already selected list (from server)
  // Setup select options
  useEffect(() => {
    const resetData = async () => {
      if (onReset) {
        await onReset();
      }
    };
    if (places) {
      setItems([]);
      resetData();
    }
  }, [places]);

  useEffect(() => {
    if (
      currentItems &&
      (currentItems.length > 0 || Object.keys(currentItems).length > 0) &&
      dishes.length > 0
    ) {
      if (prepareItems) {
        const newItems = prepareItems(currentItems, dishes);
        setItems(newItems);
      }
    }
  }, [currentItems, dishes]);

  // Setup select options
  useEffect(() => {
    if (dishes && dishes.length > 0) {
      setOptions(getAllOptions());
    }
  }, [dishes]);

  // Set options without items
  useEffect(() => {
    const newOptions = [...getAllOptions()];
    items.forEach(i => {
      const index = newOptions.findIndex(o => o.value === i.id);
      if (index > -1) {
        newOptions.splice(index, 1);
      }
    });
    setOptions(newOptions);
  }, [items]);

  // getItemsSelectProps
  const handleAdd = async (option: SelectOptionType) => {
    try {
      const dish = dishes.find(d => d.id === option.value) as SelectItemModel;
      const newItem = {
        id: option.value,
        title: option.label,
        amount: dish.minAmount ? dish.minAmount : 1,
        price: dish.price,
        minAmount: dish.minAmount,
        unitLabel: dish.unitLabel
      };
      // Вызовим внешний метод
      if (onAdd) await onAdd(newItem);
      // Добавим в список айтемов
      setItems([...items, newItem]);
    } catch {}
  };

  // getItemsListProps
  const handleSetAmount = async (itemId: number, amount: number) => {
    try {
      const newItems = items.map(item =>
        item.id === itemId ? { ...item, amount } : item
      );
      const changedItem = newItems.find(item => item.id === itemId);
      if (onAmountChange) await onAmountChange(changedItem as SelectItemModel);
      setItems(newItems.filter(item => item.amount > 0));
    } catch {}
  };

  const handleAddAmount = async (itemId: number) => {
    try {
      const newItems = [
        ...items.map(item =>
          item.id === itemId ? { ...item, amount: item.amount + 1 } : item
        )
      ];
      if (onAmountPlus)
        await onAmountPlus(
          newItems.find(item => item.id === itemId) as SelectItemModel
        );
      else {
        // В NewOrder мы не передаем метод для инкримента мы передаем метод для изменения
        if (onAmountChange)
          await onAmountChange(
            newItems.find(item => item.id === itemId) as SelectItemModel
          );
      }
      setItems(newItems);
    } catch {}
  };

  const handleMinusAmount = async (itemId: number) => {
    const itemIndex = items.findIndex(i => i.id === itemId);
    const amount =
      items[itemIndex].amount > 0
        ? items[itemIndex].amount - 1
        : items[itemIndex].amount;
    // Отправим данные только если амунт еще больше 0
    if (items[itemIndex].amount > 0) {
      const newItems = [
        ...items.map(item =>
          item.id === itemId ? { ...item, amount: amount } : item
        )
      ];
      try {
        if (onAmountMinus)
          await onAmountMinus(
            newItems.find(item => item.id === itemId) as SelectItemModel
          );
        else {
          if (onAmountChange)
            await onAmountChange(
              newItems.find(item => item.id === itemId) as SelectItemModel
            );
        }
        setItems(newItems.filter(item => item.amount > 0));
      } catch {}
    }
  };

  const getAllOptions = () => {
    return dishes.map(d => ({
      value: d.id,
      label: d.title
    }));
  };

  // const helpers = {
  //   prepareDishes
  // };

  const getItemsSelectProps = {
    onAdd: handleAdd,
    options
  };

  const getItemsListProps = {
    items,
    onSetAmount: handleSetAmount,
    onAddAmount: handleAddAmount,
    onMinusAmount: handleMinusAmount
  };

  return { items, getItemsSelectProps, getItemsListProps };
};

const prepareFromItemResponseModel = (
  currentDishes: ItemResponseModel[],
  dishes: SelectItemModel[]
) => {
  return currentDishes.map(cd => {
    const dish = dishes.find(d => d.id === cd.dish.id) as SelectItemModel;
    return {
      id: cd.dish.id,
      title: dish.title,
      amount: cd.amount,
      price: cd.price,
      minAmount: dish.minAmount,
      unitLabel: dish.unitLabel
    };
  });
};

const prepareFromChildModel = (
  currentDishes: ChildModel,
  dishes: SelectItemModel[]
) => {
  return Object.keys(currentDishes).map(key => {
    const dish = dishes.find(d => d.id === parseInt(key)) as SelectItemModel;
    return {
      id: dish.id,
      title: dish.title,
      amount: currentDishes[parseInt(key)],
      price: dish.price,
      minAmount: dish.minAmount,
      unitLabel: dish.unitLabel
    };
  });
};

// @ts-ignore
useItemsState.prepareFromItemResponseModel = prepareFromItemResponseModel;
// @ts-ignore
useItemsState.prepareFromChildModel = prepareFromChildModel;

export default useItemsState;
