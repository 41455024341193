import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import { OrderStatuses } from '../../interfaces/OrderResponseModel';
import { Avatar } from '@material-ui/core';

import { getStatusIcon } from '../../libs/statusesHelper';

export interface OrderStatusProps {
  status: OrderStatuses;
}

const useStyles = makeStyles(theme => ({
  avatar: {
    // @ts-ignore
    backgroundColor: theme.palette.primary.main
  }
}));

const OrderStatus: React.SFC<OrderStatusProps> = props => {
  const { status } = props;
  const classes = useStyles();

  return <Avatar className={classes.avatar}>{getStatusIcon(status)}</Avatar>;
};

export default OrderStatus;
