import * as React from 'react';
import { useState, useEffect } from 'react';

import { Grid, Typography, Button } from '@material-ui/core';

import { NewOrderRequestModel } from '../../../../interfaces/OrderRequestModel';

export interface SummaryCardProps {
  order: NewOrderRequestModel;
  placeTitle: string;
}

const SummaryCard: React.SFC<SummaryCardProps> = ({ order, placeTitle }) => {
  const itemsAmount = order.items.reduce((res: number, item) => {
    res += item.amount;
    return res;
  }, 0);

  const presentsAmount = order.presents.reduce((res: number, item) => {
    res += item.amount;
    return res;
  }, 0);

  const deliveryPrice = order.delivery?.price as number;
  return (
    <React.Fragment>
      <Grid item>
        <Typography variant={'h5'}>
          Заказ в: <b style={{ color: '#009688' }}>{`${placeTitle}`}</b>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={'h6'}>{`Позиций: ${itemsAmount}`}</Typography>
      </Grid>
      {presentsAmount > 0 && (
        <Grid item>
          <Typography
            variant={'h6'}>{`Подарков: ${presentsAmount}`}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography
          variant={'h6'}>{`Стоимость доставки: ${deliveryPrice}`}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant={'h6'}>{`Стоимость с учетом доставки: ${order.price +
          deliveryPrice}`}</Typography>
      </Grid>
    </React.Fragment>
  );
};

export default SummaryCard;
