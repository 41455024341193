import * as React from 'react';
// import { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';

const queryString = require('query-string');

const useStyles = makeStyles(theme => ({
  flexGrow: {
    // flexGrow: 1,
    color: 'white',
    justifyContent: 'start'
  }
}));

export interface SemisTopBarProps {
  userRole: string;
}

const SemisTopBar: React.SFC<SemisTopBarProps> = ({ userRole }) => {
  const classes = useStyles();
  const { id: categoryId } = useParams();
  let history = useHistory();

  // let location = useLocation();
  // const { type } = queryString.parse(location.search);

  const handleAdd = () => {
    // history.push(`/dish/${categoryId}/?type=semis`);
    history.push(`/new`);
  };
  return (
    <div>
      <IconButton
        className={classes.flexGrow}
        // style={{ }}
        onClick={e => {
          handleAdd();
        }}>
        <AddIcon fontSize="small" />
        <Typography
          variant="h6"
          style={{
            marginLeft: 5,
            color: 'white'
          }}>
          Заготовки
        </Typography>
      </IconButton>
    </div>
  );
};

export default SemisTopBar;
