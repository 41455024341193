import React, { useState, ReactNode } from 'react';

import {
  Grid,
  Typography,
  CardContent,
  Card,
  Switch,
  TextField,
  colors,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Collapse,
  CircularProgress
} from '@material-ui/core';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

export interface CollapseCardProps {
  children: ReactNode;
  title: string;
}

const CollapseCard: React.FunctionComponent<CollapseCardProps> = ({
  children,
  title
}) => {
  const [collapse, setCollapse] = useState(false);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <Card
      style={{
        overflow: 'visible',
        zIndex: 0,
        marginTop: 10
      }}>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={0}
          wrap="nowrap">
          <Grid item>
            <Typography variant={'h5'}>{title}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center">
              <Grid item>
                <IconButton aria-label="minus" onClick={handleCollapse}>
                  {!collapse ? <DownIcon /> : <UpIcon />}
                </IconButton>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={collapse}>
          {collapse && <div style={{ paddingBottom: 10 }}>{children}</div>}
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default CollapseCard;
