import React from 'react';

import { Grid, IconButton } from '@material-ui/core';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import AutorenewIcon from '@material-ui/icons/Autorenew';

// @ts-ignore
import { DateStr, toDateStr } from '../../interfaces/DateStr';
import { prepareDate } from '../../libs/dateHelper';

export interface DatesFilterTopBarProps {
  selectedStartDate: MaterialUiPickersDate;
  selectedEndDate: MaterialUiPickersDate;
  onDatesChanged: (startDate: DateStr, endDate: DateStr) => Promise<void>;
  onStartDateChange: (date: MaterialUiPickersDate) => void;
  onEndDateChange: (date: MaterialUiPickersDate) => void;
}

const DatesFilterTopBar: React.SFC<DatesFilterTopBarProps> = ({
  selectedStartDate,
  selectedEndDate,
  onDatesChanged,
  onStartDateChange,
  onEndDateChange,
}) => {
  const handleChangeDates = () => {
    onDatesChanged(
      toDateStr(prepareDate(selectedStartDate)),
      toDateStr(prepareDate(selectedEndDate))
    );
  };

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={1}>
      <Grid item xs={5}>
        <KeyboardDatePicker
          margin="normal"
          id="order-start-date"
          label="Начальная дата"
          format="dd.MM.yyyy"
          value={selectedStartDate}
          onChange={onStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <KeyboardDatePicker
          margin="normal"
          id="order-end-date"
          label="Конечная дата"
          format="dd.MM.yyyy"
          value={selectedEndDate}
          onChange={onEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <IconButton
          aria-label="add"
          onClick={handleChangeDates}
          color="primary"
          style={{ marginTop: 12 }}>
          <AutorenewIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default DatesFilterTopBar;
