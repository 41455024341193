import * as React from 'react';
import { ReactNode } from 'react';

export interface ItemsContainerProps {
  children: ReactNode;
}

const ItemsContainer: React.SFC<ItemsContainerProps> = ({ children }) => {
  return <>{children}</>;
};

export default ItemsContainer;
