import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogTitle,
  Button
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';

import {
  paymentsStatus,
  paymentsSystems,
  OrderStatuses,
  OrderStateModel,
  statusesLabels
} from '../../interfaces/OrderResponseModel';

import {
  changePaymentStatus,
  changeOrderStatus,
  addPayment,
  changePaymentSystem
} from '../../store/order';

import withAlert from '../../hoc/withAlert';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

// @ts-ignore
const StatusCard = props => {
  const classes = useStyles();
  const { order }: { order: OrderStateModel } = props;
  const { dispatchHandler } = props;

  const dispatch = useDispatch();

  const [selectedStatus, setSelectedStatus] = React.useState(order.status);

  const [paymentStatus, setPaymentStatus] = useState<paymentsStatus>(
    paymentsStatus.not_paid
  );
  const [paymentSystem, setPaymentSystem] = useState<paymentsSystems>(
    paymentsSystems.nal
  );

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedStatus = event.target.value as OrderStatuses;
    if (selectedStatus === OrderStatuses.cancelled) {
      setOpen(true);
      return;
    }

    try {
      await dispatchHandler(
        changeOrderStatus,
        order.id as number,
        selectedStatus
      );
      // Если ок то поменяем локальный статус
      setSelectedStatus(selectedStatus);
    } catch {}
  };

  const handlePaymentStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const status = event.target.value;
    try {
      if (order.payments === null || order.payments.length === 0) {
        await dispatchHandler(
          addPayment,
          order.id as number,
          status,
          paymentSystem
        );
      } else {
        await dispatchHandler(
          changePaymentStatus,
          order.id as number,
          order.payments[0].id,
          status
        );
      }
      // Если ок то поменяем локальный статус
      setPaymentStatus(status as paymentsStatus);
    } catch {}
  };

  const handlePaymentSystemChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const system = event.target.value;
    try {
      if (order.payments === null || order.payments.length === 0) {
        await dispatchHandler(
          addPayment,
          order.id as number,
          paymentStatus,
          system
        );
      } else {
        // console.log('changePaymentSystem',order.payment?.id);
        await dispatchHandler(
          changePaymentSystem,
          order.id as number,
          order.payments[0].id as number,
          system
        );
      }
      // Если ок то поменяем локальный статус
      setPaymentSystem(system as paymentsSystems);
    } catch {}
  };

  // Init
  useEffect(() => {
    setPaymentStatus(
      order.payments !== null && order.payments.length > 0
        ? (order.payments[0].status as paymentsStatus)
        : paymentsStatus.not_paid
    );
    setPaymentSystem(
      order.payments !== null && order.payments.length > 0
        ? paymentsSystems[order.payments[0].system as paymentsSystems]
        : paymentsSystems.nal
    );
  }, [dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelOrder = async () => {
    await dispatchHandler(
      changeOrderStatus,
      order.id as number,
      OrderStatuses.cancelled
    );
    setOpen(false);
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Отменить заказ?'}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelOrder} color="primary">
            Да отменить
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Нет не отменять
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={3}>
        <Grid item>
          <FormControl>
            <InputLabel id="status">Статус</InputLabel>
            <Select
              labelId="status"
              value={selectedStatus}
              // @ts-ignore
              onChange={e => handleStatusChange(e)}>
              {Object.keys(statusesLabels).map((key: string) => (
                <MenuItem value={key} key={key}>
                  {
                    // @ts-ignore
                    statusesLabels[key]
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="statusType">Способ</InputLabel>
            <Select
              labelId="statusType"
              value={paymentSystem}
              // @ts-ignore
              onChange={handlePaymentSystemChange}>
              <MenuItem value={paymentsSystems.cp}>Карта</MenuItem>
              <MenuItem value={paymentsSystems.sber}>Сбер</MenuItem>
              <MenuItem value={paymentsSystems.nal}>Нал</MenuItem>
              <MenuItem value={paymentsSystems.terminal}>Терм</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel
              id="statusPaid"
              disabled={paymentSystem === paymentsSystems.cp}>
              Оплачен
            </InputLabel>
            <Select
              disabled={paymentSystem === paymentsSystems.cp}
              labelId="statusPaid"
              value={paymentStatus}
              // @ts-ignore
              onChange={handlePaymentStatusChange}>
              <MenuItem value={paymentsStatus.not_paid}>Нет</MenuItem>
              <MenuItem value={paymentsStatus.paid}>Да</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withAlert(StatusCard);
