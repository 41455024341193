import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const HistoryCard = props => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>История</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense={true}>
          <ListItem>
            <ListItemText primary="02.06 15:21 - Артем - Заказ №125 создан" />
          </ListItem>
          <ListItem>
            <ListItemText primary="02.06 15:21 - Артем - Заказ №125 изменен" />
          </ListItem>
          <ListItem>
            <ListItemText primary="02.06 15:21 - Катя - Заказ №125 новый статус - в работе" />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default HistoryCard;
