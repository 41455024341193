import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Avatar,
  Typography,
  Chip,
  CardContent,
  Card
} from '@material-ui/core';

// import WhatsAppIcon from '@material-ui/icons/WhatsApp';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import PhoneIcon from '@material-ui/icons/Phone';
// import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  tel: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: 12
  }
}));

const UserCard = props => {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={1}>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <Typography variant={'h5'}>#125 Иванов Иван</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item>
            <Typography variant={'h6'}></Typography>
          </Grid> */}
          <Grid item>
            <Typography variant={'h6'} style={{ color: 'gray', fontSize: 12 }}>
              Москва, ул. Правды, дом 1, кв 2{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'h6'} style={{ color: 'gray', fontSize: 12 }}>
              <a href="tel:+79261231212" className={classes.tel}>
                +7 (926) 123-12-12
              </a>{' '}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}>
              <Grid item>
                <Chip size="small" label="закаов: 5" />
              </Grid>
              <Grid item>
                <Chip size="small" label="сумма: 2 500 ₽" />
              </Grid>
              <Grid item>
                <Chip size="small" label="1 мая 2018" />
              </Grid>
            </Grid>
          </Grid>

          {/* </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserCard;
