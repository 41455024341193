import axios from 'axios';
import moment from 'moment';

import * as actions from '../api';
import config from '../../config/config';
import logger from '../../libs/logService';
import auth from '../../libs/authService';

const socket = ({ dispatch, getState }) => next => async action => {
  return next(action);
};

export default socket;
