import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { WeekDayCard } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    // paddingTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  }
}));

const DailyMenu = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={2}>
        <Grid item>
          <WeekDayCard label={'Понедельник'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Вторник'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Среда'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Четверг'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Пятница'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Суббота'} />
        </Grid>
        <Grid item>
          <WeekDayCard label={'Воскресенье'} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DailyMenu;
