import axios from 'axios';
import moment from 'moment';

import * as actions from '../api';
import config from '../../config/config';
import logger from '../../libs/logService';
import auth from '../../libs/authService';

const api = ({ dispatch, getState }) => next => async action => {
  if (action.type !== actions.apiCallBegan.type) return next(action);

  const {
    url,
    method,
    data,
    onStart,
    onSuccess,
    onError,
    actionName,
    params,
    headers
  } = action.payload;

  // Выполняем само событие
  next(action);

  // Проверим есть ли данные в кеше
  const apiState = getState().api;
  const hasCache = checkDataInCache(actionName, url, apiState);
  // console.log('hasCache', hasCache);
  // if (hasCache) return next(action);
  if (hasCache) return;

  // Отправляем евент о начале загрузки
  dispatch({ type: actions.callRequested.type, payload: action.payload });
  // dispatch({ type: actions.callRequested.type, action });

  // Отправляем событие о старте если оно явно указанно
  if (onStart) dispatch({ type: onStart });
  
  let request_attempt = 1;
  do {
    // Пробуем сделать запрос
    try {
      const response = await makeRequest(url, method, data, headers);
      // Проверим если в оттвете есть акксес или рефреш кей то установим их в хранишище
      try {
        if (Object.keys(response.data).indexOf('access_token') > -1) {
          auth.setAccessToken(response.data.access_token);
        }
        if (Object.keys(response.data).indexOf('refresh_token') > -1) {
          auth.setRefreshToken(response.data.refresh_token);
        }
      } catch {}

      // Specific
      // Если есть onSuccess то после выполнения функции нужно вызвать диспатч с этим именем если onSuccess то просто вернуть значение
      let onSuccessResult;
      if (onSuccess) {
        onSuccessResult = await dispatch({
          type: onSuccess,
          payload: { data: response.data, params, headers: response.headers }
        });
      } else {
        // dispatch({ type: actions.callSuccess.type, payload: action.payload });
        return response.data;
      }
      // General
      const generalResult = await dispatch({
        type: actions.callSuccess.type,
        payload: action.payload
      });
      return onSuccess ? onSuccessResult : generalResult;
    } catch (error) {
      // console.log('request_attempt',request_attempt,error);
      // After 2 attempts logout
      request_attempt =+1;
      if(request_attempt > 2){
          // Разлогон
          auth.logout();
          // General
          dispatch({
            type: actions.callFailed.type,
            payload: { data: action.payload, error: error.message }
          });
          // Specific
          if (onError) dispatch({ type: onError, payload: error.message });
          return Promise.reject(error);
      }

      if (error.response && error.response.status == 401) {
        try {
          await auth.refreshTokens();
        } catch (ex) {
          console.log('cant refresh');
          // Разлогон
          auth.logout();
          // General
          dispatch({
            type: actions.callFailed.type,
            payload: { data: action.payload, error: ex.message }
          });
          // Specific
          if (onError) dispatch({ type: onError, payload: ex.message });
          return Promise.reject(ex);
        }
        continue;
      }
      // Specific
      if (onError) dispatch({ type: onError, payload: error.message });

      return dispatch({
        type: actions.callFailed.type,
        payload: { data: action.payload, error: error.message }
      });
      // return Promise.reject(error);
    }
  } while (true);
};

// Вспомогательные функции
const makeRequest = async (url, method, data, headers) => {
  let requestParams = {
    url,
    method,
    data,
    withCredentials: true
  };
  if (headers) requestParams.headers = headers;
  return await axios.request(requestParams);
};

const checkDataInCache = (actionName, url, apiState) => {
  // Check ib config has rule for action name
  if (!(actionName in config.cache)) return false;
// Check if action name in reqests
  if (actionName in apiState.requests) {
    const { lastFetch, url:lastUrl } = apiState.requests[actionName];
    // Check if url the same as last was
    
    if(url !== lastUrl) return false;
    const diffInMinutes = moment().diff(moment(lastFetch), 'minutes');
    if (diffInMinutes <= config.cache[actionName]) return true;
  }
  return false;
};

export default api;
