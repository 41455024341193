import React, { useState, useEffect, useRef } from 'react';

import { Grid, Button, CircularProgress } from '@material-ui/core';

export interface PhotoUploadProps {
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >;
  imagePath: string;
  onUpload: (file: File) => Promise<void>;
  classes: any;
}

const MAX_PHOTO_SIZE_KB = 250;
const ACCEPTED_PHOTO_FORMATS = 'image/x-png,image/png';

const validatePhoto = (
  file: File,
  setSnack: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: string;
      message: string;
    }>
  >
) => {
  // @ts-ignore

  if (file.size > 1024 * MAX_PHOTO_SIZE_KB) {
    setSnack({
      open: true,
      type: 'error',
      message: `Ошибка: размер картинки должен быть меньше ${MAX_PHOTO_SIZE_KB} килобайт`
    });
    throw 'File not valid';
  }

  if (
    ACCEPTED_PHOTO_FORMATS.toLowerCase().indexOf(file.type.toLowerCase()) === -1
  ) {
    setSnack({
      open: true,
      type: 'error',
      message: `Ошибка: картинка должна быть в формате ${ACCEPTED_PHOTO_FORMATS}`
    });
    throw 'File not valid';
  }
};

const PhotoUpload: React.SFC<PhotoUploadProps> = ({
  setSnack,
  imagePath,
  onUpload,
  classes
}) => {
  // To show preloader while uploading
  const [showPhotoLoading, setShowPhotoLoading] = useState(false);

  // Link to photo for refresh image after upload
  const photoRef = useRef(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file = e.target.files[0];
    try {
      validatePhoto(file, setSnack);
    } catch {
      return;
    }

    setShowPhotoLoading(true);
    await onUpload(file);
    setShowPhotoLoading(false);

    if (photoRef !== null) {
      //@ts-ignore
      photoRef.current.src = `${
        process.env.REACT_APP_FRONT_URL
      }${imagePath}?t=${new Date().getTime()}`;
    }
  };
  // If now image for dish
  const handleNoImage = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // @ts-ignore
    ev.target.src = '/images/no_image.png';
  };

  return (
    <div className={classes}>
      {/* Row with image preview */}
      <Grid item>
        <div>
          <img
            ref={photoRef}
            onError={handleNoImage}
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: '100%',
              maxHeight: '100%'
            }}
            src={`${process.env.REACT_APP_FRONT_URL}${imagePath}`}
          />
        </div>
      </Grid>
      {/* Row with image preview */}
      <Grid item>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              color="primary"
              style={{
                paddingRight: 10,
                marginTop: 10,
                marginBottom: 10
              }}>
              Выберите файл
              <input
                type="file"
                accept={ACCEPTED_PHOTO_FORMATS}
                id="inputFileId"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Grid>
          <Grid item>
            {showPhotoLoading && <CircularProgress disableShrink size={30} />}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhotoUpload;
