import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import withAlert from '../../hoc/withAlert';

import { useSelector, useDispatch } from 'react-redux';
import { changeDeliveryPhone, changeDeliveryName } from '../../store/order';
import { OrderStateModel } from '../../interfaces/OrderResponseModel';

import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  root: {
    // @ts-ignore
    padding: theme.spacing(4),
    // @ts-ignore
    [theme.breakpoints.down('lg')]: {
      paddingBottom: 64
    }
  },
  divider: {
    // @ts-ignore
    borderTop: `1px solid ${theme.palette.primary.main}`,
    margin: '10px 0'
  }
}));

// @ts-ignore
const ClientCard = props => {
  const classes = useStyles();
  const { handleInputChange, dispatchHandler } = props;

  const { order }: { order: OrderStateModel } = props;
  const dispatch = useDispatch();

  const [name, setName] = useState({
    value: '',
    state: 'init'
  });
  const debouncedName = useDebounce(name.value, 1000);

  const [phone, setPhone] = useState({
    value: '',
    state: 'init'
  });
  const debouncedPhone = useDebounce(phone.value, 1000);

  // Name
  useEffect(() => {
    if (debouncedName && name.state !== 'init') {
      const sendName = async (debouncedComment: string) => {
        try {
          await dispatchHandler(
            changeDeliveryName,
            order.id as number,
            debouncedName
          );
        } catch {}
      };
      sendName(debouncedName as string);
    }
  }, [debouncedName]);

  // Phone
  useEffect(() => {
    if (debouncedPhone && phone.state !== 'init') {
      const sendName = async (debouncedComment: string) => {
        try {
          await dispatchHandler(
            changeDeliveryPhone,
            order.id as number,
            debouncedPhone
          );
        } catch {}
      };
      sendName(debouncedPhone as string);
    }
  }, [debouncedPhone]);

  // Initial state
  useEffect(() => {
    setName({
      value: (order.delivery?.meta.name
        ? order.delivery.meta.name
        : '') as string,
      state: 'init'
    });

    setPhone({
      value: (order.delivery?.meta.phone
        ? order.delivery.meta.phone
        : '') as string,
      state: 'init'
    });
  }, []);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography variant={'h5'}>Клиент</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          spacing={2}>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Фамилия Имя"
              name="surname"
              type="text"
              value={name.value}
              onChange={handleInputChange(setName)}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              label="Телефон"
              name="phone"
              type="text"
              value={phone.value}
              onChange={handleInputChange(setPhone)}
              autoComplete="off"
            />
          </Grid>
          {/* <Divider className={classes.divider} /> */}
          {/* <Grid item>
            <TextField
              id="surname"
              style={{ width: '100%' }}
              label="Дополнительный контакт ФИО"
              name="surname"
              type="text"
              value={'Петров Петя'}
              autoComplete="off"
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              id="phone"
              label="Дополнительный телефон"
              name="phone"
              type="text"
              value={'+7 (916) 333-33-33'}
              autoComplete="off"
            />
          </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default withAlert(ClientCard);
