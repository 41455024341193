import { PlaceModel } from './LoginModel';

export enum OrderStatuses {
  new = 'new', //Pseudostatus
  approving = 'approving',
  approved = 'approved', // Pseudostatus
  cooking = 'cooking',
  // ready = 'ready', // Pseudostatus
  delivering = 'delivering',
  done = 'done', // Pseudostatus
  cancelled = 'cancelled' // Pseudostatus
}

export enum ItemStatuses {
  new = 'new', //Pseudostatus
  notAll = 'notAll',
  in_progress = 'in_progress',
  done = 'done' // Pseudostatus
}

export enum paymentsStatus {
  not_paid = 'not_paid',
  paid = 'paid',
  canceled = 'canceled'
}

export enum transactionStatus {
  checked = 'checked',
  paid = 'paid',
  failed = 'failed'
}

export enum paymentsSystems {
  sber = 'sber',
  nal = 'nal',
  terminal = 'terminal',
  cp = 'cp'
}

export enum CourierSystemEnum {
  own = 'own',
  dostavista = 'dostavista'
}

export const statusesLabels = {
  new: 'Новый',
  approved: 'Подвержден', // Pseudostatus
  cooking: 'Готовится',
  // ready: 'Готов', // Pseudostatus
  delivering: 'Доставка',
  done: 'Выполнен', // Pseudostatus
  cancelled: 'Отменен'
};

export const newStatusesLabels = {
  new: 'Новый',
  approved: 'Подвержден' // Pseudostatus
};

interface CategoryResponseModel {
  id: number;
  group: string;
  title: string;
}

export interface ChildModel {
  [key: number]: number;
}

export interface DishResponseModel {
  active: boolean;
  id: number;
  price: number;
  title: string;
  type: string;
  difficulty: number;
  minAmount: number;
  dishesUnit: { id: number };
  description: string;
  place: PlaceModel;
  categories: CategoryResponseModel[];
  children: ChildModel;
}

export interface ItemResponseModel {
  amount: number;
  cook: { id: number } | null;
  id: number;
  order?: OrderStateModel;
  orderId?: number;
  price: number;
  status: ItemStatuses;
  orderStatus?: OrderStatuses;
  orderType?: string;
  orderPlace?: { id: number; title: string };
  dishItems?: number[];
  deliveryTime?: Date;
  dish: DishResponseModel;
}

export interface DeliveryResponseMetaModel {
  address: string | null;
  date: string;
  name: string;
  phone: string;
  time: string;
}

export interface DeliveryResponseModel {
  id: number;
  deliveryoption: {
    id: number;
    title: string;
    type: string;
    place: { id: number };
  };
  price: number;
  courier: {
    id: number;
    person: { name: string; meta: { phone?: string; type?: string } };
  } | null;
  start_time: Date;
  end_time: Date;
  meta: DeliveryResponseMetaModel;
}

export interface OrderPaymentResponseModel {
  id: number;
  status: string;
  system: string;
}

export interface TransactionMetaResponseModel {
  TransactionId?: string;
  TestMode?: string;
  Status?: string;
  PaymentAmount?: string;
  PaymentCurrency?: string;
  IpCountry?: string;
  InvoiceId?: string;
  DateTime?: string;
  CardType?: string;
  AccountId?: string;
  Reason?: string;
  ReasonCode?: string;
}

export interface OrderTransactionResponseModel {
  id: number;
  receipt_id: string;
  type: string;
  test_mode: boolean;
  meta: TransactionMetaResponseModel;
  status: transactionStatus;
}

export interface OrderResponseModel {
  id: number;
  client: {
    id: number;
  } | null;
  delivery: DeliveryResponseModel | null;
  items: ItemResponseModel[];
  cutleries?: ItemResponseModel[];
  allItems: ItemResponseModel[];
  comment: string | null;
  created_at: string;
  status: OrderStatuses;
  transactions: OrderTransactionResponseModel[];
  payments: { id: number; status: string; system: string }[] | null;
  type: string;
  sales?: number;
  place: {
    id: number;
    title: string;
    meta: { process: { needCourier: boolean } };
  };
}

export interface OrderStateModel extends OrderResponseModel {
  price: number | null;
  itemsPrice: number | null;
  deliveryAddress: string | null;
  isPickup: boolean | null;
}

export type statusesCounterModel = {
  [key in OrderStatuses]?: number;
};

export interface OrderListStateModel {
  list: OrderStateModel[];
  statusesCounter: statusesCounterModel;
  statusFilter: OrderStatuses | null;
}

export interface OrderProps {
  order: OrderStateModel;
}

export interface OrderPropsWithSnack {
  order: OrderStateModel;
  setSnack: Function;
}
